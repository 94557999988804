@charset "UTF-8";
/* - - - - - - - - - - - - - - - - - - - - - - - - - - -
 * SHARED PROPERTIES
 * - - - - - - - - - - - - - - - - - - - - - - - - - - -
 * */
.product-block:before, .product-filter .sort-order-items:before, .filter-groups:before, .has-active-filter-items .active-filters:before, .has-product-filter:before, .rating-selector .option:before, .rating-selector .control:before, .add-review-comment .login-menu:before, #product-reviews .product-rating:before, .social-sharing:before, .product-long-description:before,
.text-block .column:before, .product-block:after, .product-filter .sort-order-items:after, .filter-groups:after, .has-active-filter-items .active-filters:after, .has-product-filter:after, .rating-selector .option:after, .rating-selector .control:after, .add-review-comment .login-menu:after, #product-reviews .product-rating:after, .social-sharing:after, .product-long-description:after,
.text-block .column:after {
  content: "";
  display: table;
}
.product-block:after, .product-filter .sort-order-items:after, .filter-groups:after, .has-active-filter-items .active-filters:after, .has-product-filter:after, .rating-selector .option:after, .rating-selector .control:after, .add-review-comment .login-menu:after, #product-reviews .product-rating:after, .social-sharing:after, .product-long-description:after,
.text-block .column:after {
  clear: both;
}

.payment-shipping-methods ul, .cart-head-items, .checkout-cart,
.import-cart, .product-documents, .shopping-cart, .menu-block-with-dropline-menu .menu, .site-header .menu-block-with-flyout-menu .menu, .menu, .pagination li, .pagination ul, .gallery-item, .gallery, .review-ratings {
  margin: 0;
  padding: 0;
  background-image: none;
  list-style: none;
}

/* - - - - - - - - - - - - - - - - - - - - - - - - - - -
 * FONTS
 * - - - - - - - - - - - - - - - - - - - - - - - - - - -
 * */
@font-face {
  font-family: "Source Sans Pro";
  src: url("../fonts/source-sans-pro/source-sans-pro-light.eot?");
  src: url("../fonts/source-sans-pro/source-sans-pro-light.eot?#iefix") format("embedded-opentype"), url("../fonts/source-sans-pro/source-sans-pro-light.woff") format("woff"), url("../fonts/source-sans-pro/source-sans-pro-light.ttf") format("truetype"), url("../fonts/source-sans-pro/source-sans-pro-light.svg#") format("svg");
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: "Source Sans Pro";
  src: url("../fonts/source-sans-pro/source-sans-pro-regular.eot?");
  src: url("../fonts/source-sans-pro/source-sans-pro-regular.eot?#iefix") format("embedded-opentype"), url("../fonts/source-sans-pro/source-sans-pro-regular.woff") format("woff"), url("../fonts/source-sans-pro/source-sans-pro-regular.ttf") format("truetype"), url("../fonts/source-sans-pro/source-sans-pro-regular.svg#") format("svg");
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: "Source Sans Pro";
  src: url("../fonts/source-sans-pro/source-sans-pro-italic.eot?");
  src: url("../fonts/source-sans-pro/source-sans-pro-italic.eot?#iefix") format("embedded-opentype"), url("../fonts/source-sans-pro/source-sans-pro-italic.woff") format("woff"), url("../fonts/source-sans-pro/source-sans-pro-italic.ttf") format("truetype"), url("../fonts/source-sans-pro/source-sans-pro-italic.svg#") format("svg");
  font-weight: 400;
  font-style: italic;
}
@font-face {
  font-family: "Source Sans Pro";
  src: url("../fonts/source-sans-pro/source-sans-pro-semibold.eot?");
  src: url("../fonts/source-sans-pro/source-sans-pro-semibold.eot?#iefix") format("embedded-opentype"), url("../fonts/source-sans-pro/source-sans-pro-semibold.woff") format("woff"), url("../fonts/source-sans-pro/source-sans-pro-semibold.ttf") format("truetype"), url("../fonts/source-sans-pro/source-sans-pro-semibold.svg#") format("svg");
  font-weight: 600;
  font-style: bold;
}
/* - - - - - - - - - - - - - - - - - - - - - - - - - - -
 * ICONS
 * - - - - - - - - - - - - - - - - - - - - - - - - - - -
 * */
@font-face {
  font-family: "swt";
  src: url("../../lib/fonts/swt-1.3/fonts/swt.eot? ");
  src: url("../../lib/fonts/swt-1.3/fonts/swt.eot?#iefix") format("embedded-opentype"), url("../../lib/fonts/swt-1.3/fonts/swt.woff") format("woff"), url("../../lib/fonts/swt-1.3/fonts/swt.ttf") format("truetype"), url("../../lib/fonts/swt-1.3/fonts/swt.svg#") format("svg");
  font-weight: normal;
  font-style: normal;
}
.swt-icon, #checkout-layout li a:before, .select-list-adresses .select-list-option-selected .icon-selected-address:before, .select-list-adresses .toggle-favorite-address:before,
.select-list-adresses .icon-selected-address:before, .toggle-volume-pricing-content:after, .product-status-checkout-link b:after, .unifaun-checkout-option-agents-wrapper:after,
.unifaun-checkout-list-input:after, .icon-container .quick-view:before, .icon-container .favorite:before, .shopping-favorite-cart .cart-content-close:after, .shopping-favorite-cart .favorite:before, .favorite-list .add-to-cart:before, .open-off-canvas-favorites .favorite:before, .favorite:before, .favorite-cart-icon:before, .select-list:before, .select-locale-button-content .icon:before, .select-locale-modal-close-button:before, .select-currency-dropdown-flags .select-currency-button:after, .breadcrumb:before, .select-language-dropdown-flags .select-language-button:after, .off-canvas-nav .search-block:before, .open-fullwidth-search:after, .search-block-with-fullwidth button:after, .search-block-with-expand h3:after, .search-block button:after,
.search-block-with-expand button:after, .import-cart-checkout-link:before, .cancel-preview-cart-import:before, .add-import-list-to-cart:before, .clear-import-items:before, .toggle.import-file a:before,
.toggle.import-file-options a:before, .modal-cart .cart-footer .cart-content-close:after, .modal-cart .modal-cart-close:after, .off-canvas-cart .cart-content-close:after, .remove-item-action:after,
.remove-item:after, .checkout-link:after, .cart-icon:before, .off-canvas-nav .toggle-child-menu:before, .site-content .custom-menu-block .toggle-child-menu:before,
.site-footer .custom-menu-block .toggle-child-menu:before, #content-sidebar .menu-title.load-tree:before,
.site-footer .menu-title.load-tree:before, #content-sidebar .menu-level-3-title:before,
.site-footer .menu-level-3-title:before, #content-sidebar .menu-level-2-title:before,
.site-footer .menu-level-2-title:before, .menu-block-with-dropline-menu .header-item .menu-level-1-title.menu-title-has-children:after, .site-header .menu-block-with-flyout-menu .menu-level-2-title:after,
.site-header .menu-block-with-flyout-menu .menu-level-3-title:after,
.site-header .menu-block-with-flyout-menu .menu-level-4-title:after, .site-header .menu-block-with-flyout-menu.menu-title-has-children:after, .site-header .menu-block-with-flyout-menu .menu-level-1-title.menu-title-has-children:after, .filter-group-title:after, .remove-active-filter-action:after, .filter-menu-item:before, .added-to-cart-feedback .feedback-icon:before, .gallery-layout a,
.product-gallery-layout a, .pagination .next > *:after, .pagination .previous > *:after, .rating-selector label:after, .rating:before, .rating:after, .select-type .control:after {
  font-family: "swt";
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/*  - - - - - - - - - - - - - - - - - - - - - - - - - - -
 * * TYPOGRAPHY
 * * - - - - - - - - - - - - - - - - - - - - - - - - - - -
 * */
html, body, input, textarea, select, button {
  font-family: "Source Sans Pro", sans-serif;
  font-weight: 400;
}

h1,
h2,
h3,
h4,
.slideshow-block .caption,
.save-percent-info {
  font-family: "Source Sans Pro", sans-serif;
  font-weight: 300;
  word-break: break-all;
}

html,
body,
input,
textarea,
select,
button {
  font-size: 100%;
  line-height: 160%;
  color: rgb(55, 55, 55);
}

h1, h2, h3, h4 {
  line-height: 1.4em;
  text-transform: none;
  letter-spacing: 0.05em;
}

h1 {
  margin: 0;
  margin-top: -0.7rem;
  line-height: 1.1em;
}

h2, h3, h4 {
  margin: 1em 0 0;
}

h1 {
  font-size: 2.4rem;
}

h2 {
  font-size: 1.7rem;
}

h3 {
  font-size: 1.4rem;
}

h4 {
  font-size: 1rem;
  font-weight: 400;
}

abbr[title] {
  border-style: none;
  text-decoration: none;
}

.text-block ul,
.faq-block ul {
  padding-left: 0;
}
.text-block li,
.faq-block li {
  list-style: none;
  padding-left: 13px;
  position: relative;
}
.text-block li:before,
.faq-block li:before {
  content: "•";
  position: absolute;
  top: 0;
  left: 0px;
  line-height: 1.4em;
}
.text-block ol li,
.faq-block ol li {
  list-style: inherit;
}
.text-block ol li:before,
.faq-block ol li:before {
  content: none;
}

dd {
  margin-left: 0;
}

p {
  margin: 0.6em 0 0 0;
}

b, strong {
  font-weight: 600;
}

a {
  font-weight: 400;
  text-decoration: none;
  color: #405AA7;
}

.desktop a:hover {
  text-decoration: underline;
}

.price .currency,
.weight .unit,
.quantity .unit,
.product-unit-price .unit {
  font-size: 0.5em;
  text-transform: uppercase;
}

.currency + .amount {
  padding-left: 2px;
}

.product-price .unit {
  display: none;
}

.original-price {
  font-size: 0.6em;
  opacity: 0.5;
}
.original-price .price {
  text-decoration: line-through;
}

html[lang=ar] {
  font-size: 1rem;
}
html[lang=ar] #page-container span {
  font-size: 1rem;
}

/* - - - - - - - - - - - - - - - - - - - - - - - - - - -
 * FORMS
 * - - - - - - - - - - - - - - - - - - - - - - - - - - -
 * */
input::-webkit-input-placeholder, textarea::-webkit-input-placeholder {
  color: rgb(180, 180, 180);
  opacity: 1;
}
input:-moz-placeholder, textarea:-moz-placeholder {
  color: rgb(180, 180, 180);
  opacity: 1;
}
input::-moz-placeholder, textarea::-moz-placeholder {
  color: rgb(180, 180, 180);
  opacity: 1;
}
input:-ms-input-placeholder, textarea:-ms-input-placeholder {
  color: rgb(180, 180, 180);
  opacity: 1;
}

.disabled input::-webkit-input-placeholder, .disabled textarea::-webkit-input-placeholder {
  color: rgb(140, 140, 140);
}
.disabled input:-moz-placeholder, .disabled textarea:-moz-placeholder {
  color: rgb(140, 140, 140);
}
.disabled input::-moz-placeholder, .disabled textarea::-moz-placeholder {
  color: rgb(140, 140, 140);
}
.disabled input:-ms-input-placeholder, .disabled textarea:-ms-input-placeholder {
  color: rgb(140, 140, 140);
}

.mobile input::-webkit-input-placeholder, .mobile textarea::-webkit-input-placeholder {
  color: rgb(120, 120, 120);
}
.mobile input:-moz-placeholder, .mobile textarea:-moz-placeholder {
  color: rgb(120, 120, 120);
}
.mobile input::-moz-placeholder, .mobile textarea::-moz-placeholder {
  color: rgb(120, 120, 120);
}
.mobile input:-ms-input-placeholder, .mobile textarea:-ms-input-placeholder {
  color: rgb(120, 120, 120);
}
.mobile .disabled input::-webkit-input-placeholder, .mobile .disabled textarea::-webkit-input-placeholder {
  color: rgb(0, 0, 0);
}
.mobile .disabled input:-moz-placeholder, .mobile .disabled textarea:-moz-placeholder {
  color: rgb(0, 0, 0);
}
.mobile .disabled input::-moz-placeholder, .mobile .disabled textarea::-moz-placeholder {
  color: rgb(0, 0, 0);
}
.mobile .disabled input:-ms-input-placeholder, .mobile .disabled textarea:-ms-input-placeholder {
  color: rgb(0, 0, 0);
}

input[type=search] {
  border-style: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  outline: 0;
  border-radius: 0;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  background-color: transparent;
}

.text-type,
.email-type,
.number-type,
.password-type,
.textarea-type,
.select-type,
.button-type,
.submit-type {
  position: relative;
  margin-top: 10px;
}
.text-type .caption,
.email-type .caption,
.number-type .caption,
.password-type .caption,
.textarea-type .caption,
.select-type .caption,
.button-type .caption,
.submit-type .caption {
  top: 1rem;
  font-size: 1.2rem;
  opacity: 0;
  position: absolute;
}

.error .caption {
  opacity: 1;
}

.quantity-field .unit {
  font-size: 0.8rem;
  line-height: 1em;
  opacity: 0.5;
}
.quantity-field .unit:before {
  content: "(";
}
.quantity-field .unit:after {
  content: ")";
}

.control textarea,
.control select,
.control input[type=text],
.control input[type=password],
.control input[type=email],
.control input[type=number],
.control input[type=tel],
.control input[type=url],
.control input[type=file] {
  border-style: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  outline: 0;
  border-radius: 0;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  background-color: transparent;
  width: 100%;
  position: relative;
  z-index: 2;
  font-size: 1.2rem;
  padding: 0.7rem 0.2rem 0 0;
  border-bottom: 1px solid #000;
}

.has-field-content .caption,
.error .caption {
  font-size: 0.7rem;
  top: 0;
  left: 0;
  z-index: 0;
  line-height: 1;
  transition: top 0.1s ease-in-out;
  opacity: 0.5;
}

.field-wrap.disabled {
  opacity: 0.3;
}

.mobile .field-wrap.disabled {
  opacity: 0.5;
}

.control textarea {
  min-height: 3em;
  resize: vertical;
}

.select-type .control {
  position: relative;
}
.select-type .control:after {
  position: absolute;
  content: "\e900";
  line-height: 40px;
  right: 1px;
  top: 4px;
  width: 40px;
  height: 40px;
  text-align: center;
  color: #000;
  font-size: 22px;
  transition: color linear 0.2s;
  pointer-events: none;
}

.control select {
  cursor: pointer;
  padding-right: 23px;
}

.site-header select,
.site-footer select {
  direction: rtl;
}
.site-header select option,
.site-footer select option {
  direction: ltr;
}

.button,
button,
.cart-content-close,
.checkout-link,
.product-status-checkout-link {
  border-style: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  outline: 0;
  border-radius: 0;
  cursor: pointer;
  background-color: #000;
  color: #fff;
  text-transform: uppercase;
  text-align: center;
  display: inline-block;
  text-decoration: none;
  font-size: 1.1rem;
  line-height: 100%;
  padding: 16px 1em 14px;
  font-weight: 400;
  transition: all 0.3s ease;
}
.button[disabled=disabled], .button[disabled],
button[disabled=disabled],
button[disabled],
.cart-content-close[disabled=disabled],
.cart-content-close[disabled],
.checkout-link[disabled=disabled],
.checkout-link[disabled],
.product-status-checkout-link[disabled=disabled],
.product-status-checkout-link[disabled] {
  opacity: 0.2;
}
.button:focus,
button:focus,
.cart-content-close:focus,
.checkout-link:focus,
.product-status-checkout-link:focus {
  background-color: #444;
}

button[type=submit],
.submit-type button,
.customer-account-check-action,
.apply-discount-code-action {
  width: 100%;
}

.desktop .button:hover,
.desktop button:hover,
.desktop input[type=button]:hover,
.desktop input[type=submit]:hover,
.desktop .cart-content-close:hover,
.desktop .checkout-link:hover,
.desktop .product-status-checkout-link:hover {
  background-color: #444;
  text-decoration: none;
  color: #fff;
}
.desktop .button[disabled=disabled]:hover, .desktop .button[disabled]:hover,
.desktop button[disabled=disabled]:hover,
.desktop button[disabled]:hover,
.desktop input[type=button][disabled=disabled]:hover,
.desktop input[type=button][disabled]:hover,
.desktop input[type=submit][disabled=disabled]:hover,
.desktop input[type=submit][disabled]:hover,
.desktop .cart-content-close[disabled=disabled]:hover,
.desktop .cart-content-close[disabled]:hover,
.desktop .checkout-link[disabled=disabled]:hover,
.desktop .checkout-link[disabled]:hover,
.desktop .product-status-checkout-link[disabled=disabled]:hover,
.desktop .product-status-checkout-link[disabled]:hover {
  background-color: #000;
}

.text-type.error .control input,
.email-type.error .control input,
.number-type.error .control input,
.password-type.error .control input,
.textarea-type.error .control input,
.select-type.error .control input {
  border-style: none;
}

.control input.error-field,
.control textarea.error-field {
  border-style: none;
}

label.error,
.label.error,
.message.error {
  color: white;
  background-color: #d14b2c;
  padding: 4px 4px 3px;
  font-size: 0.7rem;
  line-height: 1.3em;
  margin: 0;
}

label.rating-error {
  margin-left: 114px;
}

/* = = = = = = = = = = = = = = = = = = = = = = = = = = =
 * * VENDORS
 * * = = = = = = = = = = = = = = = = = = = = = = = = = = =
 * */
/* - - - - - - - - - - - - - - - - - - - - - - - - - - -
 * ION RANGE SLIDER
 * - - - - - - - - - - - - - - - - - - - - - - - - - - -
 * */
.irs {
  position: relative;
  display: block;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  user-select: none;
}

.irs-line {
  position: relative;
  display: block;
  overflow: hidden;
  outline: none !important;
}

.irs-line-left, .irs-line-mid, .irs-line-right {
  position: absolute;
  display: block;
  top: 0;
}

.irs-line-left {
  left: 0;
  width: 11%;
}

.irs-line-mid {
  left: 9%;
  width: 82%;
}

.irs-line-right {
  right: 0;
  width: 11%;
}

.irs-bar {
  position: absolute;
  display: block;
  left: 0;
  width: 0;
}

.irs-bar-edge {
  position: absolute;
  display: block;
  top: 0;
  left: 0;
}

.irs-shadow {
  position: absolute;
  display: none;
  left: 0;
  width: 0;
}

.irs-slider {
  position: absolute;
  display: block;
  cursor: default;
  z-index: 1;
}
.irs-slider.type_last {
  z-index: 2;
}

.irs-min {
  position: absolute;
  display: block;
  left: 0;
  cursor: default;
}

.irs-max {
  position: absolute;
  display: block;
  right: 0;
  cursor: default;
}

.irs-from, .irs-to, .irs-single {
  position: absolute;
  display: block;
  top: 0;
  left: 0;
  cursor: default;
  white-space: nowrap;
}

.irs-grid {
  position: absolute;
  display: none;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 20px;
}

.irs-with-grid .irs-grid {
  display: block;
}

.irs-grid-pol {
  position: absolute;
  top: 0;
  left: 0;
  width: 1px;
  height: 8px;
  background: #000;
}
.irs-grid-pol.small {
  height: 4px;
}

.irs-grid-text {
  position: absolute;
  bottom: 0;
  left: 0;
  white-space: nowrap;
  text-align: center;
  font-size: 9px;
  line-height: 9px;
  padding: 0 3px;
  color: #000;
}

.irs-disable-mask {
  position: absolute;
  display: block;
  top: 0;
  left: -1%;
  width: 102%;
  height: 100%;
  cursor: default;
  background: rgba(0, 0, 0, 0);
  z-index: 2;
}

.irs-disabled {
  opacity: 0.4;
}

.lt-ie9 .irs-disabled {
  filter: alpha(opacity=40);
}

.irs-hidden-input {
  position: absolute !important;
  display: block !important;
  top: 0 !important;
  left: 0 !important;
  width: 0 !important;
  height: 0 !important;
  font-size: 0 !important;
  line-height: 0 !important;
  padding: 0 !important;
  margin: 0 !important;
  outline: none !important;
  z-index: -9999 !important;
  background: none !important;
  border-style: solid !important;
  border-color: transparent !important;
}

.irs-slider {
  background: url(../../lib/ion-range-slider/img/sprite-skin-modern.png) repeat-x;
}

.irs {
  height: 50px;
}

.irs-with-grid {
  height: 70px;
}

.irs-line {
  height: 6px;
  top: 25px;
  background-color: #D1D6E0;
  border-radius: 3px;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.3) inset;
}

.irs-line-left {
  height: 6px;
  background-position: 0 -30px;
}

.irs-line-mid {
  height: 6px;
  background-position: 0 0;
}

.irs-line-right {
  height: 6px;
  background-position: 100% -30px;
}

.irs-bar {
  height: 6px;
  top: 25px;
  background-image: none;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.3) inset;
}

.irs-bar-edge {
  top: 25px;
  height: 6px;
  width: 6px;
  background-position: 0 -90px;
}

.irs-shadow {
  height: 5px;
  top: 25px;
  background: #000;
  opacity: 0.25;
}

.lt-ie9 .irs-shadow {
  filter: alpha(opacity=25);
}

.irs-slider {
  width: 11px;
  height: 18px;
  top: 31px;
  background-position: 0 -120px;
}
.irs-slider.state_hover, .irs-slider:hover {
  background-position: 0 -150px;
}

.irs-min, .irs-max {
  color: #999;
  font-size: 10px;
  line-height: 1.333;
  text-shadow: none;
  top: 0;
  padding: 1px 3px;
  background: #e1e4e9;
  border-radius: 4px;
}

.irs-from, .irs-to, .irs-single {
  color: #fff;
  font-size: 10px;
  line-height: 1.333;
  text-shadow: none;
  padding: 1px 5px;
  border-radius: 4px;
}

.irs-bar, .irs-from, .irs-to, .irs-single {
  background-color: #0082c5;
}

.irs-from:after, .irs-to:after, .irs-single:after {
  position: absolute;
  display: block;
  content: "";
  bottom: -6px;
  left: 50%;
  width: 0;
  height: 0;
  margin-left: -3px;
  overflow: hidden;
  border: 3px solid transparent;
  border-top-color: #0082c5;
}

.irs-grid {
  height: 34px;
}

.irs-grid-pol {
  background: #c0c0c0;
}

.irs-grid-text {
  bottom: 12px;
  color: #c0c0c0;
}

html {
  background-color: rgb(255, 255, 255);
}

#mobile-nav {
  display: none;
}

@media only screen and (max-width: 999px) {
  .has-fixed-site-header .admin-toolbar {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    z-index: 201;
  }
  .has-fixed-site-header .admin-toolbar-phantom {
    height: 50px;
  }
}
/* - - - - - - - - - - - - - - - - - - - - - - - - - - -
 * LAYOUT
 * - - - - - - - - - - - - - - - - - - - - - - - - - - -
 * */
#content,
.site-header-wrapper,
.site-footer-wrapper {
  padding-left: 30px;
  padding-right: 30px;
}
@media only screen and (max-width: 500px) {
  #content,
  .site-header-wrapper,
  .site-footer-wrapper {
    padding-left: 10px;
    padding-right: 10px;
  }
}

.customer-page #main-content,
.site-footer-content,
.block,
.search-results,
.product-overview,
.category-description,
.preview-cart-import,
.page-headline,
#blog-page .layout,
#blog-post-page .layout {
  max-width: 1400px;
  margin-left: auto;
  margin-right: auto;
}

@media only screen and (max-width: 1400px) {
  .product-overview {
    padding-left: 0;
    padding-right: 0;
  }
}
.slideshow-media,
.category-list-block,
.product-list-block,
.search-results-block,
.product-block,
.category-block,
.header-region .block,
.customer-page .block,
#search-page .page-headline,
#product-list-page .page-headline,
.password-recovery .page-headline,
.blog-latest-posts-block,
.blog-categories-block,
.blog-tags-block,
.blog-archive-block,
.instagram-block {
  max-width: none;
}

.slideshow-media {
  margin-left: -30px;
  margin-right: -30px;
}
@media only screen and (max-width: 500px) {
  .slideshow-media {
    margin-left: -10px;
    margin-right: -10px;
  }
}

.header-region .block,
.footer-region .block {
  margin-left: 0;
  margin-right: 0;
}

.header-region .block,
.footer-region .block,
.slideshow-media,
.customer-page .block {
  padding-left: 0;
  padding-right: 0;
}

.theme-layout-boxed .site-header,
.theme-layout-boxed .site-footer,
.theme-layout-boxed #content {
  max-width: 1400px;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
}
.theme-layout-boxed .site-header.fixed-site-header {
  padding-left: 0;
  padding-right: 0;
}
.theme-layout-boxed .block-fullwidth,
.theme-layout-boxed .slideshow-block {
  margin-left: -30px;
  margin-right: -30px;
}
@media only screen and (max-width: 500px) {
  .theme-layout-boxed .block-fullwidth,
  .theme-layout-boxed .slideshow-block {
    margin-left: 10px;
    margin-right: 10px;
  }
}

#content {
  padding-bottom: 60px;
  padding-top: 30px;
}

.admin-user-logged-in.is-preview #admin-toolbar-phantom {
  display: none;
}

#start-page.has-slideshow-media #content {
  margin-top: 0;
}
#start-page.has-slideshow-media.has-sidebar #content {
  margin-top: 30px;
}
#start-page.has-slideshow-media .admin-user-logged-in.has-sidebar #content {
  margin-top: 30px;
}
#start-page.has-slideshow-media .admin-user-logged-in.is-preview #content {
  margin-top: 0;
}

@media only screen and (max-width: 760px) {
  .admin-user-logged-in #content {
    margin-top: 30px;
  }
  .admin-user-logged-in.is-preview #content {
    margin-top: 30px;
  }
  .has-slideshow-media.admin-user-logged-in #content {
    margin-top: 0;
  }
  .has-slideshow-media.admin-user-logged-in.is-preview #content {
    margin-top: 0;
  }
}
/* - - - - - - - - - - - - - - - - - - - - - - - - - - -
 * Sidebar
 * - - - - - - - - - - - - - - - - - - - - - - - - - - -
 * */
.has-sidebar #content {
  display: -webkit-flex;
  display: flex;
}
.has-sidebar #content .block,
.has-sidebar #content .page-headline {
  padding-left: 0;
  padding-right: 0;
  margin-left: 0;
}
.has-sidebar #content .slideshow-media {
  margin-left: 0;
  margin-right: 0;
}
.has-sidebar #main-content {
  -webkit-order: 2;
          order: 2;
  -webkit-flex: 1;
          flex: 1;
  min-width: 0;
}
.has-sidebar #content-sidebar {
  padding-right: 30px;
  width: 200px;
}
@media only screen and (max-width: 700px) {
  .has-sidebar #content {
    -webkit-flex-direction: column;
            flex-direction: column;
  }
  .has-sidebar #main-content,
  .has-sidebar #content-sidebar {
    width: auto;
    padding-right: 0;
    margin-top: 20px;
  }
  .has-sidebar #main-content {
    -webkit-order: 1;
            order: 1;
  }
  .has-sidebar #content-sidebar {
    -webkit-order: 2;
            order: 2;
  }
}

/* - - - - - - - - - - - - - - - - - - - - - - - - - - -
 * Sticky footer
 * - - - - - - - - - - - - - - - - - - - - - - - - - - -
 * */
#page-container {
  display: -webkit-flex;
  display: flex;
  min-height: 100vh;
  -webkit-flex-direction: column;
          flex-direction: column;
  min-height: 100%;
}

.site-header,
.site-footer {
  -webkit-flex: none;
          flex: none;
}

#content {
  -webkit-flex: 1 0 auto;
          flex: 1 0 auto;
  width: 100%;
}

.has-sidebar #page-container {
  display: block;
}

/* - - - - - - - - - - - - - - - - - - - - - - - - - - -
 * Layout classes
 * - - - - - - - - - - - - - - - - - - - - - - - - - - -
 * */
.group-items.two, .group-items.three, .group-items.four {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
}
@media only screen and (max-width: 600px) {
  .group-items.two, .group-items.three, .group-items.four {
    -webkit-flex-direction: column;
            flex-direction: column;
  }
}
.group-items .group-item {
  -webkit-flex: 1 0 0;
          flex: 1 0 0;
  margin-left: 20px;
}
.group-items .group-item:first-child {
  margin-left: 0;
}
@media only screen and (max-width: 600px) {
  .group-items .group-item {
    margin-left: 0;
  }
}

.column-content.two, .column-content.three, .column-content.four {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-justify-content: space-between;
          justify-content: space-between;
}
.column-content.two .column, .column-content.three .column, .column-content.four .column {
  -webkit-flex: 1 0 auto;
          flex: 1 0 auto;
  margin-left: 20px;
  margin-bottom: 20px;
}
.column-content.two .column:first-child, .column-content.three .column:first-child, .column-content.four .column:first-child {
  margin-left: 0;
}

.two.column-content .column {
  width: calc(50% - 20px);
}
@media only screen and (max-width: 600px) {
  .two.column-content {
    -webkit-flex-direction: column;
            flex-direction: column;
    margin-left: 0;
  }
  .two.column-content .column {
    width: auto;
    margin-left: 0;
  }
}

.three.column-content .column {
  width: calc(33.3333333333% - 20px);
}
@media only screen and (max-width: 800px) {
  .three.column-content {
    -webkit-flex-direction: column;
            flex-direction: column;
    margin-left: 0;
  }
  .three.column-content .column {
    width: auto;
    margin-left: 0;
  }
}

.four.column-content .column {
  width: calc(25% - 20px);
}
@media only screen and (max-width: 900px) {
  .four.column-content {
    -webkit-flex-direction: column;
            flex-direction: column;
    margin-left: 0;
  }
  .four.column-content .column {
    width: auto;
    margin-left: 0;
  }
}

#content-sidebar .three.column-content,
#content-sidebar .four.column-content {
  -webkit-flex-direction: column;
          flex-direction: column;
}
#content-sidebar .three.column-content .column,
#content-sidebar .four.column-content .column {
  width: auto;
  margin-left: 0;
}

/* - - - - - - - - - - - - - - - - - - - - - - - - - - -
 * WYSIWYG - Clear floating images
 * - - - - - - - - - - - - - - - - - - - - - - - - - - -
 * */
/* - - - - - - - - - - - - - - - - - - - - - - - - - - -
 * instafeed Instagram v2 module
 * - - - - - - - - - - - - - - - - - - - - - - - - - - -
 * */
.instagram-module {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  -webkit-justify-content: center;
          justify-content: center;
  gap: 28px;
  -webkit-align-items: flex-start;
          align-items: flex-start;
}
.instagram-module a.instagram-link,
.instagram-module a.instagram-link:hover {
  text-decoration: none;
  color: initial;
}
.instagram-module a.instagram-link figure.instagram-figure,
.instagram-module a.instagram-link:hover figure.instagram-figure {
  position: relative;
}
.instagram-module a.instagram-link img.instagram-img,
.instagram-module a.instagram-link:hover img.instagram-img {
  height: 300px;
  width: 300px;
}
.instagram-module a.instagram-link .instagram-album.instagram-figure:before,
.instagram-module a.instagram-link:hover .instagram-album.instagram-figure:before {
  content: "\e413";
  font-family: "Material Icons";
  color: #FFF;
  position: absolute;
  font-size: 32px;
  top: 8px;
  right: 8px;
}
.instagram-module a.instagram-link .instagram-video.instagram-figure:before,
.instagram-module a.instagram-link:hover .instagram-video.instagram-figure:before {
  content: "\e037";
  font-family: "Material Icons";
  color: #FFF;
  position: absolute;
  font-size: 40px;
  top: 8px;
  right: 4px;
}
.instagram-module a.instagram-link figcaption.instagram-caption,
.instagram-module a.instagram-link:hover figcaption.instagram-caption {
  display: none;
}
@media only screen and (max-width: 650px) {
  .instagram-module a.instagram-link figcaption.instagram-caption,
  .instagram-module a.instagram-link:hover figcaption.instagram-caption {
    margin-top: -4px;
    text-align: left;
    font-weight: 600;
    text-decoration: none;
  }
}

/* - - - - - - - - - - - - - - - - - - - - - - - - - - -
 * HEADER
 * - - - - - - - - - - - - - - - - - - - - - - - - - - -
 * */
.header-region .currency-block h3,
.header-region .currency-block .select-type .caption,
.header-region .language-block h3,
.header-region .language-block .select-type .caption,
.footer-region .currency-block h3,
.footer-region .currency-block .select-type .caption,
.footer-region .language-block h3,
.footer-region .language-block .select-type .caption {
  display: none;
}
.header-region .select-type,
.footer-region .select-type {
  margin-top: 0;
}
.header-region .select-type .control:after,
.footer-region .select-type .control:after {
  top: -8px;
  width: 16px;
  height: 25px;
  top: 0;
  line-height: 25px;
}
.header-region select,
.footer-region select {
  text-transform: uppercase;
  width: auto;
  border-bottom-style: none;
  padding-top: 0;
  padding-bottom: 0;
}
.header-region .select-language + .currency-block-with-text,
.footer-region .select-language + .currency-block-with-text {
  margin-left: 0.5em;
}

.site-header {
  background-color: #fff;
}

.fixed-site-header {
  background-color: transparent;
  margin-left: 0;
  margin-right: 0;
}
.fixed-site-header .site-header-wrapper {
  background-color: #fff;
}

.site-header-content {
  position: relative;
}

.fixed-site-header {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 200;
  opacity: 1;
}

.admin-user-logged-in .fixed-site-header {
  top: 50px;
}
.admin-user-logged-in.is-preview .fixed-site-header {
  top: 0;
}

#site-header-phantom {
  display: none;
}

.has-fixed-site-header #site-header-phantom {
  display: block;
}

.header-item {
  -webkit-flex: 1;
          flex: 1;
  position: relative;
}

.header-item-2 {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
}

@media only screen and (max-width: 980px) {
  #home {
    text-align: center;
    margin: 0 auto;
  }
}

#shop-logo {
  transition: max-height 500ms cubic-bezier(0.25, 0.1, 0.25, 1);
  display: block;
  margin: 0 auto;
  font-size: 32px;
  line-height: 36px;
  text-transform: uppercase;
}
@media only screen and (max-width: 500px) {
  #shop-logo {
    font-size: 20px;
  }
}

.small-header-size #shop-logo {
  max-height: 50px;
}

.header-region-1 {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  -webkit-align-items: center;
          align-items: center;
}

.header-region-2 {
  -webkit-flex: 1;
          flex: 1;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  -webkit-justify-content: flex-end;
          justify-content: flex-end;
  -webkit-align-items: center;
          align-items: center;
}

.header-region-3 {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  -webkit-justify-content: flex-start;
          justify-content: flex-start;
  -webkit-align-items: center;
          align-items: center;
}

/* - - - - - - - - - - - - - - - - - - - - - - - - - - -
 * REGION ADJUSTMENTS
 * - - - - - - - - - - - - - - - - - - - - - - - - - - -
 * */
.header-region .currency-block,
.header-region .language-block {
  margin-top: 0;
}

.header-region-3 .cart-block,
.header-region-3 .search-block,
.header-region-3 .favorite-cart-block.block {
  margin-left: auto;
}

.header-region .search-block + .cart-block,
.header-region .cart-block + .search-block {
  margin-left: 0;
}

.header-region .block {
  margin-left: 15px;
}
.header-region .block:first-child {
  margin-left: 0;
}

.header-items,
.header-region .control select,
.footer-region .control select,
.header-region .menu-level-1-title {
  font-size: 0.9rem;
}

@media only screen and (max-width: 980px) {
  .header-item-1 {
    padding-left: 50px;
  }
}
.mobile .header-item-1 {
  padding-left: 50px;
}

/* - - - - - - - - - - - - - - - - - - - - - - - - - - -
 * FOOTER
 * - - - - - - - - - - - - - - - - - - - - - - - - - - -
 * */
.site-footer-wrapper {
  padding-top: 30px;
  padding-bottom: 30px;
}

.footer-region h2:first-child,
.footer-region h3:first-child,
.footer-region h4:first-child,
.footer-region p:first-child {
  margin-top: 0;
}

.site-footer-content {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  -webkit-flex-direction: column;
          flex-direction: column;
}
.site-footer-content .block {
  margin-bottom: 20px;
}
@media only screen and (max-width: 500px) {
  .site-footer-content .newsletter-block {
    margin-left: 0;
    margin-right: 0;
  }
}

.software-by-starweb {
  text-align: center;
  text-transform: uppercase;
  font-size: 0.6rem;
  opacity: 0.5;
}
.software-by-starweb i, .software-by-starweb a {
  font-weight: normal;
  font-style: normal;
}
.software-by-starweb a {
  color: rgb(55, 55, 55);
}

.desktop .software-by-starweb a:hover {
  text-decoration: none;
  color: rgb(55, 55, 55);
}

.footer-region:empty {
  display: none;
}

/* // FOOTER LAYOUT
 * .two-footer-regions,
 * .three-footer-regions,
 * .four-footer-regions
 *  display: flex
 *  flex-wrap: wrap
 *  flex-direction: row
 *  margin-left: -20px
 *
 *  .footer-region
 *    flex: 1
 *    margin-left: $column-margin
 *    margin-bottom: $column-margin
 *
 * .two-footer-regions
 *  .footer-region
 *    width: calc(50% - 20px)
 *
 *  +at-max-width(600px)
 *    flex-direction: column
 *    margin-left: 0
 *
 *    .footer-region
 *      width: auto
 *      margin-left: 0
 *
 * .three-footer-regions
 *  .footer-region
 *    width: calc(33.3333333333% - 20px)
 *
 *  +at-max-width(800px)
 *    flex-direction: column
 *    margin-left: 0
 *
 *    .footer-region
 *      width: auto
 *      margin-left: 0
 *
 * .four-footer-regions
 *  .footer-region
 *    width: calc(25% - 20px)
 *
 *  +at-max-width(900px)
 *    flex-direction: column
 *    margin-left: 0
 *
 *    .footer-region
 *      width: auto
 *      margin-left: 0 */
.form-block,
.newsletter-block,
.currency-block,
.faq-block,
.search-results-content,
.product-list-block,
.language-block,
.instagram-block {
  margin-top: 30px;
}

.currency-block h3,
.language-block h3 {
  display: none;
}

.site-footer .newsletter-block,
.site-footer .currency-block,
.site-footer .language-block {
  max-width: 400px;
}

/* - - - - - - - - - - - - - - - - - - - - - - - - - - -
 * SOCIAL.
 * - - - - - - - - - - - - - - - - - - - - - - - - - - -
 * */
.rating {
  font: 0/0 a;
  margin-top: 6px;
  text-align: center;
  min-height: 12px;
}
.rating span {
  display: none;
}
.rating:before, .rating:after {
  display: inline;
  font-size: 12px;
}
.rating:before {
  color: #ebbf3c;
}
.rating:after {
  color: #E0E0E0;
}

.rating-1:before {
  content: "\f005";
}
.rating-1:after {
  content: "\f005\f005\f005\f005";
}

.rating-2:before {
  content: "\f005\f005";
}
.rating-2:after {
  content: "\f005\f005\f005";
}

.rating-3:before {
  content: "\f005\f005\f005";
}
.rating-3:after {
  content: "\f005\f005";
}

.rating-4:before {
  content: "\f005\f005\f005\f005";
}
.rating-4:after {
  content: "\f005";
}

.rating-5:before {
  content: "\f005\f005\f005\f005\f005";
}
.rating-5:after {
  content: "";
}

.social-sharing {
  margin: 40px 0 20px;
}
.social-sharing h3 {
  margin: 0;
}
.social-sharing a {
  display: block;
  float: left;
  margin-left: 0px;
}
.social-sharing a:first-child {
  margin-left: 0;
}

.product-rating a, .product-rating a:hover {
  text-decoration: none;
}
.product-rating.review-count-0, .product-rating:before {
  display: none;
}
.product-rating .rating:before, .product-rating .rating:after {
  font-size: 24px;
}
.product-rating .info {
  display: block;
}
.product-rating .info.review-count-0, .product-rating .info:before {
  display: none;
}
.product-rating .count {
  display: none;
}
.product-rating .info,
.product-rating .add-new {
  display: block;
}
.product-rating .info:hover,
.product-rating .add-new:hover {
  text-decoration: underline;
}

#product-reviews .product-rating .title {
  float: left;
  padding-right: 10px;
}
#product-reviews .product-rating .info {
  display: block;
}
#product-reviews .product-rating .info {
  opacity: 0.5;
  font-size: 80%;
  font-size: 0.8rem;
}
#product-reviews .product-rating .info:hover {
  text-decoration: none;
}

.review-ratings {
  margin: 10px 0;
}
.review-ratings .rating-selector input {
  display: none;
}
.review-ratings .rating-selector .caption {
  display: none;
}
.review-ratings .pending-review {
  position: absolute;
  top: 0;
  right: 0;
  font-weight: 300;
  line-height: 1.1em;
  padding: 3px 8px;
  background-color: rgba(255, 255, 255, 0.1);
  font-size: 80%;
  font-size: 0.8rem;
}
@media only screen and (max-width: 500px) {
  .review-ratings li {
    padding-left: 10px;
  }
  .review-ratings .rating,
  .review-ratings .rating-score-name,
  .review-ratings .rating-selector {
    position: static;
    top: auto;
    left: auto;
  }
  .review-ratings .facebook-login-button,
  .review-ratings .button {
    float: none;
    width: 100%;
    margin-top: 10px;
    display: block;
    text-align: center;
  }
}

.add-review-comment {
  width: calc(33.3333333333% - 15px);
}
@media only screen and (max-width: 1400px) {
  .add-review-comment {
    width: auto;
  }
}

#review-list {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  margin-left: -20px;
  max-width: 100%;
}
@media only screen and (max-width: 1000px) {
  #review-list {
    -webkit-flex-direction: column;
            flex-direction: column;
  }
}

.review-rating,
.no-reviews {
  width: calc(33.3333333333% - 20px);
  margin-left: 20px;
  display: -webkit-flex;
  display: flex;
}
@media only screen and (max-width: 1000px) {
  .review-rating,
  .no-reviews {
    width: auto;
    -webkit-flex-direction: column;
            flex-direction: column;
  }
}

.review-content {
  padding-top: 0;
}

.review-rating,
.add-review-comment {
  position: relative;
  padding: 10px 10px 20px 10px;
  margin-bottom: 10px;
  background-color: #fff;
  border: 1px dotted silver;
}
.review-rating form,
.add-review-comment form {
  margin: 0;
}
.review-rating .rating,
.review-rating .rating-selector,
.add-review-comment .rating,
.add-review-comment .rating-selector {
  width: 105px;
  text-align: left;
}
.review-rating .rating:before, .review-rating .rating:after,
.review-rating .rating-selector:before,
.review-rating .rating-selector:after,
.add-review-comment .rating:before,
.add-review-comment .rating:after,
.add-review-comment .rating-selector:before,
.add-review-comment .rating-selector:after {
  font-size: 18px;
}
.review-rating .rating-score-name,
.add-review-comment .rating-score-name {
  position: absolute;
  top: 39px;
  left: 10px;
  width: 95px;
}
@media only screen and (max-width: 1000px) {
  .review-rating .rating-score-name,
  .add-review-comment .rating-score-name {
    position: static;
    top: auto;
    left: auto;
    width: auto;
  }
}
.review-rating time,
.review-rating .rating-score-name,
.add-review-comment time,
.add-review-comment .rating-score-name {
  opacity: 0.5;
  font-size: 80%;
  font-size: 0.8rem;
}
.review-rating .login-label,
.add-review-comment .login-label {
  font-size: 80%;
  font-size: 0.8rem;
  margin-bottom: 4px;
}

.add-review-comment {
  margin-bottom: 0;
}

.add-review-comment .rating-selector {
  position: absolute;
  top: 13px;
}

.add-review-comment .facebook-login-button {
  float: left;
}
.add-review-comment .button {
  float: right;
  width: auto;
}
.add-review-comment .review-author {
  margin-left: 115px;
  line-height: 1.2em;
}
.add-review-comment .textarea-type {
  margin-top: 30px;
}

.review {
  -webkit-flex: 1;
          flex: 1;
}

.review-author {
  font-weight: 600;
  margin-top: 3px;
}

.review-content {
  padding-top: 5px;
}

#product-reviews {
  clear: both;
  padding-top: 20px;
}
#product-reviews h3 {
  margin-top: 0;
}

.rating-selector .option {
  float: left;
}

.rating-selector .caption {
  line-height: 1.2em;
  margin-top: 4px;
}
.rating-selector label {
  display: block;
  width: 18px;
  height: 18px;
  text-indent: -9999px;
  cursor: pointer;
  position: relative;
}
.rating-selector label:after {
  display: block;
  text-align: center;
  line-height: 18px;
  font-size: 18px;
  color: #E0E0E0;
  content: "\f005";
  text-indent: 0;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
.rating-selector .rating-score-name {
  position: absolute;
  top: 18px;
  left: 0px;
  width: 105px;
}

.error.rating-error:before {
  content: "←";
}

.rating-selector .selected label:after {
  color: #ebbf3c;
}

.ratings.gallery-layout-1 .rating {
  text-align: left;
}

@media only screen and (max-width: less-than-ipad-portrait) {
  .ratings.gallery .details .description {
    min-height: 0;
  }
  .ratings.gallery .rating {
    text-align: left;
  }
}
@media only screen and (max-width: less-than-iphone-landscape) {
  .ratings.gallery .details {
    min-height: 0;
  }
}
/* - - - - - - - - - - - - - - - - - - - - - - - - - - -
 * GALLERY
 * - - - - - - - - - - - - - - - - - - - - - - - - - - -
 * */
.add-to-cart-action {
  padding-left: 35px;
  padding-right: 35px;
}
.add-to-cart-action.loading span {
  position: relative;
}
.add-to-cart-action.loading span:after {
  content: "";
  border-radius: 50%;
  border: 2px solid #ffffff;
  border-top: 2px solid transparent;
  width: 16px;
  height: 16px;
  top: -1px;
  right: -24px;
  -webkit-animation: spin 1s linear infinite;
          animation: spin 1s linear infinite;
  display: block;
  position: absolute;
}

@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}
.gallery {
  margin-left: -20px;
  margin-top: 20px;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
}
.gallery h3 {
  margin-top: 0;
  line-height: 1.1em;
  font-size: 1.1rem;
}
.gallery h3,
.gallery .short-description {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.product-list-block-with-image-reset .products-gallery figure:before {
  display: none;
}
.product-list-block-with-image-reset .products-gallery figure .figure-content,
.product-list-block-with-image-reset .products-gallery figure .img-wrap {
  position: static;
  top: auto;
  left: auto;
  width: auto;
  height: auto;
}
.product-list-block-with-image-reset .products-gallery figure .img-wrap {
  display: block;
  text-align: center;
}
.product-list-block-with-image-reset .products-gallery figure .img-wrap,
.product-list-block-with-image-reset .products-gallery figure img {
  -webkit-transform: none;
          transform: none;
}
.product-list-block-with-image-reset .products-gallery figure img {
  position: static;
  top: auto;
  left: auto;
}

.gallery-item {
  margin-bottom: 20px;
  margin-left: 20px;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
}
.gallery-item .button {
  position: absolute;
  top: 0;
  right: 0;
  font-size: 0.8rem;
  opacity: 0;
  transition: opacity 0.4s ease-in-out;
}

.no-products {
  text-align: center;
  width: 100%;
  margin-left: 20px;
}

.gallery-info-link {
  position: relative;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-flex: 1 0 auto;
          flex: 1 0 auto;
}

.details {
  text-align: center;
}

.gallery-item-description {
  -webkit-flex: 1 0 auto;
          flex: 1 0 auto;
}

.gallery-info-link,
.desktop .gallery-info-link:hover {
  text-decoration: none;
  color: rgb(55, 55, 55);
}

.product-list-block-with-full-length-description .gallery h3,
.product-list-block-with-full-length-description .gallery .short-description {
  overflow: visible;
  text-overflow: clip;
  white-space: normal;
}

@media only screen and (max-width: 500px) {
  .gallery {
    margin-left: -10px;
  }
  .gallery-item,
  .no-products {
    margin-left: 10px;
    margin-bottom: 10px;
  }
}
.categories-gallery .gallery-item {
  width: calc(25% - 20px);
}
@media only screen and (max-width: 1000px) {
  .categories-gallery .gallery-item {
    width: calc(50% - 20px);
  }
}
@media only screen and (max-width: 500px) {
  .categories-gallery .gallery-item {
    width: calc(50% - 10px);
  }
}
.categories-gallery figure {
  text-align: center;
  -webkit-flex: 1 0 auto;
          flex: 1 0 auto;
}
.categories-gallery .gallery-item-description {
  margin-top: 0.2em;
}

.category-list-block-with-centered-images .categories-gallery figure {
  position: relative;
  overflow: hidden;
}
.category-list-block-with-centered-images .categories-gallery figure:before {
  content: "";
  display: block;
  padding-top: 100%;
  height: 0;
  width: 100%;
}
.category-list-block-with-centered-images .categories-gallery figure .figure-content,
.category-list-block-with-centered-images .categories-gallery figure .img-wrap {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.category-list-block-with-centered-images .categories-gallery figure .img-wrap {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  -webkit-transform: translate(50%, 50%) translateZ(0);
          transform: translate(50%, 50%) translateZ(0);
}
.category-list-block-with-centered-images .categories-gallery figure img {
  position: absolute;
  display: inline-block;
  top: 0;
  left: 0;
  -webkit-transform: translate(-50%, -50%) translateZ(0);
          transform: translate(-50%, -50%) translateZ(0);
  max-width: 100%;
  max-height: 100%;
  width: auto;
  height: auto;
  -webkit-perspective: 1000;
  -webkit-backface-visibility: hidden;
}

.products-gallery figure,
.related-products-gallery figure,
.bundled-products-gallery figure {
  position: relative;
  overflow: hidden;
}
.products-gallery figure:before,
.related-products-gallery figure:before,
.bundled-products-gallery figure:before {
  content: "";
  display: block;
  padding-top: 100%;
  height: 0;
  width: 100%;
}
.products-gallery figure .figure-content,
.products-gallery figure .img-wrap,
.related-products-gallery figure .figure-content,
.related-products-gallery figure .img-wrap,
.bundled-products-gallery figure .figure-content,
.bundled-products-gallery figure .img-wrap {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.products-gallery figure .img-wrap,
.related-products-gallery figure .img-wrap,
.bundled-products-gallery figure .img-wrap {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  -webkit-transform: translate(50%, 50%) translateZ(0);
          transform: translate(50%, 50%) translateZ(0);
}
.products-gallery figure img,
.related-products-gallery figure img,
.bundled-products-gallery figure img {
  position: absolute;
  display: inline-block;
  top: 0;
  left: 0;
  -webkit-transform: translate(-50%, -50%) translateZ(0);
          transform: translate(-50%, -50%) translateZ(0);
  max-width: 100%;
  max-height: 100%;
  width: auto;
  height: auto;
  -webkit-perspective: 1000;
  -webkit-backface-visibility: hidden;
}
.products-gallery .product-info,
.related-products-gallery .product-info,
.bundled-products-gallery .product-info {
  background-color: #fff;
}
.products-gallery .details,
.related-products-gallery .details,
.bundled-products-gallery .details {
  padding: 10px;
  display: -webkit-flex;
  display: flex;
  -webkit-flex: 1 0 auto;
          flex: 1 0 auto;
  -webkit-flex-direction: column;
          flex-direction: column;
}
.products-gallery.gallery-layout-1.has-info-buy-buttons .details, .products-gallery.gallery-layout-1.has-info-buttons .details,
.related-products-gallery.gallery-layout-1.has-info-buy-buttons .details,
.related-products-gallery.gallery-layout-1.has-info-buttons .details,
.bundled-products-gallery.gallery-layout-1.has-info-buy-buttons .details,
.bundled-products-gallery.gallery-layout-1.has-info-buttons .details {
  padding-right: 60px;
}
.products-gallery .product-details,
.products-gallery .product-sku,
.related-products-gallery .product-details,
.related-products-gallery .product-sku,
.bundled-products-gallery .product-details,
.bundled-products-gallery .product-sku {
  display: none;
}
.products-gallery .product-sku,
.related-products-gallery .product-sku,
.bundled-products-gallery .product-sku {
  margin: 0.6em 0;
  font-family: "Courier New", Courier, monospace;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.products-gallery .short-description,
.products-gallery .product-sku,
.products-gallery .original-price,
.related-products-gallery .short-description,
.related-products-gallery .product-sku,
.related-products-gallery .original-price,
.bundled-products-gallery .short-description,
.bundled-products-gallery .product-sku,
.bundled-products-gallery .original-price {
  opacity: 0.5;
  font-size: 0.8rem;
  line-height: 1.2em;
}
.products-gallery .product-price,
.related-products-gallery .product-price,
.bundled-products-gallery .product-price {
  min-height: 53px;
  margin-top: 0.2em;
}
.products-gallery .original-price,
.related-products-gallery .original-price,
.bundled-products-gallery .original-price {
  line-height: 1em;
}
.products-gallery .short-description,
.related-products-gallery .short-description,
.bundled-products-gallery .short-description {
  margin: 0.2em 0;
}

.desktop .gallery-item:hover .button {
  opacity: 1;
}

.touch .gallery-item .button {
  opacity: 1;
}

.products-gallery .gallery-item,
.products-gallery.gallery-layout-4 .gallery-item {
  width: calc(12.5% - 20px);
}
@media only screen and (max-width: 1600px) {
  .products-gallery .gallery-item,
  .products-gallery.gallery-layout-4 .gallery-item {
    width: calc(16.6666666667% - 20px);
  }
}
@media only screen and (max-width: 1200px) {
  .products-gallery .gallery-item,
  .products-gallery.gallery-layout-4 .gallery-item {
    width: calc(20% - 20px);
  }
}
@media only screen and (max-width: 850px) {
  .products-gallery .gallery-item,
  .products-gallery.gallery-layout-4 .gallery-item {
    width: calc(25% - 20px);
  }
}
@media only screen and (max-width: 500px) {
  .products-gallery .gallery-item,
  .products-gallery.gallery-layout-4 .gallery-item {
    width: calc(50% - 10px);
  }
}

.theme-layout-boxed .products-gallery.gallery-layout-4 .gallery-item,
.products-gallery.gallery-layout-3 .gallery-item {
  width: calc(16.6666666667% - 20px);
}
@media only screen and (max-width: 1600px) {
  .theme-layout-boxed .products-gallery.gallery-layout-4 .gallery-item,
  .products-gallery.gallery-layout-3 .gallery-item {
    width: calc(20% - 20px);
  }
}
@media only screen and (max-width: 1200px) {
  .theme-layout-boxed .products-gallery.gallery-layout-4 .gallery-item,
  .products-gallery.gallery-layout-3 .gallery-item {
    width: calc(25% - 20px);
  }
}
@media only screen and (max-width: 850px) {
  .theme-layout-boxed .products-gallery.gallery-layout-4 .gallery-item,
  .products-gallery.gallery-layout-3 .gallery-item {
    width: calc(33.3333333333% - 20px);
  }
}
@media only screen and (max-width: 500px) {
  .theme-layout-boxed .products-gallery.gallery-layout-4 .gallery-item,
  .products-gallery.gallery-layout-3 .gallery-item {
    width: calc(50% - 10px);
  }
}

.theme-layout-boxed .products-gallery.gallery-layout-3 .gallery-item {
  width: calc(25% - 20px);
}
@media only screen and (max-width: 1600px) {
  .theme-layout-boxed .products-gallery.gallery-layout-3 .gallery-item {
    width: calc(33.3333333333% - 20px);
  }
}
@media only screen and (max-width: 1200px) {
  .theme-layout-boxed .products-gallery.gallery-layout-3 .gallery-item {
    width: calc(50% - 20px);
  }
}

.products-gallery.gallery-layout-1 .gallery-item {
  width: 100%;
}
.products-gallery.gallery-layout-1 .product-info {
  -webkit-flex-direction: row;
          flex-direction: row;
}
.products-gallery.gallery-layout-1 figure:before {
  display: none;
}
.products-gallery.gallery-layout-1 figure .figure-content,
.products-gallery.gallery-layout-1 figure .img-wrap {
  position: static;
  top: auto;
  left: auto;
  width: auto;
  height: auto;
}
.products-gallery.gallery-layout-1 figure .img-wrap {
  display: block;
  text-align: center;
}
.products-gallery.gallery-layout-1 figure .img-wrap,
.products-gallery.gallery-layout-1 figure img {
  -webkit-transform: none;
          transform: none;
}
.products-gallery.gallery-layout-1 figure img {
  position: static;
  top: auto;
  left: auto;
}
.products-gallery.gallery-layout-1 .details {
  width: 70%;
  margin-left: 10px;
  text-align: left;
}
.products-gallery.gallery-layout-1 .gallery-item-description {
  -webkit-flex-grow: 0;
          flex-grow: 0;
}
.products-gallery.gallery-layout-1 .product-price {
  min-height: 0;
}
.products-gallery.gallery-layout-1 h3,
.products-gallery.gallery-layout-1 .short-description {
  overflow: visible;
  text-overflow: clip;
  white-space: normal;
}
@media only screen and (min-width: 1600px) {
  .products-gallery.gallery-layout-1 h3 {
    font-size: 1.7rem;
  }
  .products-gallery.gallery-layout-1 .short-description {
    font-size: 1rem;
  }
}

.gallery-item {
  position: relative;
}

.save-percent-info {
  position: absolute;
  top: 1em;
  left: 1em;
  background-color: #000;
  color: white;
  font-size: 20px;
  width: 2.2em;
  height: 2.2em;
  border-radius: 50%;
}
.save-percent-info span {
  display: inline-block;
}
.save-percent-info .label {
  display: none;
}
.save-percent-info .percentage {
  margin-top: 0.45em;
  margin-left: 0.2em;
}
.save-percent-info .value {
  font-size: 1em;
}
.save-percent-info .value:before {
  content: "-";
}
.save-percent-info .unit {
  font-size: 0.5em;
  margin-left: -0.2em;
}

.product-label {
  position: absolute;
  overflow: hidden;
  text-align: center;
  pointer-events: none;
}
.product-label span {
  display: block;
  position: absolute;
  font-size: 10px;
  color: #FFF;
  background-color: #000;
  text-transform: uppercase;
  text-align: center;
  line-height: 20px;
  padding-left: 16px;
  padding-right: 16px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.product-label-0 {
  top: 0;
  right: 0;
  width: 75px;
  height: 75px;
}
.product-label-0 span {
  top: 18px;
  right: -22px;
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
  width: 100px;
}

.product-label-1 {
  left: 0;
  right: 0;
  bottom: 0;
  height: 20px;
}
.product-label-1 span {
  left: 0;
  right: 0;
}

.touch .has-product-label .button {
  opacity: 0;
}

.categories-gallery img {
  display: block;
  margin: 0 auto;
}

.gallery-header {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: flex-end;
          justify-content: flex-end;
}

.pagination {
  margin-right: auto;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
}
.pagination p {
  -webkit-order: 2;
          order: 2;
  margin-top: 0;
  margin-left: 4px;
  line-height: 26px;
}
.pagination p span {
  display: none;
}
.pagination p em {
  font-style: normal;
}
.pagination ul {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  margin-left: -2px;
}
.pagination li {
  display: none;
  margin-left: 2px;
}
.pagination li a, .pagination li span {
  display: block;
  background-color: rgb(55, 55, 55);
  color: white;
  text-indent: -9999px;
  width: 26px;
  height: 26px;
  position: relative;
}
.pagination li span {
  opacity: 0.5;
}
.pagination .previous,
.pagination .next {
  display: inline-block;
}
.pagination .previous > *:after,
.pagination .next > *:after {
  display: block;
  text-indent: 0;
  position: absolute;
  text-align: center;
  top: 50%;
  margin-top: -10px;
  width: 100%;
  font-size: 18px;
}
.pagination .previous > *:after {
  content: "\f104";
}
.pagination .next > *:after {
  content: "\f105";
}

.mobile .pagination p {
  line-height: 48px;
}
.mobile .pagination li a, .mobile .pagination li span {
  width: 48px;
  height: 48px;
}
.mobile .pagination .previous > *:after,
.mobile .pagination .next > *:after {
  margin-top: -13px;
  font-size: 26px;
}

.sort-order {
  margin-top: 0;
}

.control .sort-by-select {
  padding-top: 3px;
  font-size: 0.8rem;
  border-bottom-style: none;
  padding-right: 22px;
  direction: rtl;
}
.control .sort-by-select option {
  direction: ltr;
}

.sort-order .control:after {
  line-height: 26px;
  top: 0;
  right: -5px;
  width: 26px;
  height: 26px;
  font-size: 18px;
}

.desktop .pagination a:hover {
  color: white;
}

.gallery-layout {
  -webkit-order: 4;
          order: 4;
}

.gallery-layout,
.product-gallery-layout {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: flex-end;
          justify-content: flex-end;
}
.gallery-layout b,
.product-gallery-layout b {
  display: none;
}
.gallery-layout a,
.product-gallery-layout a {
  display: block;
  width: 26px;
  height: 26px;
  margin-left: 6px;
  color: rgb(55, 55, 55);
  opacity: 0.5;
  transition: color 0.2s linear;
  text-align: center;
  position: relative;
}
.gallery-layout a.current,
.product-gallery-layout a.current {
  cursor: default;
}
.gallery-layout a.current, .gallery-layout a:hover,
.product-gallery-layout a.current,
.product-gallery-layout a:hover {
  opacity: 1;
}
.gallery-layout a:before,
.product-gallery-layout a:before {
  position: absolute;
  width: 26px;
  display: block;
  top: -6px;
  left: -5px;
  font-size: 37px;
}
.gallery-layout a:first-child,
.product-gallery-layout a:first-child {
  margin-left: 0;
}
@media only screen and (max-width: 500px) {
  .gallery-layout a[data-layout="4"],
  .product-gallery-layout a[data-layout="4"] {
    display: none;
  }
}
.gallery-layout a[data-layout="4"]:before,
.product-gallery-layout a[data-layout="4"]:before {
  content: "\e61a";
}
.gallery-layout a[data-layout="3"]:before,
.product-gallery-layout a[data-layout="3"]:before {
  content: "\e61b";
}
.gallery-layout a[data-layout="1"]:before,
.product-gallery-layout a[data-layout="1"]:before {
  content: "\e61c";
}

.sort-order label {
  display: none;
}

.added-to-cart-feedback {
  position: absolute;
  display: none;
  opacity: 0;
  top: 30%;
  left: 50%;
  width: 50px;
  height: 50px;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 50%;
  text-align: center;
  box-shadow: 0 0 0 2px rgba(255, 255, 255, 0.3);
}
.added-to-cart-feedback.show {
  display: block;
}
.added-to-cart-feedback .feedback-icon:before {
  content: "\f00c";
  display: block;
  color: #fff;
  font-size: 30px;
  line-height: 50px;
}

.related-products-gallery .gallery-item,
.bundled-products-gallery .gallery-item {
  width: calc(16.6666666667% - 20px);
}
@media only screen and (max-width: 1200px) {
  .related-products-gallery .gallery-item,
  .bundled-products-gallery .gallery-item {
    width: calc(20% - 20px);
  }
}
@media only screen and (max-width: 850px) {
  .related-products-gallery .gallery-item,
  .bundled-products-gallery .gallery-item {
    width: calc(25% - 20px);
  }
}
@media only screen and (max-width: 700px) {
  .related-products-gallery .gallery-item,
  .bundled-products-gallery .gallery-item {
    width: calc(50% - 20px);
  }
}
@media only screen and (max-width: 500px) {
  .related-products-gallery .gallery-item,
  .bundled-products-gallery .gallery-item {
    width: calc(50% - 10px);
  }
}

.header-item .products-gallery .gallery-item,
.header-item .products-gallery.gallery-layout-4 .gallery-item {
  width: 140px;
}

.product-upsell-wrap {
  padding-bottom: 24px;
}

.product-upsell-slideshow {
  display: block;
  margin-left: 0;
}

.theme-checkout-layout-0 .product-upsell-slideshow.gallery-layout-1 .gallery-item {
  width: calc(25% - 20px);
}
@media only screen and (max-width: 1200px) {
  .theme-checkout-layout-0 .product-upsell-slideshow.gallery-layout-1 .gallery-item {
    width: calc(33.3333333333% - 20px);
  }
}
@media only screen and (max-width: 850px) {
  .theme-checkout-layout-0 .product-upsell-slideshow.gallery-layout-1 .gallery-item {
    width: calc(50% - 20px);
  }
}
.theme-checkout-layout-0 .product-upsell-slideshow.gallery-layout-3 .gallery-item {
  width: calc(16.6666666667% - 20px);
}
@media only screen and (max-width: 1200px) {
  .theme-checkout-layout-0 .product-upsell-slideshow.gallery-layout-3 .gallery-item {
    width: calc(25% - 20px);
  }
}
@media only screen and (max-width: 850px) {
  .theme-checkout-layout-0 .product-upsell-slideshow.gallery-layout-3 .gallery-item {
    width: calc(33.3333333333% - 20px);
  }
}
@media only screen and (max-width: 500px) {
  .theme-checkout-layout-0 .product-upsell-slideshow.gallery-layout-3 .gallery-item {
    width: calc(50% - 10px);
  }
}
.theme-checkout-layout-0 .product-upsell-slideshow.gallery-layout-4 .gallery-item {
  width: calc(12.5% - 20px);
}
@media only screen and (max-width: 1200px) {
  .theme-checkout-layout-0 .product-upsell-slideshow.gallery-layout-4 .gallery-item {
    width: calc(16.6666666667% - 20px);
  }
}
@media only screen and (max-width: 850px) {
  .theme-checkout-layout-0 .product-upsell-slideshow.gallery-layout-4 .gallery-item {
    width: calc(33.3333333333% - 20px);
  }
}
@media only screen and (max-width: 500px) {
  .theme-checkout-layout-0 .product-upsell-slideshow.gallery-layout-4 .gallery-item {
    width: calc(50% - 10px);
  }
}

.theme-checkout-layout-1 .product-upsell-slideshow.gallery-layout-1 .gallery-item {
  width: calc(33.3333333333% - 20px);
}
@media only screen and (max-width: 1200px) {
  .theme-checkout-layout-1 .product-upsell-slideshow.gallery-layout-1 .gallery-item {
    width: calc(100% - 20px);
  }
}
.theme-checkout-layout-1 .product-upsell-slideshow.gallery-layout-3 .gallery-item {
  width: calc(25% - 20px);
}
@media only screen and (max-width: 1300px) {
  .theme-checkout-layout-1 .product-upsell-slideshow.gallery-layout-3 .gallery-item {
    width: calc(33.3333333333% - 20px);
  }
}
@media only screen and (max-width: 1000px) {
  .theme-checkout-layout-1 .product-upsell-slideshow.gallery-layout-3 .gallery-item {
    width: calc(50% - 20px);
  }
}
.theme-checkout-layout-1 .product-upsell-slideshow.gallery-layout-4 .gallery-item {
  width: calc(20% - 20px);
}
@media only screen and (max-width: 1300px) {
  .theme-checkout-layout-1 .product-upsell-slideshow.gallery-layout-4 .gallery-item {
    width: calc(25% - 20px);
  }
}
@media only screen and (max-width: 1000px) {
  .theme-checkout-layout-1 .product-upsell-slideshow.gallery-layout-4 .gallery-item {
    width: calc(50% - 20px);
  }
}

.theme-checkout-layout-2 .product-upsell-slideshow.gallery-layout-1 .gallery-item {
  width: calc(50% - 20px);
}
@media only screen and (max-width: 1200px) {
  .theme-checkout-layout-2 .product-upsell-slideshow.gallery-layout-1 .gallery-item {
    width: calc(100% - 20px);
  }
}
.theme-checkout-layout-2 .product-upsell-slideshow.gallery-layout-3 .gallery-item {
  width: calc(33.3333333333% - 20px);
}
@media only screen and (max-width: 1300px) {
  .theme-checkout-layout-2 .product-upsell-slideshow.gallery-layout-3 .gallery-item {
    width: calc(50% - 20px);
  }
}
@media only screen and (max-width: 900px) {
  .theme-checkout-layout-2 .product-upsell-slideshow.gallery-layout-3 .gallery-item {
    width: calc(100% - 20px);
  }
}
.theme-checkout-layout-2 .product-upsell-slideshow.gallery-layout-4 .gallery-item {
  width: calc(25% - 20px);
}
@media only screen and (max-width: 1300px) {
  .theme-checkout-layout-2 .product-upsell-slideshow.gallery-layout-4 .gallery-item {
    width: calc(33.3333333333% - 20px);
  }
}
@media only screen and (max-width: 900px) {
  .theme-checkout-layout-2 .product-upsell-slideshow.gallery-layout-4 .gallery-item {
    width: calc(100% - 20px);
  }
}

/* - - - - - - - - - - - - - - - - - - - - - - - - - - -
 * PRUDUCT LIST FILTER
 * - - - - - - - - - - - - - - - - - - - - - - - - - - -
 * */
@media only screen and (max-width: 800px) {
  .filter-groups,
  .filter-header,
  .has-product-filter .product-list {
    float: none;
    width: auto;
    margin-left: 0;
  }
}
.filter-groups,
.filter-items,
.sort-order-items {
  margin: 0;
  padding: 0;
  list-style: none;
}

.active-filters-match {
  margin: 0;
}

.product-list {
  position: relative;
}

.product-list-loader {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.8);
}
.product-list-loader span {
  display: block;
  top: 150px;
  left: 50%;
  margin-left: -50px;
  font-size: 10px;
  position: absolute;
  text-indent: -9999em;
  border-top: 1.1em solid rgba(0, 0, 0, 0.2);
  border-right: 1.1em solid rgba(0, 0, 0, 0.2);
  border-bottom: 1.1em solid rgba(0, 0, 0, 0.2);
  border-left: 1.1em solid rgba(0, 0, 0, 0.8);
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation: load8 1s infinite linear;
  animation: load8 1s infinite linear;
  border-radius: 50%;
  width: 100px;
  height: 100px;
}
.product-list-loader span:after {
  border-radius: 50%;
  width: 100px;
  height: 100px;
}

.desktop .show-product-filter-loader .product-list-loading .product-list-loader {
  display: block;
}

@-webkit-keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
.filter-menu {
  margin-top: 10px;
  margin-left: -2%;
  display: -webkit-flex;
  display: flex;
}

.filter-menu-item {
  -webkit-flex: 1;
          flex: 1;
  margin-left: 2%;
  line-height: 11px;
  padding-top: 8px;
  padding-bottom: 8px;
}

.filter-menu-item:before {
  padding-right: 4px;
}

.toggle-active-filters:before {
  content: "\e904";
}

.toggle-sort-order:before {
  content: "\e600";
}

@media only screen and (min-width: 800px) {
  .filter-menu {
    display: none;
  }
  .product-list-block-with-filter-menu .filter-menu {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
  }
  .product-list-block-with-filter-menu .filter-menu-item {
    -webkit-flex: none;
            flex: none;
  }
  .product-list-block-with-filter-menu .has-active-filter-items .active-filters {
    margin-top: 15px;
  }
}
@media only screen and (max-width: 800px) {
  .filter-menu-item {
    padding-top: 17px;
    padding-bottom: 16px;
  }
}
.has-active-filter-items .active-filters {
  margin-left: -5px;
}
@media only screen and (max-width: 800px) {
  .has-active-filter-items .active-filters {
    margin-top: 15px;
  }
}

.remove-active-filter-action,
.clear-active-filters-action {
  float: left;
  margin-left: 5px;
  margin-bottom: 5px;
  width: auto;
  padding: 4px 14px;
  display: block;
  font-size: 90%;
  font-size: 0.9rem;
}

.has-active-filter-items #active-filters-count:before {
  content: "(";
}
.has-active-filter-items #active-filters-count:after {
  content: ")";
}

.remove-active-filter-action {
  color: white;
  background-color: black;
  text-transform: none;
  line-height: 24px;
  font-size: 80%;
  font-size: 0.8rem;
  position: relative;
  padding-right: 30px;
}
.remove-active-filter-action:after {
  content: "\e61d";
  line-height: 15px;
  font-size: 14px;
  position: absolute;
  margin-top: -7px;
  top: 50%;
  right: 10px;
}
@media only screen and (max-width: 800px) {
  .remove-active-filter-action {
    padding-top: 8px;
    padding-bottom: 8px;
  }
}

.clear-active-filters-action {
  padding: 3px 5px;
  display: none;
}
@media only screen and (max-width: 800px) {
  .clear-active-filters-action {
    padding-top: 7px;
    padding-bottom: 7px;
  }
}

.has-multiple-filter-items .clear-active-filters-action {
  display: block;
}

.filter-groups {
  margin-left: -1%;
  margin-top: 10px;
}

.filter-group {
  transition: padding 0.15s ease;
  margin-bottom: 2px;
  float: left;
  width: 19%;
  margin-left: 1%;
  margin-bottom: 1%;
  position: relative;
  padding-bottom: 0;
  box-shadow: 0 3px 5px rgba(0, 0, 0, 0.3);
}

.filter-group-closed {
  box-shadow: none;
  padding-bottom: 0;
}
.filter-group-closed .filter-group-title {
  margin: 0;
  background-color: rgba(0, 0, 0, 0.05);
}
.filter-group-closed .filter-group-title:after {
  content: "\e900";
}
.filter-group-closed .filter-items {
  max-height: 0;
  overflow: hidden;
}
.filter-group-closed .filter-item {
  padding-left: 0;
}
.filter-group-closed .filter-group-content {
  padding-top: 0;
  padding-bottom: 0;
}

.filter-group-count {
  display: none;
}

.filter-group-count-0 .filter-group-title,
.filter-group-count-0 .filter-item label {
  opacity: 0.5;
  cursor: default;
}
.filter-group-count-0 .filter-group-count {
  display: none;
}

.filter-group-title {
  padding: 10px 50px 10px 15px;
  margin: 0;
  position: relative;
  display: block;
  cursor: pointer;
  text-transform: none;
  background-color: #fff;
  font-weight: 400;
  font-size: 90%;
  font-size: 0.9rem;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.filter-group-title:hover {
  text-decoration: none;
}
.filter-group-title:after {
  content: "\e901";
  position: absolute;
  font-size: 20px;
  top: 50%;
  right: 10px;
  margin-top: -11px;
}
@media only screen and (max-width: 800px) {
  .filter-group-title {
    padding-top: 15px;
    padding-bottom: 15px;
  }
}

.filter-group-open .filter-group-title {
  color: #fff;
  background-color: #000;
}

.filter-group-content {
  z-index: 99;
  position: absolute;
  top: 40px;
  left: 0;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.filter-group-content,
.sort-order-content {
  background-color: #fff;
  padding: 15px;
  width: 100%;
}

.sort-order-content {
  display: none;
  margin-top: 1%;
}

@media only screen and (max-width: 800px) {
  .filter-groups,
  .active-filters,
  .has-product-filter .gallery-header {
    display: none;
  }
  .filter-group-storlek {
    display: block;
  }
  .filter-groups {
    margin-top: 2px;
  }
  .filter-menu-show-filters .filter-groups,
  .filter-menu-show-filters .active-filters {
    display: block;
  }
  .filter-menu-show-sort-order .sort-order-content {
    display: block;
  }
}
@media only screen and (min-width: 800px) {
  .product-list-block-with-filter-menu .filter-groups,
  .product-list-block-with-filter-menu .active-filters,
  .product-list-block-with-filter-menu .has-product-filter .gallery-header {
    display: none;
  }
  .product-list-block-with-filter-menu.filter-menu-show-filters .filter-groups,
  .product-list-block-with-filter-menu.filter-menu-show-filters .active-filters {
    display: block;
  }
  .product-list-block-with-filter-menu.filter-menu-show-sort-order .sort-order-content {
    display: block;
  }
}
.filter-items {
  transition: max-height 0.1s ease;
  max-height: 5000px;
}

.filter-items,
.sort-order-items,
.filter-items label,
.sort-order-items label {
  font-size: 80%;
  font-size: 0.8rem;
}

.filter-item-type-checkbox {
  position: relative;
  padding-left: 20px;
  margin-bottom: 6px;
}
.filter-item-type-checkbox label {
  display: block;
  line-height: 1.4em;
}

.filter-type-checkbox {
  top: 1px;
  left: 0;
  position: absolute;
}

.see-more-filter-items,
.see-less-filter-items {
  display: none;
  white-space: nowrap;
}

@media only screen and (max-width: 800px) {
  .filter-groups {
    margin-left: 0;
  }
  .filter-group-content {
    position: static;
    top: auto;
    left: auto;
    box-shadow: none;
  }
  .filter-group {
    float: none;
    width: 100%;
    margin-left: 0;
    box-shadow: none;
  }
}
@media only screen and (max-width: 800px) {
  .irs-slider {
    background-image: none;
    width: 31px;
    height: 31px;
    box-shadow: 0 3px 0px 1px rgba(0, 0, 0, 0.05), 0 0 0 1px #DEDBDB inset;
    border-radius: 50%;
    background: linear-gradient(to bottom, #F9F9F9 0%, #E4E4E4 60%);
  }
  .irs-slider:hover, .irs-slider.irs-slider.state_hover {
    background-position: 0 0;
  }
  .irs-slider:after {
    content: "";
    position: absolute;
    width: 13px;
    height: 13px;
    margin-left: -7px;
    top: 9px;
    left: 50%;
    border-radius: 50%;
    background: linear-gradient(to bottom, #D8D8D8 21%, #FFFEFE 100%);
    box-shadow: 0 0 0 1px rgba(64, 63, 63, 0.1) inset;
  }
}
.product-list-block-with-filters-aside .product-list,
.product-list-block-with-filters-aside .filter-header {
  float: right;
}
.product-list-block-with-filters-aside .filter-groups {
  margin-top: 0;
  width: 20%;
}
@media only screen and (max-width: 800px) {
  .product-list-block-with-filters-aside .filter-groups {
    width: 100%;
  }
}
.product-list-block-with-filters-aside.has-product-filter .filter-header, .product-list-block-with-filters-aside.has-product-filter .product-list {
  width: 78%;
  margin-left: 2%;
}
@media only screen and (max-width: 800px) {
  .product-list-block-with-filters-aside.has-product-filter .filter-header, .product-list-block-with-filters-aside.has-product-filter .product-list {
    width: 100%;
    margin-left: 0;
  }
}
.product-list-block-with-filters-aside .filter-groups,
.product-list-block-with-filters-aside .has-product-filter .product-list {
  float: left;
}
.product-list-block-with-filters-aside .filter-group-title {
  padding: 10px 15px;
}
.product-list-block-with-filters-aside .filter-group {
  margin-bottom: 0;
  float: none;
  width: auto;
  margin-left: 0;
  margin-bottom: 0;
  position: relative;
  padding-bottom: 10px;
  box-shadow: none;
}
@media only screen and (max-width: 800px) {
  .product-list-block-with-filters-aside .filter-group {
    width: 100%;
  }
}
.product-list-block-with-filters-aside .filter-group-content {
  position: static;
  top: auto;
  left: auto;
  box-shadow: none;
}
.product-list-block-with-filters-aside .has-active-filter-items .active-filters {
  margin-bottom: 10px;
}

.product-filter .sort-order-items {
  margin-left: -1%;
}
.product-filter .filter-item,
.product-filter .sort-order-item {
  display: inline-block;
  margin-left: 1%;
  margin-bottom: 1%;
  position: relative;
  padding-left: 0;
}
.product-filter .see-more-filter-items,
.product-filter .see-less-filter-items {
  margin-left: 2%;
}
.product-filter label {
  display: inline-block;
  position: relative;
  top: 0;
  left: 0;
  padding: 2px 6px;
  background-color: white;
  box-shadow: 0 0 0 1px #CECECE;
  text-align: left;
  margin-bottom: 0.3em;
  max-width: 100%;
  word-break: break-word;
  cursor: pointer;
}
.product-filter .filter-item-type-range {
  display: block;
  margin: 0;
}
.product-filter .filter-item-type-range label {
  display: none;
}
.product-filter input:checked + label {
  background-color: #000;
  color: white;
}
.product-filter .filter-item-count {
  opacity: 0.5;
  padding-left: 3px;
}
.product-filter .filter-item-count:before {
  content: "[";
}
.product-filter .filter-item-count:after {
  content: "]";
}
.product-filter .filter-item-count-0 label {
  opacity: 0.5;
  cursor: default;
}
.product-filter input {
  display: none;
}
@media only screen and (max-width: 800px) {
  .product-filter label {
    padding: 13px;
  }
}

/* - - - - - - - - - - - - - - - - - - - - - - - - - - -
 * SLIDESHOW
 * - - - - - - - - - - - - - - - - - - - - - - - - - - -
 * */
.slideshow-media {
  margin-bottom: 40px;
}
.slideshow-media .slide {
  width: 100%;
}
.slideshow-media .figure {
  position: relative;
  text-align: center;
}
.slideshow-media .figure a {
  display: block;
}
.slideshow-media .caption {
  position: absolute;
  left: 0;
  width: 100%;
  color: white;
  text-align: center;
  top: 50%;
  font-size: 170%;
  margin-top: -50px;
  font-size: 4vw;
}
.slideshow-media .caption h3 {
  font-size: 1em;
  line-height: 1.1em;
  text-transform: uppercase;
  margin-top: 0;
  font-weight: 600;
}
.slideshow-media .caption p {
  font-size: 0.8em;
  margin-top: 0em;
  line-height: 1.2em;
}
@media only screen and (min-width: 700px) {
  .slideshow-media .caption {
    font-size: 6vw;
  }
  .slideshow-media .caption p {
    font-size: 0.3em;
  }
}
.slideshow-media .caption-empty {
  display: none;
}

.theme-layout-boxed .slideshow-media .caption {
  font-size: 3vw;
}

.touch .flickity-prev-next-button {
  display: none;
}

/* - - - - - - - - - - - - - - - - - - - - - - - - - - -
 * FULLWIDTH / SLIDESHOW
 * - - - - - - - - - - - - - - - - - - - - - - - - - - -
 * */
.slideshow-block {
  opacity: 0;
  transition: opacity 0.4s;
}

.flickity-loaded {
  opacity: 1;
}

.flickity-slider {
  -webkit-transform: translatez(0);
  transform: translatez(0);
}

.desktop .flickity-prev-next-button:hover {
  background-color: #fff;
  color: #333;
}

@media only screen and (max-width: 700px) {
  .slideshow-media .caption {
    position: static;
    top: auto;
    left: auto;
    margin-top: 0;
    color: rgb(55, 55, 55);
    padding-left: 12%;
    padding-right: 12%;
  }
  .slideshow-media .caption-empty {
    display: none;
  }
}
/* - - - - - - - - - - - - - - - - - - - - - - - - - - -
 * NAVIGATION
 * - - - - - - - - - - - - - - - - - - - - - - - - - - -
 * */
.menu a {
  color: rgb(55, 55, 55);
}

.site-header .menu-block-with-mega-dropdown-menu .menu-title {
  display: block;
}
.site-header .menu-block-with-mega-dropdown-menu .menu-level-1 {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-justify-content: center;
          justify-content: center;
}
.site-header .menu-block-with-mega-dropdown-menu .menu-level-1-title {
  text-transform: uppercase;
  padding: 0.5rem 0.5rem;
}
.site-header .menu-block-with-mega-dropdown-menu li.no-children:nth-child(1) .menu-level-1-title {
  padding-left: 0;
}
.site-header .menu-block-with-mega-dropdown-menu .menu-level-2 {
  visibility: hidden;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  padding: 30px;
  left: -30px;
  right: -30px;
  position: absolute;
  z-index: 9999;
  overflow: hidden;
  background-color: #fff;
}
.site-header .menu-block-with-mega-dropdown-menu .menu-level-2-item {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  padding-right: 30px;
  padding-bottom: 30px;
  width: 180px;
}
.site-header .menu-block-with-mega-dropdown-menu .menu-level-2-title {
  text-transform: uppercase;
  font-weight: 600;
}
.site-header .menu-block-with-mega-dropdown-menu .menu-level-3 {
  display: block;
}

.desktop .site-header .menu-block-with-mega-dropdown-menu .menu-title:hover {
  text-decoration: none;
}

.site-header .menu-block-with-flyout-menu .menu a {
  display: block;
  color: rgb(55, 55, 55);
}
.site-header .menu-block-with-flyout-menu .menu-level-1 {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-justify-content: center;
          justify-content: center;
  position: relative;
}
.site-header .menu-block-with-flyout-menu .menu-level-1-title {
  text-transform: uppercase;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 8px;
  padding-bottom: 8px;
  color: rgb(55, 55, 55);
}
.site-header .menu-block-with-flyout-menu .menu-level-1-title.menu-title-has-children {
  position: relative;
}
.site-header .menu-block-with-flyout-menu .menu-level-1-title.menu-title-has-children:after {
  content: "\e900";
}
.site-header .menu-block-with-flyout-menu .menu-level-1-title.menu-title-has-children {
  padding-right: 28px;
}
.site-header .menu-block-with-flyout-menu .menu-level-1-title.menu-title-has-children:after {
  position: absolute;
  top: 10px;
  right: 10px;
  padding-left: 3px;
  font-size: 20px;
}
.site-header .menu-block-with-flyout-menu .menu-level-2-title:after,
.site-header .menu-block-with-flyout-menu .menu-level-3-title:after,
.site-header .menu-block-with-flyout-menu .menu-level-4-title:after, .site-header .menu-block-with-flyout-menu.menu-title-has-children:after {
  position: absolute;
  content: "\f105";
  top: 5px;
  right: 10px;
  font-size: 20px;
}
.site-header .menu-block-with-flyout-menu .menu-level-2,
.site-header .menu-block-with-flyout-menu .menu-level-3,
.site-header .menu-block-with-flyout-menu .menu-level-4 {
  position: absolute;
  visibility: hidden;
  opacity: 0;
  transition: opacity 0.2s;
  top: 0;
  width: 200px;
  z-index: 9997;
}
.site-header .menu-block-with-flyout-menu .menu-level-2 {
  top: auto;
}
.site-header .menu-block-with-flyout-menu .menu-level-2-item,
.site-header .menu-block-with-flyout-menu .menu-level-3-item,
.site-header .menu-block-with-flyout-menu .menu-level-4-item {
  position: relative;
  padding-right: 5px;
}
.site-header .menu-block-with-flyout-menu .menu-level-3,
.site-header .menu-block-with-flyout-menu .menu-level-4 {
  right: -199px;
}
.site-header .menu-block-with-flyout-menu .menu-level-2-title,
.site-header .menu-block-with-flyout-menu .menu-level-3-title,
.site-header .menu-block-with-flyout-menu .menu-level-4-title {
  background-color: #fff;
  padding: 4px 20px 4px 10px;
  font-weight: 400;
}
.site-header .menu-block-with-flyout-menu.flyout-menu-left .menu-level-2 {
  right: 0;
}
.site-header .menu-block-with-flyout-menu.flyout-menu-left .menu-level-2-item,
.site-header .menu-block-with-flyout-menu.flyout-menu-left .menu-level-3-item,
.site-header .menu-block-with-flyout-menu.flyout-menu-left .menu-level-4-item {
  padding-right: 0;
  padding-left: 5px;
}
.site-header .menu-block-with-flyout-menu.flyout-menu-left .menu-level-3,
.site-header .menu-block-with-flyout-menu.flyout-menu-left .menu-level-4 {
  right: auto;
  left: -199px;
}
.site-header .menu-block-with-flyout-menu.flyout-menu-left .menu-level-2-title:after,
.site-header .menu-block-with-flyout-menu.flyout-menu-left .menu-level-3-title:after,
.site-header .menu-block-with-flyout-menu.flyout-menu-left .menu-level-4-title:after, .site-header .menu-block-with-flyout-menu.flyout-menu-left.menu-title-has-children:after {
  display: none;
}

.menu-block-with-dropline-menu .menu a {
  display: block;
  color: rgb(55, 55, 55);
}
.menu-block-with-dropline-menu .header-item .menu-level-1 {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-justify-content: center;
          justify-content: center;
}
.menu-block-with-dropline-menu .header-item .menu-title {
  text-transform: uppercase;
  padding: 8px 10px;
}
.menu-block-with-dropline-menu .header-item .menu-level-1-title.menu-title-has-children {
  padding-right: 28px;
  position: relative;
}
.menu-block-with-dropline-menu .header-item .menu-level-1-title.menu-title-has-children:after {
  content: "\e900";
  position: absolute;
  top: 10px;
  right: 10px;
  padding-left: 3px;
  font-size: 20px;
}
.menu-block-with-dropline-menu .header-item .menu-item-has-children {
  position: relative;
}
.menu-block-with-dropline-menu .header-item .menu-level-2 {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  position: absolute;
  visibility: hidden;
  opacity: 0;
  transition: opacity 0.2s;
  width: 200px;
  z-index: 9997;
  background-color: #fff;
}
.menu-block-with-dropline-menu .header-item .menu-level-3,
.menu-block-with-dropline-menu .header-item .menu-level-4 {
  margin-left: 1em;
}

.desktop .header-item .menu-item:hover .menu-level-1-title:only-child:hover {
  background-color: transparent;
}
.desktop .header-item .menu-item:hover > .menu {
  visibility: visible;
  opacity: 1;
}

@media only screen and (max-width: 980px) {
  .header-item .menu-block {
    display: none;
  }
}
.mobile .header-item .menu-block {
  display: none;
}

#content-sidebar .menu-title,
.site-footer .menu-title {
  position: relative;
  padding-left: 10px;
  display: block;
}
#content-sidebar .menu-title:before,
.site-footer .menu-title:before {
  padding-right: 0.2em;
  position: absolute;
  top: 5px;
  left: 0;
}
#content-sidebar .menu-level-1-title,
.site-footer .menu-level-1-title {
  text-transform: uppercase;
  font-size: 0.95rem;
}
#content-sidebar .menu-level-2-title,
.site-footer .menu-level-2-title {
  font-size: 0.9rem;
}
#content-sidebar .menu-level-2-title:before,
.site-footer .menu-level-2-title:before {
  content: "·";
}
#content-sidebar .menu-level-2,
.site-footer .menu-level-2 {
  margin-left: 1em;
}
#content-sidebar .menu-level-3-title,
.site-footer .menu-level-3-title {
  font-size: 0.85rem;
}
#content-sidebar .menu-level-3-title:before,
.site-footer .menu-level-3-title:before {
  content: "-";
  top: 3px;
}
#content-sidebar .menu-level-3,
.site-footer .menu-level-3 {
  margin-left: 1em;
}
#content-sidebar .menu-level-4-title,
.site-footer .menu-level-4-title {
  font-size: 0.8rem;
}
#content-sidebar .menu-level-4,
.site-footer .menu-level-4 {
  margin-left: 1em;
}
#content-sidebar .menu-title.load-tree:before,
.site-footer .menu-title.load-tree:before {
  content: "+";
  top: 2px;
}
#content-sidebar .menu-title.load-tree.open:before,
.site-footer .menu-title.load-tree.open:before {
  content: "-";
}

.desktop #content-sidebar .menu-title.load-tree:hover {
  cursor: pointer;
}

.sidebar .category-menu-block .menu-item-has-children > .menu {
  display: none;
}
.sidebar .category-menu-block .menu-item-has-children.menu-item-show-children > .menu {
  display: block;
}

.site-footer .menu-level-1 {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  -webkit-flex-direction: row;
          flex-direction: row;
}
.site-footer .menu-level-1 ul {
  margin-left: 1em;
}
@media only screen and (max-width: 500px) {
  .site-footer .menu-level-1 {
    -webkit-flex-direction: column;
            flex-direction: column;
  }
  .site-footer .menu-level-1 ul {
    margin-left: 0;
  }
  .site-footer .menu-level-1 .menu-level-2 ul {
    margin-left: 1em;
  }
}
.site-footer .menu-item {
  margin: 0.1em 0 0.5em;
}
.site-footer .menu-level-2-title:before {
  top: 1px;
}
.site-footer .menu-level-3-title:before {
  top: 0px;
}
.site-footer .menu-block h2 {
  margin-top: 0;
}

.footer-region-10 .menu-block h2 {
  text-align: center;
}
.footer-region-10 .menu-level-1 {
  -webkit-justify-content: center;
          justify-content: center;
}

.site-content .custom-menu-block .toggle-child-menu,
.site-footer .custom-menu-block .toggle-child-menu {
  position: absolute;
  cursor: pointer;
  width: 35px;
  height: 20px;
  text-align: center;
  font-size: 20px;
  top: 1px;
  right: 0;
  display: none;
  transition: -webkit-transform 0.3s;
  transition: transform 0.3s;
  transition: transform 0.3s, -webkit-transform 0.3s;
}
.site-content .custom-menu-block .toggle-child-menu:before,
.site-footer .custom-menu-block .toggle-child-menu:before {
  content: "\e900";
  line-height: 20px;
}
.site-content .custom-menu-block .menu-item-has-loading-children .toggle-child-menu:before,
.site-footer .custom-menu-block .menu-item-has-loading-children .toggle-child-menu:before {
  content: "";
  border-radius: 50%;
  border: 1px solid #000;
  border-top: 1px solid transparent;
  width: 13px;
  height: 13px;
  top: 5px;
  right: 10px;
  -webkit-animation: spin 1s linear infinite;
          animation: spin 1s linear infinite;
  display: block;
  position: absolute;
}
.site-content .custom-menu-block .menu-title-has-children,
.site-footer .custom-menu-block .menu-title-has-children {
  padding-right: 40px;
}
.site-content .custom-menu-block .menu-item-has-children,
.site-footer .custom-menu-block .menu-item-has-children {
  position: relative;
}
.site-content .custom-menu-block .menu-item-has-children > .menu,
.site-footer .custom-menu-block .menu-item-has-children > .menu {
  display: none;
}
.site-content .custom-menu-block .menu-item-has-children.menu-item-show-children > .menu,
.site-footer .custom-menu-block .menu-item-has-children.menu-item-show-children > .menu {
  display: block;
}
.site-content .custom-menu-block .menu-item-show-children > .toggle-child-menu,
.site-footer .custom-menu-block .menu-item-show-children > .toggle-child-menu {
  -webkit-transform: rotateX(180deg);
          transform: rotateX(180deg);
  -webkit-transform-origin: 50% 50%;
          transform-origin: 50% 50%;
}
.site-content .custom-menu-block .toggle-child-menu,
.site-content .category-tree-menu .toggle-child-menu,
.site-footer .custom-menu-block .toggle-child-menu,
.site-footer .category-tree-menu .toggle-child-menu {
  display: block;
}

/* - - - - - - - - - - - - - - - - - - - - - - - - - - -
 * REGION ADJUSTMENTS
 * - - - - - - - - - - - - - - - - - - - - - - - - - - -
 * */
.header-region-1 .menu-block-with-mega-dropdown-menu .menu-level-1,
.header-region-1 .menu-block-with-flyout-menu .menu-level-1 {
  -webkit-justify-content: flex-end;
          justify-content: flex-end;
}

.header-region-3 .menu-block-with-mega-dropdown-menu .menu-level-1,
.header-region-3 .menu-block-with-flyout-menu .menu-level-1 {
  -webkit-justify-content: flex-start;
          justify-content: flex-start;
}

/* - - - - - - - - - - - - - - - - - - - - - - - - - - -
 * OFF CANVAS NAVIGATION
 * - - - - - - - - - - - - - - - - - - - - - - - - - - -
 * */
html, body {
  height: 100%;
}

body {
  position: relative;
  transition: -webkit-transform 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
  transition: transform 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
  transition: transform 0.3s cubic-bezier(0.215, 0.61, 0.355, 1), -webkit-transform 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
}

.toggle-child-menu {
  display: none;
}

#page-container {
  -webkit-overflow-scrolling: touch;
}

#mobile-menu {
  display: none;
  width: 35px;
  height: 35px;
  position: absolute;
  left: 0;
  top: 5px;
  cursor: pointer;
  padding-top: 15px;
}
#mobile-menu span,
#mobile-menu span:before,
#mobile-menu span:after {
  cursor: pointer;
  border-radius: 1px;
  height: 5px;
  width: 35px;
  background: #000;
  position: absolute;
  display: block;
  content: "";
  transition: all 500ms ease-in-out;
}
#mobile-menu span:before {
  top: -10px;
}
#mobile-menu span:after {
  bottom: -10px;
}
@media only screen and (max-width: 980px) {
  #mobile-menu {
    display: block;
  }
}

.mobile #mobile-menu {
  display: block;
}

.has-open-fullwidth-search #mobile-menu {
  top: 50px;
}

.off-canvas-nav {
  background-color: #f0f1f1;
  position: fixed;
  -webkit-overflow-scrolling: touch;
  top: 0;
  left: 0;
  height: 100%;
  width: 240px;
  overflow-y: auto;
  padding-top: 40px;
  padding-bottom: 40px;
  font-size: 1.3rem;
}
.off-canvas-nav .menu {
  display: block;
}
.off-canvas-nav .menu-title {
  display: block;
  padding: 0.4em 0;
  line-height: 1.2em;
}
.off-canvas-nav .menu-level-1-title {
  padding-left: 10px;
}
.off-canvas-nav .menu-level-2-title {
  font-size: 0.85em;
  padding-left: 20px;
  font-weight: 600;
}
.off-canvas-nav .menu-level-3-title {
  font-size: 0.85em;
  padding-left: 30px;
}
.off-canvas-nav .menu-level-4-title {
  font-size: 0.8em;
  padding-left: 35px;
}
.off-canvas-nav .menu-title-has-children {
  padding-right: 50px;
}
.off-canvas-nav .toggle-child-menu {
  position: absolute;
  cursor: pointer;
  width: 50px;
  height: 40px;
  text-align: center;
  line-height: 44px;
  font-size: 28px;
  top: 0;
  right: 0;
  display: none;
}
.off-canvas-nav .toggle-child-menu:before {
  content: "\e900";
}
.off-canvas-nav .menu-item-has-loading-children .toggle-child-menu:before {
  content: "";
  border-radius: 50%;
  border: 2px solid #000;
  border-top: 2px solid transparent;
  width: 16px;
  height: 16px;
  top: 12px;
  right: 16px;
  -webkit-animation: spin 1s linear infinite;
          animation: spin 1s linear infinite;
  display: block;
  position: absolute;
}
.off-canvas-nav .menu-item-has-children {
  position: relative;
}
.off-canvas-nav .menu-item-has-children > .menu {
  display: none;
}
.off-canvas-nav .menu-item-has-children.menu-item-show-children > .menu {
  display: block;
}
.off-canvas-nav .menu-item-show-children > .toggle-child-menu:before {
  content: "\e901";
}
.off-canvas-nav .menu-level-2:last-of-type {
  margin-bottom: 15px;
}
.off-canvas-nav .menu-level-4-item {
  padding-left: 10px;
  position: relative;
}
.off-canvas-nav .menu-level-4-item:before {
  content: "·";
  display: block;
  position: absolute;
  top: 3px;
  left: 36px;
}
.off-canvas-nav .custom-menu-block .toggle-child-menu,
.off-canvas-nav .category-tree-menu .toggle-child-menu {
  display: block;
}

.off-canvas-nav .category-menu-block:not(.category-tree-menu) .menu-item-has-children.open .menu {
  display: block;
}

.has-visible-off-canvas-nav #mobile-menu span {
  background-color: transparent;
}
.has-visible-off-canvas-nav #mobile-menu span:before,
.has-visible-off-canvas-nav #mobile-menu span:after {
  top: 0;
}
.has-visible-off-canvas-nav #mobile-menu span:before {
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
}
.has-visible-off-canvas-nav #mobile-menu span:after {
  -webkit-transform: rotate(-45deg);
          transform: rotate(-45deg);
}
.has-visible-off-canvas-nav #page-container {
  width: 100%;
  position: fixed;
  overflow: hidden;
}

@media only screen and (max-width: 500px) {
  .in-mobile-region,
  .in-mobile-region.search-block-with-expand {
    display: none;
  }
}
.mobile-menu-with-left-push .off-canvas-nav {
  display: none;
  z-index: 200;
}
.mobile-menu-with-left-push.has-visible-off-canvas-nav #page-container {
  -webkit-transform: translate3d(240px, 0, 0);
          transform: translate3d(240px, 0, 0);
}
.mobile-menu-with-left-push.has-visible-off-canvas-nav #page-container:after {
  right: 0;
  opacity: 1;
  background-color: transparent;
}
.mobile-menu-with-left-push.has-visible-off-canvas-nav .off-canvas-nav {
  display: block;
}

.mobile-menu-with-left-slide #page-container {
  transition: -webkit-transform 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
  transition: transform 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
  transition: transform 0.3s cubic-bezier(0.215, 0.61, 0.355, 1), -webkit-transform 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
}
.mobile-menu-with-left-slide .off-canvas-nav {
  padding-top: 50px;
  z-index: 999999;
  background-color: #fff;
  -webkit-transform: translate3d(-100%, 0, 0);
          transform: translate3d(-100%, 0, 0);
  transition: -webkit-transform 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
  transition: transform 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
  transition: transform 0.3s cubic-bezier(0.215, 0.61, 0.355, 1), -webkit-transform 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
}
.mobile-menu-with-left-slide .off-canvas-close {
  position: absolute;
  top: 10px;
  right: 10px;
  width: 40px;
  height: 40px;
  cursor: pointer;
  transition: opacity 0.5s;
}
.mobile-menu-with-left-slide .off-canvas-close:before, .mobile-menu-with-left-slide .off-canvas-close:after {
  cursor: pointer;
  height: 2px;
  width: 38px;
  background: #000;
  position: absolute;
  display: block;
  content: "";
  top: 17px;
  left: 2px;
}
.mobile-menu-with-left-slide .off-canvas-close:before {
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
}
.mobile-menu-with-left-slide .off-canvas-close:after {
  -webkit-transform: rotate(-45deg);
          transform: rotate(-45deg);
}
.mobile-menu-with-left-slide.has-visible-off-canvas-nav .off-canvas-nav {
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.5);
  -webkit-transform: translate3d(0, 0, 0);
          transform: translate3d(0, 0, 0);
}
.mobile-menu-with-left-slide.has-visible-off-canvas-nav #page-container:after {
  right: 0;
  opacity: 1;
}

/* - - - - - - - - - - - - - - - - - - - - - - - - - - -
 * CART
 * - - - - - - - - - - - - - - - - - - - - - - - - - - -
 * */
.header-item-2 {
  position: relative;
}

.cart-icon {
  -webkit-order: -1;
          order: -1;
}
.cart-icon:before {
  content: "\e608";
  font-size: 30px;
}

.empty-cart .cart-item-count {
  display: none;
}

.cart-item .item-weight:before,
.cart-item .sku:before {
  content: attr(data-title);
  padding-right: 4px;
}
.cart-item .item-weight:first-child:before,
.cart-item .sku:first-child:before {
  content: attr(data-title);
}

.bundled-product-items {
  font-size: 0.8rem;
  line-height: 1.4em;
  margin-top: 0.4em;
  margin-bottom: 0.6em;
  margin-left: 1.4em;
}

.bundled-product-item {
  position: relative;
  line-height: 1.1em;
  margin-bottom: 0.3em;
}
.bundled-product-item:before {
  content: "-";
  position: absolute;
  top: 0px;
  left: -10px;
}
.bundled-product-item .quantity .unit {
  text-transform: uppercase;
  font-size: 0.6em;
}
.bundled-product-item .variant:before {
  content: ", " attr(data-title);
}
.bundled-product-item .sku {
  opacity: 0.4;
}
.bundled-product-item .sku:before {
  content: " " attr(data-title);
  padding-right: 0.2em;
}

.cart-header,
.cart-item a {
  color: rgb(55, 55, 55);
}

.cart-header {
  display: block;
  padding-left: 0.5rem;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
}
.cart-header .price {
  margin-left: 0.3em;
  font-size: 24px;
  margin-top: 9px;
}
@media only screen and (max-width: 500px) {
  .cart-header .price {
    display: none;
  }
}
@media only screen and (max-width: 980px) {
  .cart-header {
    padding-top: 0;
  }
}

.cart-title {
  text-indent: -9999px;
}

.cart-sum-label {
  display: none;
}

.cart-item-count {
  margin-left: 0.3em;
}
.cart-item-count .number {
  background-color: #000;
  color: #fff;
  border-radius: 50%;
  display: block;
  width: 18px;
  height: 18px;
  text-align: center;
  line-height: 19px;
  font-size: 12px;
  transition: -webkit-transform 0.5s;
  transition: transform 0.5s;
  transition: transform 0.5s, -webkit-transform 0.5s;
}
.cart-item-count .unit {
  font-size: 0.5em;
  text-transform: uppercase;
  display: none;
}

.checkout-cart .cart-item .stock-status-locations {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  font-size: 0.9rem;
  opacity: 0.6;
  max-height: 130px;
  overflow: hidden;
  margin-bottom: 3px;
}
.checkout-cart .cart-item .stock-status-locations span {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  white-space: nowrap;
  line-height: 1.2em;
}
.checkout-cart .cart-item .stock-status-locations span .stock-name {
  -webkit-flex: 1;
          flex: 1;
}

.checkout-cart .cart-item .stock-status-wrap {
  width: 100%;
  padding-top: 5px;
}
.checkout-cart .cart-item .stock-status-wrap .expand-checkout-stock-locations-button {
  display: none;
  opacity: 0.8;
  font-weight: 400;
  color: #405AA7 !important;
}
.checkout-cart .cart-item .stock-status-wrap .expand-checkout-stock-locations-button .less {
  display: none;
}
.checkout-cart .cart-item .stock-status-wrap.expanded .stock-status-locations {
  max-height: none !important;
}
.checkout-cart .cart-item .stock-status-wrap.expanded .expand-checkout-stock-locations-button .less {
  display: block;
  opacity: 0.8;
}
.checkout-cart .cart-item .stock-status-wrap.expanded .expand-checkout-stock-locations-button .more {
  display: none;
}

.header-item {
  position: static;
}

@media only screen and (max-width: 500px) {
  .cart-content {
    right: -10px;
    left: -10px;
  }
}
.shopping-cart-item {
  position: relative;
  padding-bottom: 7px;
  margin-bottom: 10px;
  border-bottom: 1px solid #dedede;
}
.shopping-cart-item:first-child {
  margin-top: 1em;
}
.shopping-cart-item a {
  display: block;
}
.shopping-cart-item h3 {
  font-size: 1rem;
  padding-right: 30px;
}
.shopping-cart-item h4 {
  font-size: 0.7rem;
  margin-top: 0.5em;
  opacity: 0.4;
}
.shopping-cart-item .quantity .unit {
  padding-left: 0.1em;
}

.remove-item-action,
.remove-item {
  position: absolute;
  top: -10px;
  right: 20px;
  width: 40px;
  height: 40px;
  background-color: transparent;
  padding: 0;
  color: rgb(55, 55, 55);
}

.db-cart-message,
.empty-cart-message {
  display: block;
  font-size: 0.8rem;
  opacity: 0.8;
  text-align: right;
}

.checkout-block .db-cart-message {
  font-size: 1rem;
}

.shopping-cart-item,
.shopping-cart-subtotal,
.cart-footer,
.cart-content .db-cart-message,
.cart-content .empty-cart-message {
  padding-right: 20px;
  padding-left: 20px;
}

.cart-item-info {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
}

.shopping-cart {
  overflow-y: auto;
  border-top: 1px solid #dedede;
}
.shopping-cart h3, .shopping-cart h4 {
  margin-top: 0;
}

.empty-cart .shopping-cart-items {
  border-top-style: none;
}

.cart-footer,
.cart-item-info,
.cart-subtotal {
  margin: 0;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
}

.shopping-cart-subtotal {
  font-weight: 600;
  padding-bottom: 20px;
}
.shopping-cart-subtotal .caption {
  text-transform: uppercase;
}

.checkout-link {
  position: relative;
  padding-right: 32px;
}
.checkout-link:after {
  content: "\f105";
  font-size: 24px;
  position: absolute;
  top: 50%;
  right: 14px;
  margin-top: -0.5em;
}

.cart-content .price {
  margin-left: auto;
}

.cart-content {
  -webkit-flex-direction: column;
          flex-direction: column;
}
.cart-content .db-cart-message {
  -webkit-order: -2;
          order: -2;
}
.cart-content .empty-cart-message {
  -webkit-order: -3;
          order: -3;
}

.cart-footer {
  -webkit-order: -1;
          order: -1;
}

.remove-item-action,
.remove-item {
  font-size: 1.1rem;
}
.remove-item-action span,
.remove-item span {
  display: none;
}
.remove-item-action:after,
.remove-item:after {
  content: "\e61d";
}

.cart-footer,
.empty-cart-message {
  margin-top: 1em;
  margin-bottom: 1em;
}

.empty-cart-message + db-cart-message {
  margin-top: 0;
}

.empty-cart .shopping-cart-subtotal,
.empty-cart .checkout-link {
  display: none;
}

.desktop .cart-header:hover,
.desktop .cart-item a:hover {
  text-decoration: none;
}
.desktop .cart-item .remove-item-action:hover,
.desktop .cart-item .remove-item:hover,
.desktop .cart-content-close:hover {
  background-color: transparent;
  color: rgb(55, 55, 55);
}

.site-footer .cart-content {
  position: fixed;
  top: 0;
  right: 0;
  z-index: 999999;
  min-width: 300px;
  background-color: white;
  max-height: none;
  visibility: hidden;
  -webkit-transform: translateX(100%);
          transform: translateX(100%);
  transition-duration: 1s;
  transition-property: visibility, -webkit-transform;
  transition-property: transform, visibility;
  transition-property: transform, visibility, -webkit-transform;
  transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
}
.site-footer .show-cart-content .cart-content {
  -webkit-transform: translateX(0);
          transform: translateX(0);
  visibility: visible;
}

.site-header .cart-content {
  display: none !important;
}

#page-container:after {
  content: "";
  top: 0;
  left: 0;
  bottom: 0;
  position: fixed;
  background-color: rgba(92, 92, 92, 0.4);
  opacity: 0;
  z-index: 999999;
  transition: opacity 0.7s;
}

.off-canvas-cart {
  background-color: #fff;
  position: fixed;
  top: 0;
  right: 0;
  width: 350px;
  bottom: 0;
  height: 100%;
  overflow-y: auto;
  padding-top: 50px;
  padding-bottom: 40px;
  -webkit-transform: translate3d(100%, 0, 0);
          transform: translate3d(100%, 0, 0);
  -webkit-overflow-scrolling: touch;
}
.off-canvas-cart .checkout-link {
  width: 100%;
}
.off-canvas-cart .cart-header {
  display: none;
}
.off-canvas-cart .cart-content {
  display: -webkit-flex;
  display: flex;
}
.off-canvas-cart .empty-cart .cart-content {
  display: block;
  padding: 15px 10px 0px 65px;
  line-height: 1.2em;
}
.off-canvas-cart .empty-cart .cart-content .db-cart-message,
.off-canvas-cart .empty-cart .cart-content .empty-cart-message {
  text-align: left;
  padding: 0;
  margin: 0;
}
.off-canvas-cart .cart-content-close {
  position: absolute;
  display: block;
  top: 10px;
  left: 15px;
  text-indent: -9999px;
  background-color: transparent;
  color: rgb(55, 55, 55);
  width: 40px;
  height: 40px;
  padding: 0;
}
.off-canvas-cart .cart-content-close:after {
  content: "\e61d";
  text-indent: 0;
  display: block;
  font-size: 32px;
  margin-top: -0.3em;
}

.has-visible-off-canvas-cart body {
  -webkit-transform: translate3d(-350px, 0, 0);
          transform: translate3d(-350px, 0, 0);
  overflow-y: hidden;
}
.has-visible-off-canvas-cart #page-container {
  position: fixed;
  overflow: hidden;
  width: 100%;
  height: 100%;
}
.has-visible-off-canvas-cart #page-container:after {
  opacity: 1;
  right: 0;
  -webkit-transform: translate3d(0, 0, 0);
          transform: translate3d(0, 0, 0);
}
.has-visible-off-canvas-cart.has-fixed-site-header .admin-toolbar-phantom {
  display: none;
}

@media only screen and (max-width: 980px) {
  #page-container {
    overflow-y: auto;
  }
}
@media only screen and (max-width: 500px) {
  .off-canvas-cart {
    width: 280px;
  }
  .has-visible-off-canvas-cart body {
    -webkit-transform: translate3d(-280px, 0, 0);
            transform: translate3d(-280px, 0, 0);
  }
}
.modal-cart {
  -webkit-transform: translate(0, 0) scale(0, 0);
          transform: translate(0, 0) scale(0, 0);
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 999998;
  padding: 10px;
}
.modal-cart .cart-header {
  padding-left: 20px;
  margin-bottom: 10px;
}
.modal-cart .cart-item-count,
.modal-cart .cart-sum-label,
.modal-cart .cart-header .price,
.modal-cart .cart-icon {
  display: none;
}
.modal-cart .cart-title {
  text-indent: 0;
  font-size: 1.7rem;
}
.modal-cart .db-cart-message,
.modal-cart .empty-cart-message {
  text-align: left;
}
.modal-cart .modal-cart-close {
  position: absolute;
  display: block;
  color: #000;
  top: 15px;
  right: 10px;
  text-indent: -9999px;
  background-color: transparent;
  color: rgb(55, 55, 55);
  width: 40px;
  height: 40px;
  padding: 0;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  opacity: 0.4;
}
.modal-cart .modal-cart-close:after {
  content: "\e61d";
  text-indent: 0;
  display: block;
  font-size: 26px;
}
.modal-cart .remove-item-action {
  right: 5px;
  transition: opacity 0.3s ease;
}
.modal-cart .remove-item-action:after {
  content: "\e902";
  opacity: 0.4;
}
.modal-cart .shopping-cart-item h3 {
  padding-right: 50px;
}
.modal-cart .checkout-link {
  margin-left: auto;
}
.modal-cart .cart-footer .cart-content-close {
  color: #000;
  background-color: #efefef;
  position: relative;
  padding-right: 40px;
}
.modal-cart .cart-footer .cart-content-close:after {
  content: "\e61d";
  padding-left: 0.5em;
  font-size: 16px;
  position: absolute;
  top: 50%;
  right: 1em;
  margin-top: -0.5em;
}

.desktop .modal-cart .remove-item-action:hover:after {
  opacity: 1;
  color: red;
}
.desktop .cart-block-with-modal .cart-footer .cart-content-close:hover {
  background-color: #dedede;
}
.desktop .modal-cart-close:hover {
  opacity: 1;
}

.modal-cart-content {
  opacity: 1;
  background-color: #fff;
  overflow-y: auto;
  padding-top: 20px;
  padding-bottom: 20px;
  z-index: 999999;
  -webkit-transform-origin: 0 0;
          transform-origin: 0 0;
  transition: -webkit-transform ease-out 0.2s;
  transition: transform ease-out 0.2s;
  transition: transform ease-out 0.2s, -webkit-transform ease-out 0.2s;
  max-width: 600px;
  margin: 60px auto;
  position: relative;
  -webkit-transform: translate3d(100%, 0, 0) scale(0, 0);
          transform: translate3d(100%, 0, 0) scale(0, 0);
}
@media only screen and (max-width: 500px) {
  .modal-cart-content {
    margin-top: 10px;
    margin-bottom: 10px;
  }
}

.has-visible-modal-cart body {
  overflow-y: hidden;
}
.has-visible-modal-cart .modal-cart {
  -webkit-transform: translate(0, 0) scale(1, 1);
          transform: translate(0, 0) scale(1, 1);
  overflow-x: hidden;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}
.has-visible-modal-cart .modal-cart .modal-cart-content {
  -webkit-transform: translate3d(0, 0, 0) scale(1, 1);
          transform: translate3d(0, 0, 0) scale(1, 1);
}

.mobile.has-visible-modal-cart #page-container {
  overflow: hidden;
  position: fixed;
}

.show-cart-content .shopping-cart h4 {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  -webkit-justify-content: space-between;
          justify-content: space-between;
}
.show-cart-content .shopping-cart h4 .variant {
  width: 100%;
}

/* - - - - - - - - - - - - - - - - - - - - - - - - - - -
 * IMPORT CART
 * - - - - - - - - - - - - - - - - - - - - - - - - - - -
 * */
.import-file-wrap,
.import-file-options-wrap,
.import-cart-wrap.no-items,
.import-file-upload .caption {
  display: none;
}

.import-cart-wrap,
.products-add-to-cart {
  margin-top: 30px;
}

@media only screen and (max-width: 900px) {
  .import-cart-wrap .import-cart .cart-item {
    -webkit-flex-direction: column;
            flex-direction: column;
  }
}
@media only screen and (max-width: 700px) {
  .import-cart-wrap .import-cart .cart-item .title {
    padding-left: 0;
    padding-top: 10px;
  }
}

.import-cart .unit-price,
.import-cart .quantity,
.import-cart .total-price {
  -webkit-align-items: flex-start;
          align-items: flex-start;
}
.import-cart .bundled-products {
  padding-left: 1em;
}
@media only screen and (max-width: 700px) {
  .import-cart .bundled-products {
    padding-right: 20px;
  }
}
.import-cart .cart-head {
  margin-bottom: 10px;
  border-bottom: 1px dotted silver;
  padding-bottom: 0px;
}
.import-cart .description {
  -webkit-flex: auto;
          flex: auto;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
}
@media only screen and (max-width: 700px) {
  .import-cart .description {
    display: block;
  }
}
.import-cart .price-wrap {
  padding-right: 40px;
  padding-top: 20px;
}
.import-cart .bundled-product .bundled-product-quantity {
  padding-left: 0.4em;
}
.import-cart .bundled-product-description {
  margin-left: 0.4em;
  margin-top: 10px;
}
.import-cart .bundled-product-description p {
  margin-top: 0.5em;
  font-size: 0.8rem;
  opacity: 0.4;
  line-height: 1.1em;
}
.import-cart .bundled-product-description h5 {
  margin: 0;
  line-height: 1.1em;
  font-size: 0.9rem;
  font-weight: 400;
}
.import-cart .bundled-product-description .bundled-product-quantity {
  padding-left: 0.5em;
}
.import-cart .cart-item .sku:before {
  content: attr(data-title);
}

.customer-cart-import .status-message {
  line-height: 45px;
}

.toggle.import-file a,
.toggle.import-file-options a {
  padding-left: 20px;
  position: relative;
}
.toggle.import-file a:before,
.toggle.import-file-options a:before {
  content: "\e900";
  font-size: 22px;
  position: absolute;
  top: -1px;
  left: 0;
}

.import-file-upload .control input[type=file] {
  min-height: 47px;
  font-size: 1em;
}

.clear-import-items:before {
  font-size: 15px;
  padding-right: 10px;
  content: "\e61d";
}

.add-import-list-to-cart:before {
  font-size: 15px;
  padding-right: 10px;
  content: "\e608";
}

.cancel-preview-cart-import:before {
  font-size: 15px;
  padding-right: 10px;
  content: "\f104";
}

.import-cart-checkout-link,
.cancel-preview-cart-import {
  width: 100%;
}

.import-cart-checkout-link {
  display: block;
}
.import-cart-checkout-link:before {
  font-size: 15px;
  padding-right: 10px;
  content: "\f105";
}

.clear-import-items,
.cancel-preview-cart-import {
  background-color: transparent;
  color: #000;
}

.preview-cart-import {
  margin-top: 40px;
}
.preview-cart-import .data-table {
  overflow-y: scroll;
  padding: 20px;
  background-color: #fff;
  margin-top: 20px;
}
.preview-cart-import .control select {
  border-bottom-style: none;
}
.preview-cart-import label {
  display: none;
}
.preview-cart-import table {
  width: 100%;
  margin-top: 0px;
}
.preview-cart-import table th, .preview-cart-import table td {
  padding: 0 30px 0 4px;
  background-color: #fff;
  border: 1px solid #ECECEC;
}
.preview-cart-import table th {
  text-align: left;
}
.preview-cart-import table tr:first-child th {
  border-style: none;
}

.import-cart-footer {
  margin-top: 20px;
  margin-bottom: 10px;
}

/* - - - - - - - - - - - - - - - - - - - - - - - - - - -
 * SEARCH
 * - - - - - - - - - - - - - - - - - - - - - - - - - - -
 * */
.search-query:before {
  content: "“";
}
.search-query:after {
  content: "”";
}

.search-block h3, .search-block label {
  display: none;
}
.search-block .content {
  position: relative;
}

.search-block form,
.search-block-with-expand form {
  display: -webkit-flex;
  display: flex;
}
.search-block .field-wrap,
.search-block-with-expand .field-wrap {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-flex: 1 0 auto;
          flex: 1 0 auto;
}
.search-block .control,
.search-block-with-expand .control {
  width: 100%;
}
.search-block input[type=search],
.search-block-with-expand input[type=search] {
  background-color: #F0F0F0;
  padding: 0.1rem 0.5rem;
  width: 100%;
}
.search-block button,
.search-block-with-expand button {
  width: 26px;
  padding: 4px;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
}
.search-block button:after,
.search-block-with-expand button:after {
  content: "\e609";
}
.search-block button span,
.search-block-with-expand button span {
  display: none;
}

.search-block-with-expand {
  position: relative;
  display: -webkit-flex;
  display: flex;
}
.search-block-with-expand h3 {
  display: block;
  text-transform: uppercase;
  font-size: 0.9rem;
  cursor: pointer;
  margin: 0;
  font-weight: 400;
}
.search-block-with-expand h3:after {
  padding-left: 0.5em;
  content: "\e609";
  line-height: 17px;
}
.search-block-with-expand .content {
  overflow: hidden;
  width: 0;
  transition: none;
  position: absolute;
  top: -3px;
  left: 0;
  z-index: 9;
}
.search-block-with-expand label {
  display: none;
}
.search-block-with-expand button {
  background-color: #000;
  color: #fff;
}
.search-block-with-expand.expand-search .content {
  width: 182px;
  transition: width 0.3s;
}

.has-open-fullwidth-search .search-block-with-fullwidth {
  overflow: visible;
}

.search-block-with-fullwidth {
  margin-left: -30px;
  margin-right: -30px;
  max-height: 0;
  max-width: none;
  transition: max-height 0s ease-in;
  background-color: #000;
  display: block;
  overflow: hidden;
}
.search-block-with-fullwidth h3, .search-block-with-fullwidth label {
  display: none;
}
.search-block-with-fullwidth .field-wrap {
  -webkit-flex: 1 0 auto;
          flex: 1 0 auto;
}
.search-block-with-fullwidth form {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
}
.search-block-with-fullwidth input[type=search] {
  padding: 0.5rem 10px 0.4rem;
  box-sizing: border-box;
  width: 100%;
  font-size: 1.3rem;
  background-color: transparent;
  color: #fff;
}
.search-block-with-fullwidth .control {
  width: 100%;
}
.search-block-with-fullwidth button {
  background-color: transparent;
  border-radius: 50%;
  opacity: 0.7;
  margin-right: 10px;
}
.search-block-with-fullwidth button[type=submit] {
  display: none;
}
.search-block-with-fullwidth button span {
  display: none;
}
.search-block-with-fullwidth button:after {
  content: "\e61d";
}
.search-block-with-fullwidth.show-search {
  transition: max-height 0.3s ease-in;
  max-height: 200px;
}
@media only screen and (max-width: 500px) {
  .search-block-with-fullwidth {
    margin-left: -10px;
    margin-right: -10px;
  }
}

.autocomplete-suggestions {
  background-color: #fff;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
  border: 1px solid #e8e8e8;
  font-size: 14px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif;
}

.autocomplete-suggestion {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  padding: 2px 3px;
  display: -webkit-flex;
  display: flex;
}

.autocomplete-suggestion .image {
  max-width: 50px;
}
.autocomplete-suggestion .wrapperCenter {
  padding: 0 5px;
}
.autocomplete-suggestion .category {
  color: grey;
}

.autocomplete-selected {
  background-color: #e9e8e9;
  color: #446dd4;
}

.desktop .autocomplete-suggestion:hover {
  cursor: pointer;
}
.desktop .search-block-with-fullwidth button:hover {
  background-color: transparent;
  opacity: 1;
}

.open-fullwidth-search {
  width: 26px;
  height: 26px;
  text-align: center;
  line-height: 28px;
  cursor: pointer;
}
.open-fullwidth-search span {
  display: none;
}
.open-fullwidth-search:after {
  content: "\e609";
}

.off-canvas-nav .search-block {
  position: relative;
  padding-left: 20px;
  margin-bottom: 20px;
}
.off-canvas-nav .search-block:before {
  padding-right: 0.5em;
  content: "\e609";
  width: 25px;
  height: 25px;
  position: absolute;
  font-size: 16px;
  top: 9px;
  left: 0;
}
.off-canvas-nav .search-block h3 {
  display: none;
}
.off-canvas-nav .search-block button {
  display: none;
}
.off-canvas-nav .search-block label {
  display: none;
}
.off-canvas-nav .search-block input {
  width: 100%;
  padding-left: 0;
}
.off-canvas-nav .search-block input::-webkit-input-placeholder {
  color: rgb(55, 55, 55);
}
.off-canvas-nav .search-block input:-moz-placeholder {
  color: rgb(55, 55, 55);
}
.off-canvas-nav .search-block input::-moz-placeholder {
  color: rgb(55, 55, 55);
}
.off-canvas-nav .search-block input:-ms-input-placeholder {
  color: rgb(55, 55, 55);
}

.site-footer .search-block {
  width: 250px;
}
.site-footer .search-block .field-wrap {
  position: relative;
  -webkit-flex: 1;
          flex: 1;
  margin-top: 0;
}
.site-footer .search-block .control,
.site-footer .search-block input {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.site-footer .search-block .control::-webkit-input-placeholder,
.site-footer .search-block input::-webkit-input-placeholder {
  color: rgb(150, 150, 150);
}
.site-footer .search-block .control:-moz-placeholder,
.site-footer .search-block input:-moz-placeholder {
  color: rgb(150, 150, 150);
}
.site-footer .search-block .control::-moz-placeholder,
.site-footer .search-block input::-moz-placeholder {
  color: rgb(150, 150, 150);
}
.site-footer .search-block .control:-ms-input-placeholder,
.site-footer .search-block input:-ms-input-placeholder {
  color: rgb(150, 150, 150);
}
.site-footer .search-block button {
  padding: 10px;
  width: 40px;
  font-size: 20px;
}

.quick-search-results {
  background: #ffffff;
  min-width: 355px;
  box-shadow: rgba(0, 0, 0, 0.1) 0 3px 6px;
  position: absolute;
  left: 0;
  right: 0;
  z-index: 9999;
  text-transform: none;
  width: 100%;
}
@media only screen and (max-width: 500px) {
  .quick-search-results {
    min-width: 315px;
  }
}
.quick-search-results .wrapper-header {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  padding: 20px 10px 10px;
  -webkit-justify-content: space-between;
          justify-content: space-between;
}
.quick-search-results .header-products {
  font-size: 1rem;
  text-transform: uppercase;
  font-weight: 600;
}
.quick-search-results .search-link {
  text-transform: uppercase;
  font-size: 0.9rem;
}
.quick-search-results .quick-search-result {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  padding: 10px;
  line-height: 1.3em;
  cursor: pointer;
}
.quick-search-results .quick-search-result:last-of-type {
  margin-bottom: 20px;
}
.quick-search-results .quick-search-result .image {
  width: 100%;
  max-width: 55px;
  height: auto;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
}
.quick-search-results .quick-search-result .image img {
  display: block;
  max-height: 55px;
}
.quick-search-results .quick-search-result .price {
  margin: 0 0 auto auto;
  font-weight: 600;
  padding-top: 5px;
}
.quick-search-results .quick-search-result .wrapper-content {
  margin-left: 10px;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  overflow: hidden;
  padding-right: 20px;
}
.quick-search-results .quick-search-result .name {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  font-weight: 600;
  padding-top: 5px;
}
.quick-search-results .quick-search-result .category {
  font-size: 0.875rem;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

/* - - - - - - - - - - - - - - - - - - - - - - - - - - -
 * LANGUAGE
 * - - - - - - - - - - - - - - - - - - - - - - - - - - -
 * */
.select-language-flags .language {
  display: inline-block;
  line-height: 0;
  font-size: 0;
  text-align: center;
  padding-top: 5px;
  margin-left: 3px;
  color: rgb(55, 55, 55);
  opacity: 0.6;
}
.select-language-flags .language-code {
  display: block;
  font-size: 10px;
  margin-top: 2px;
  display: none;
}
.select-language-flags .language-icon {
  font: 0/0 a;
  background-repeat: no-repeat;
  background-position: center center;
  display: inline-block;
  width: 22px;
  height: 25px;
}
.select-language-flags .language-icon:first-child {
  margin-left: 0;
}
.select-language-flags .current-language {
  opacity: 1;
}
.select-language-flags .language-icon-da {
  background-image: url(../img/flags/da.png);
}
.select-language-flags .language-icon-de {
  background-image: url(../img/flags/de.png);
}
.select-language-flags .language-icon-en {
  background-image: url(../img/flags/en.png);
}
.select-language-flags .language-icon-es {
  background-image: url(../img/flags/es.png);
}
.select-language-flags .language-icon-fi {
  background-image: url(../img/flags/fi.png);
}
.select-language-flags .language-icon-fr {
  background-image: url(../img/flags/fr.png);
}
.select-language-flags .language-icon-no {
  background-image: url(../img/flags/no.png);
}
.select-language-flags .language-icon-sv {
  background-image: url(../img/flags/sv.png);
}

.desktop .select-language-flags .language:hover {
  cursor: pointer;
  text-decoration: none;
  opacity: 1;
}

.select-language-dropdown-flags {
  width: 55px;
  height: 25px;
  position: relative;
}
.select-language-dropdown-flags .select-language-dropdown {
  position: absolute;
  width: 45px;
  z-index: 800;
  max-height: 0;
  opacity: 0;
  overflow: hidden;
  transition: max-height 0.3s opacity 0.1s;
}
.select-language-dropdown-flags .open-language-dropdown .select-language-dropdown {
  max-height: 400px;
  opacity: 1;
}
.select-language-dropdown-flags .select-language-button {
  width: 55px;
  padding-left: 4px;
  height: 25px;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  background-position: 8px center;
  background-repeat: no-repeat;
  color: rgb(55, 55, 55);
}
.select-language-dropdown-flags .select-language-button:after {
  content: "\e900";
  position: absolute;
  top: 1px;
  right: 0;
  font-size: 22px;
}
.select-language-dropdown-flags .select-language-dropdown {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  padding-top: 8px;
  background-color: #fff;
  padding-left: 4px;
}
.select-language-dropdown-flags .language {
  margin-bottom: 10px;
  color: rgb(55, 55, 55);
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
}
.select-language-dropdown-flags .language-code {
  font-size: 10px;
  line-height: 0;
  display: none;
}
.select-language-dropdown-flags .language-icon {
  height: 20px;
  width: 37px;
  background-position: center center;
  background-repeat: no-repeat;
}
.select-language-dropdown-flags .current-language {
  display: none;
}
.select-language-dropdown-flags .language-icon-da {
  background-image: url(../img/flags/da.png);
}
.select-language-dropdown-flags .language-icon-de {
  background-image: url(../img/flags/de.png);
}
.select-language-dropdown-flags .language-icon-en {
  background-image: url(../img/flags/en.png);
}
.select-language-dropdown-flags .language-icon-es {
  background-image: url(../img/flags/es.png);
}
.select-language-dropdown-flags .language-icon-fi {
  background-image: url(../img/flags/fi.png);
}
.select-language-dropdown-flags .language-icon-fr {
  background-image: url(../img/flags/fr.png);
}
.select-language-dropdown-flags .language-icon-no {
  background-image: url(../img/flags/no.png);
}
.select-language-dropdown-flags .language-icon-sv {
  background-image: url(../img/flags/sv.png);
}

.desktop .select-language-dropdown-flags .select-language-button:hover,
.desktop .select-language-dropdown-flags .language:hover {
  cursor: pointer;
  text-decoration: none;
}

/* - - - - - - - - - - - - - - - - - - - - - - - - - - -
 * NEWSLETTER
 * - - - - - - - - - - - - - - - - - - - - - - - - - - -
 * */
.newsletter-block .content {
  position: relative;
  display: -webkit-flex;
  display: flex;
}
@media only screen and (max-width: 500px) {
  .newsletter-block .content {
    -webkit-flex-direction: column;
            flex-direction: column;
  }
}
.newsletter-block .email-type {
  margin-top: 0;
  -webkit-flex: 1;
          flex: 1;
}

.site-footer .newsletter-block {
  display: inline-block;
  max-width: none;
  width: 330px;
}
@media only screen and (max-width: 500px) {
  .site-footer .newsletter-block {
    width: 100%;
  }
}
.site-footer .newsletter-block .caption {
  display: none;
  margin-top: 0;
}
.site-footer .newsletter-block input[type=email] {
  font-size: 1rem;
  border-style: none;
  background-color: #F0F0F0;
  padding: 0.1rem 0.5rem;
  min-height: 47px;
}
.site-footer .newsletter-block input[type=email]::-webkit-input-placeholder {
  color: rgb(150, 150, 150);
}
.site-footer .newsletter-block input[type=email]:-moz-placeholder {
  color: rgb(150, 150, 150);
}
.site-footer .newsletter-block input[type=email]::-moz-placeholder {
  color: rgb(150, 150, 150);
}
.site-footer .newsletter-block input[type=email]:-ms-input-placeholder {
  color: rgb(150, 150, 150);
}
.site-footer .newsletter-subscribe-button {
  position: static;
  top: auto;
  right: auto;
  margin-top: 0;
}

/* - - - - - - - - - - - - - - - - - - - - - - - - - - -
 * BREADCRUMBS
 * - - - - - - - - - - - - - - - - - - - - - - - - - - -
 * */
.breadcrumbs {
  text-transform: uppercase;
  font-size: 0.9rem;
  opacity: 0.8;
}

.has-sidebar .breadcrumbs {
  margin-top: 0;
}

.breadcrumb {
  color: rgb(55, 55, 55);
  padding-right: 0.4em;
}
.breadcrumb:before {
  content: "/";
  opacity: 0.6;
  padding-right: 0.4em;
}
.breadcrumb:first-child:before {
  display: none;
}

.desktop .breadcrumb:hover {
  text-decoration: none;
}

.block-fullwidth img {
  width: 100%;
}

.block-fullwidth {
  max-width: none;
  padding-left: 0;
  padding-right: 0;
  margin-left: -30px;
  margin-right: -30px;
}
@media only screen and (max-width: 500px) {
  .block-fullwidth {
    margin-left: -10px;
    margin-right: -10px;
  }
}

.read-more a:after {
  content: "Read more »";
}

.read-less a:after {
  content: "Read less ...";
}

html[lang=sv] .read-more a:after {
  content: "Läs mer »";
}
html[lang=sv] .read-less a:after {
  content: "Läs mindre ...";
}

/* - - - - - - - - - - - - - - - - - - - - - - - - - - -
 * Lightbox
 * - - - - - - - - - - - - - - - - - - - - - - - - - - -
 * */
.desktop .pswp__button:hover {
  background-color: transparent;
}

/* - - - - - - - - - - - - - - - - - - - - - - - - - - -
 * CURRENCY
 * - - - - - - - - - - - - - - - - - - - - - - - - - - -
 * */
.select-currency-flags .currency {
  display: inline-block;
  line-height: 0;
  font-size: 0;
  text-align: center;
  padding-top: 5px;
  margin-left: 3px;
  color: rgb(55, 55, 55);
  opacity: 0.6;
}
.select-currency-flags .currency-code {
  display: block;
  font-size: 10px;
  margin-top: 2px;
}
.select-currency-flags .currency-icon {
  font: 0/0 a;
  background-repeat: no-repeat;
  background-position: center center;
  display: block;
  width: 22px;
  height: 25px;
}
.select-currency-flags .currency-icon:first-child {
  margin-left: 0;
}
.select-currency-flags .current-currency {
  opacity: 1;
}
.select-currency-flags .currency-icon-dkk {
  background-image: url(../img/flags/da.png);
}
.select-currency-flags .currency-icon-gbp {
  background-image: url(../img/flags/en.png);
}
.select-currency-flags .currency-icon-eur {
  background-image: url(../img/flags/eu.png);
}
.select-currency-flags .currency-icon-nok {
  background-image: url(../img/flags/no.png);
}
.select-currency-flags .currency-icon-sek {
  background-image: url(../img/flags/sv.png);
}
.select-currency-flags .currency-icon-usd {
  background-image: url(../img/flags/us.png);
}

.desktop .select-currency-flags .currency:hover {
  cursor: pointer;
  text-decoration: none;
  opacity: 1;
}

.select-currency-dropdown-flags {
  width: 70px;
  height: 25px;
  position: relative;
}
.select-currency-dropdown-flags .select-currency-dropdown {
  position: absolute;
  width: 65px;
  z-index: 800;
  max-height: 0;
  opacity: 0;
  overflow: hidden;
  transition: max-height 0.3s opacity 0.1s;
}
.select-currency-dropdown-flags .open-currency-dropdown .select-currency-dropdown {
  max-height: 400px;
  opacity: 1;
}
.select-currency-dropdown-flags .select-currency-button {
  width: 75px;
  padding-left: 4px;
  height: 25px;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  background-position: 8px center;
  background-repeat: no-repeat;
  color: rgb(55, 55, 55);
}
.select-currency-dropdown-flags .select-currency-button:after {
  content: "\e900";
  position: absolute;
  top: 1px;
  right: 0;
  font-size: 22px;
}
.select-currency-dropdown-flags .select-currency-dropdown {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  padding-top: 8px;
  background-color: #fff;
  padding-left: 4px;
}
.select-currency-dropdown-flags .currency {
  margin-bottom: 10px;
  color: rgb(55, 55, 55);
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
}
.select-currency-dropdown-flags .currency-code {
  font-size: 10px;
  line-height: 0;
}
.select-currency-dropdown-flags .currency-icon {
  height: 20px;
  width: 37px;
  background-position: center center;
  background-repeat: no-repeat;
}
.select-currency-dropdown-flags .current-currency {
  display: none;
}
.select-currency-dropdown-flags .currency-icon-dkk {
  background-image: url(../img/flags/da.png);
}
.select-currency-dropdown-flags .currency-icon-gbp {
  background-image: url(../img/flags/en.png);
}
.select-currency-dropdown-flags .currency-icon-eur {
  background-image: url(../img/flags/eu.png);
}
.select-currency-dropdown-flags .currency-icon-nok {
  background-image: url(../img/flags/no.png);
}
.select-currency-dropdown-flags .currency-icon-sek {
  background-image: url(../img/flags/sv.png);
}
.select-currency-dropdown-flags .currency-icon-usd {
  background-image: url(../img/flags/us.png);
}

.desktop .select-currency-dropdown-flags .select-currency-button:hover,
.desktop .select-currency-dropdown-flags .currency:hover {
  cursor: pointer;
  text-decoration: none;
}

/* - - - - - - - - - - - - - - - - - - - - - - - - - - -
 * VAT
 * - - - - - - - - - - - - - - - - - - - - - - - - - - -
 * */
.vat-selector-block {
  display: none;
}
.vat-selector-block p {
  margin: 0;
}
.vat-selector-block a {
  text-indent: 0;
  padding-top: 8px;
  padding-bottom: 8px;
}

.header-items {
  text-transform: uppercase;
}
.header-items .vat-selector-block a {
  display: block;
}
.header-items .vat-selector-block span {
  display: none;
}

.select-locale-modal {
  margin: 0;
}
.select-locale-modal h3 {
  margin: 0;
}
.select-locale-modal .fieldset {
  margin-top: 0.5em;
}

.select-locale-modal-footer {
  margin-top: 30px;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
}
.select-locale-modal-footer button {
  width: auto;
  font-size: 90%;
}

.select-locale-modal-close-button {
  background-color: transparent;
  color: #000;
  position: relative;
  padding-left: 40px;
}
.select-locale-modal-close-button:before {
  content: "\e61d";
  position: absolute;
  top: 50%;
  margin-top: -6px;
  left: 13px;
  font-size: 13px;
}

.desktop .select-locale-modal-close-button:hover {
  color: #fff;
}

.select-locale-button {
  background-color: transparent;
  color: rgb(55, 55, 55);
  border: 1px solid silver;
  width: auto;
  text-transform: none;
  font-size: 0.9em;
  padding: 0;
}

.select-locale-button-content {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: stretch;
          align-items: stretch;
}
.select-locale-button-content .icon:before {
  content: "\e907";
}
.select-locale-button-content span {
  padding: 4px 8px;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
}
.select-locale-button-content .country,
.select-locale-button-content .language,
.select-locale-button-content .currency {
  border-left: 1px solid silver;
}

.desktop .select-locale-button:hover {
  color: #fff;
}

#checkout-page.mobile .sw-modal-window .wrapper {
  top: 20px;
  bottom: 20px;
}
#checkout-page.mobile .sw-modal-window.iframe .wrapper .main {
  padding: 0px;
}
#checkout-page.desktop .sw-modal-window .wrapper {
  right: 40px;
  bottom: 40px;
  left: 40px;
}
#checkout-page .sw-modal-window.iframe .content {
  width: 100%;
}
#checkout-page .sw-modal-window .wrapper {
  display: -webkit-flex;
  display: flex;
}
#checkout-page .sw-modal-window .wrapper {
  z-index: 999999999;
}
#checkout-page .sw-modal-window .close {
  top: 4px;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  right: 4px;
}
#checkout-page .sw-modal-window .close:after {
  top: 9px;
  right: 8px;
  width: 26px;
  height: 26px;
  color: rgb(0, 0, 0);
}
#checkout-page .sw-modal-window .close:hover:after {
  color: rgb(0, 0, 0);
}
#checkout-page .sw-modal-window.iframe .wrapper .close {
  font-size: 25px;
}

.select-list {
  margin-top: 10px;
  font-size: 1.2rem;
  position: relative;
}

.select-list-control {
  border-bottom: 1px solid #000;
}

.placeholder-text {
  color: #b4b4b4;
}

.select-list-label {
  font-size: 0.7rem;
  line-height: 1;
  opacity: 0.5;
}

.select-list:before {
  content: "\e900";
  position: absolute;
  line-height: 40px;
  right: 1px;
  top: 4px;
  width: 40px;
  height: 40px;
  text-align: center;
  color: #000;
  font-size: 22px;
}

.select-list-control {
  position: relative;
}

.select-list-options {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  margin-top: 1px;
  background-color: #eeeeee;
  z-index: 1000;
  display: none;
  font-size: 1rem;
  line-height: 1.2em;
  box-shadow: 0 6px 10px rgba(0, 0, 0, 0.2);
}

.select-list-show-options .select-list-options {
  display: block;
}

.select-list-placeholder {
  padding: 1px 40px 4px 0;
}

.select-list-option {
  padding: 10px;
  border-bottom: 1px solid silver;
}

.desktop .select-list-option-hover {
  background-color: #4a99fb;
  color: #fff;
}

.desktop .select-list-option-text:hover,
.desktop .select-list-placeholder:hover {
  cursor: pointer;
}

.favorite-cart-block {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
}

.favorite-cart {
  cursor: pointer;
  position: relative;
  display: -webkit-flex;
  display: flex;
  height: 100%;
}

.favorite-cart-header {
  display: block;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  height: 100%;
}

.favorite-cart-icon {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-order: -1;
          order: -1;
  height: 100%;
}
.favorite-cart-icon:before {
  content: "\e608";
  font-size: 30px;
}

.favorite {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: flex-start;
          justify-content: flex-start;
  margin-left: auto;
  position: relative;
  cursor: pointer;
  height: 100%;
}
.favorite:before {
  content: "\e90f";
  font-size: 30px;
  margin-top: 0;
}

.favorite-active:before {
  content: "\e90c";
  color: #ff3838;
}

.favorite-list,
.shopping-cart-list {
  display: none;
}

.favorite-item-count .number {
  background-color: #000;
  color: #fff;
  border-radius: 50%;
  display: block;
  width: 18px;
  height: 18px;
  text-align: center;
  line-height: 19px;
  font-size: 12px;
  transition: -webkit-transform 0.5s;
  transition: transform 0.5s;
  transition: transform 0.5s, -webkit-transform 0.5s;
}

.favorite-has-items .favorite-item-count,
.product-has-items .product-item-count {
  display: block;
}

.favorite-item-count,
.product-item-count {
  margin-left: 0.3em;
  display: none;
}

.product-item-count .number {
  background-color: #000;
  color: #fff;
  border-radius: 50%;
  display: block;
  width: 18px;
  height: 18px;
  text-align: center;
  line-height: 19px;
  font-size: 12px;
  transition: -webkit-transform 0.5s;
  transition: transform 0.5s;
  transition: transform 0.5s, -webkit-transform 0.5s;
}
.product-item-count .unit {
  font-size: 0.5em;
  text-transform: uppercase;
  display: none;
}

.open-off-canvas-favorites .favorite:before {
  margin-bottom: 8px;
}

.has-visible-shopping-favorite-cart body,
.has-visible-shopping-cart body {
  -webkit-transform: translate3d(-350px, 0, 0);
          transform: translate3d(-350px, 0, 0);
  overflow-y: hidden;
}
@media only screen and (max-width: 374px) {
  .has-visible-shopping-favorite-cart body,
  .has-visible-shopping-cart body {
    -webkit-transform: translate3d(-300px, 0, 0);
            transform: translate3d(-300px, 0, 0);
  }
}
.has-visible-shopping-favorite-cart body .off-canvas-cart,
.has-visible-shopping-cart body .off-canvas-cart {
  display: none;
}
.has-visible-shopping-favorite-cart.admin-user-logged-in .shopping-favorite-cart,
.has-visible-shopping-cart.admin-user-logged-in .shopping-favorite-cart {
  top: 50px;
}
.has-visible-shopping-favorite-cart .admin-toolbar,
.has-visible-shopping-cart .admin-toolbar {
  -webkit-transform: translateX(350px);
          transform: translateX(350px);
  transition: -webkit-transform 0.3s cubic-bezier(0.46, 0.03, 0.52, 0.96);
  transition: transform 0.3s cubic-bezier(0.46, 0.03, 0.52, 0.96);
  transition: transform 0.3s cubic-bezier(0.46, 0.03, 0.52, 0.96), -webkit-transform 0.3s cubic-bezier(0.46, 0.03, 0.52, 0.96);
  z-index: 2;
}
@media only screen and (max-width: 374px) {
  .has-visible-shopping-favorite-cart .admin-toolbar,
  .has-visible-shopping-cart .admin-toolbar {
    -webkit-transform: translateX(300px);
            transform: translateX(300px);
  }
}
.has-visible-shopping-favorite-cart #page-container,
.has-visible-shopping-cart #page-container {
  position: fixed;
  overflow: hidden;
  width: 100%;
  height: 100%;
}
.has-visible-shopping-favorite-cart #page-container:after,
.has-visible-shopping-cart #page-container:after {
  opacity: 1;
  right: 0;
  -webkit-transform: translate3d(0, 0, 0);
          transform: translate3d(0, 0, 0);
}
.has-visible-shopping-favorite-cart.has-fixed-site-header .admin-toolbar-phantom,
.has-visible-shopping-cart.has-fixed-site-header .admin-toolbar-phantom {
  display: none;
}

.favorite-list .favorite-details {
  width: 230px;
  padding: 12px 40px 12px 7px;
}
@media only screen and (max-width: 374px) {
  .favorite-list .favorite-details {
    width: 205px;
  }
}
.favorite-list .img-wrap {
  width: 55px;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  margin: 5px;
}
@media only screen and (max-width: 374px) {
  .favorite-list .img-wrap {
    width: 40px;
  }
}
.favorite-list .info-link,
.favorite-list .add-to-cart {
  background: transparent;
  color: rgb(55, 55, 55);
  padding: 0;
  border-right: 1px solid #dedede;
  width: 55px;
  box-sizing: border-box;
}
@media only screen and (max-width: 374px) {
  .favorite-list .info-link,
  .favorite-list .add-to-cart {
    width: 45px;
  }
}
.favorite-list .info-link:hover,
.favorite-list .add-to-cart:hover {
  color: #ffffff;
}
.favorite-list .info-link span,
.favorite-list .add-to-cart span {
  display: block;
}
.favorite-list .add-to-cart:before {
  content: "\e608";
  font-size: 20px;
}
.favorite-list .info-link {
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
}
.favorite-list .info-link:hover {
  background: #444;
  color: #fff;
}

.shopping-cart-list {
  margin-bottom: 136px;
}
@media only screen and (max-width: 980px) {
  .shopping-cart-list {
    margin-bottom: 125px;
  }
}
.shopping-cart-list .favorite-details {
  width: 285px;
  padding: 12px 40px 12px 7px;
}
@media only screen and (max-width: 374px) {
  .shopping-cart-list .favorite-details {
    width: 250px;
  }
}
.shopping-cart-list .img-wrap {
  width: 55px;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  margin: 5px;
}
@media only screen and (max-width: 374px) {
  .shopping-cart-list .img-wrap {
    width: 40px;
  }
}
.shopping-cart-list .favorite-item a {
  width: 100%;
}

.favorite-list.favorite-list-visible,
.shopping-cart-list.product-list-visible {
  display: block;
  -webkit-flex: 1;
          flex: 1;
  overflow-y: auto;
  overflow-x: hidden;
}

.shopping-favorite-cart {
  background-color: #fff;
  position: fixed;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  top: 0;
  right: 0;
  width: 350px;
  bottom: 0;
  overflow-y: auto;
  -webkit-transform: translate3d(100%, 0, 0);
          transform: translate3d(100%, 0, 0);
  -webkit-overflow-scrolling: touch;
}
@media only screen and (max-width: 374px) {
  .shopping-favorite-cart {
    width: 300px;
  }
}
.shopping-favorite-cart .db-cart-message {
  text-align: left;
  padding: 15px 20px;
  line-height: 1.1em;
}
.shopping-favorite-cart .db-cart-message:nth-child(2) {
  padding-top: 0;
}
.shopping-favorite-cart .db-cart-message:last-of-type {
  border-bottom: 1px solid #dedede;
}
.shopping-favorite-cart .product-price,
.shopping-favorite-cart .quantity-wrap {
  margin-top: 5px;
}
.shopping-favorite-cart .added-to-cart {
  background: #000;
  color: #fff;
}
.shopping-favorite-cart .favorite-cart-icon:before,
.shopping-favorite-cart .favorite:before {
  margin-top: 17px;
  margin-bottom: auto;
}
.shopping-favorite-cart .favorite {
  margin-right: 15px;
}
.shopping-favorite-cart .favorite-item-count,
.shopping-favorite-cart .product-item-count {
  margin-top: 16px;
}
.shopping-favorite-cart .selected:after {
  content: "";
  height: 3px;
  background-color: rgb(55, 55, 55);
  width: 100%;
  display: block;
  position: absolute;
  bottom: 0;
  width: 33px;
  left: 0;
}
.shopping-favorite-cart .cart-item-count {
  margin-top: 16px;
}
.shopping-favorite-cart .cart-block.selected:after {
  left: 9px;
}
.shopping-favorite-cart .favorite.selected,
.shopping-favorite-cart .selected .cart-header {
  cursor: default;
}
.shopping-favorite-cart .shopping-favorite-cart-header {
  padding: 0 20px 0 70px;
  height: 65px;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  border-bottom: 1px solid #dedede;
}
.shopping-favorite-cart .selected-header {
  font-size: 1.25rem;
}
@media only screen and (max-width: 500px) {
  .shopping-favorite-cart .selected-header {
    font-size: 1rem;
  }
}
.shopping-favorite-cart .favorite-items {
  margin: 0;
  padding: 0;
  background-image: none;
  list-style: none;
}
.shopping-favorite-cart .favorite-item {
  border-bottom: 1px solid #dedede;
  position: relative;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
}
.shopping-favorite-cart .favorite-item a {
  color: rgb(55, 55, 55);
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
}
.shopping-favorite-cart .favorite-item a:hover {
  text-decoration: none;
}
.shopping-favorite-cart .favorite-details h3 {
  font-size: 1rem;
  margin: 0;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.shopping-favorite-cart .stock-status,
.shopping-favorite-cart .sku {
  font-size: 0.75rem;
}
.shopping-favorite-cart .stock-status.stock-status-has-content:after {
  content: ",";
  padding-right: 4px;
}
.shopping-favorite-cart .cart-item .sku:before {
  content: "" attr(data-title);
}
.shopping-favorite-cart .item-info {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  line-height: 1.2em;
}
.shopping-favorite-cart .item-info .product-price .old-price {
  text-decoration: line-through;
  color: #969696;
  margin-right: 5px;
}
.shopping-favorite-cart .item-info .product-price .old-price .price {
  font-size: 14px;
}
.shopping-favorite-cart .item-details {
  width: 100%;
}
.shopping-favorite-cart .remove-item-action {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  padding: 10px;
  right: 0;
  top: 0;
  width: 40px;
  height: 40px;
}
.shopping-favorite-cart .remove-item-action:hover {
  background: transparent;
  color: rgb(55, 55, 55);
}
.shopping-favorite-cart .remove-item-action:before {
  margin-top: auto;
  margin-bottom: auto;
}
.shopping-favorite-cart .erase-list {
  text-align: right;
  padding: 15px 20px;
}
.shopping-favorite-cart .erase-button {
  font-size: 0.75rem;
  color: #fff;
  display: inline-block;
  padding: 8px 10px 6px;
  cursor: pointer;
  text-transform: none;
}
.shopping-favorite-cart .cart-content-close {
  position: absolute;
  display: block;
  top: 10px;
  left: 15px;
  text-indent: -9999px;
  background-color: transparent;
  color: rgb(55, 55, 55);
  width: 40px;
  height: 40px;
  padding: 0;
}
.shopping-favorite-cart .cart-content-close:after {
  content: "\e61d";
  text-indent: 0;
  display: block;
  font-size: 32px;
  margin-top: -0.3em;
}
.shopping-favorite-cart .shopping-cart {
  border-top: 0;
}
.shopping-favorite-cart .cart-footer {
  width: 100%;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  border-top: 1px solid #dedede;
  background: rgba(255, 255, 255, 0.96);
  margin: 0;
  padding: 0;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: center;
          justify-content: center;
}
.shopping-favorite-cart .cart-footer .checkout-link {
  margin: 0 20px 20px 20px;
  display: block;
}
.shopping-favorite-cart .cart-footer .cart-summary {
  padding: 20px;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
}
.shopping-favorite-cart .cart-footer .cart-summary .cart-grand-total {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  font-size: 16px;
}
.shopping-favorite-cart .cart-footer .cart-summary .cart-grand-total.subtotal {
  font-size: 14px;
}
@media only screen and (max-width: 980px) {
  .shopping-favorite-cart .cart-footer .cart-summary .cart-grand-total.subtotal {
    font-size: 12px;
  }
}
.shopping-favorite-cart .cart-footer .cart-summary .cart-grand-total.subtotal .price {
  font-size: 1.125rem;
}
@media only screen and (max-width: 980px) {
  .shopping-favorite-cart .cart-footer .cart-summary .cart-grand-total.subtotal .price {
    font-size: 1rem;
  }
}
@media only screen and (max-width: 980px) {
  .shopping-favorite-cart .cart-footer .cart-summary {
    padding: 15px 10px;
  }
}
.shopping-favorite-cart .cart-footer .cart-summary .cart-grand-total {
  font-size: 1.375rem;
  margin: 0;
}
@media only screen and (max-width: 980px) {
  .shopping-favorite-cart .cart-footer .cart-summary .cart-grand-total {
    font-size: 1.125rem;
  }
}

.icon-container {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-justify-content: flex-end;
          justify-content: flex-end;
  position: absolute;
  bottom: 0;
  left: auto;
  right: 0;
  width: auto;
}
.icon-container .favorite,
.icon-container .quick-view {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  width: 48px;
  height: 48px;
}
.icon-container .favorite:before {
  content: "\e90f";
  font-size: 20px;
}
.icon-container .favorite-active:before {
  content: "\e90c";
  color: #ff3838;
}
.icon-container .quick-view:before {
  content: "\e608";
  font-size: 20px;
}

.has-favorite-option .product-info {
  padding-bottom: 20px;
}

@media only screen and (max-width: 980px) {
  #page-container {
    overflow-y: auto;
  }
}
.infinite-loading-container {
  font-size: 0.8rem;
  opacity: 0.5;
  padding: 15px 15px 20px;
  line-height: 1.1em;
  font-style: italic;
}

.has-favorite-cart .add-to-cart-form .add-to-cart-wrap {
  position: relative;
}
.has-favorite-cart .add-to-cart-form .order-data {
  -webkit-order: 100;
          order: 100;
}
.has-favorite-cart .add-to-cart-form .product-add-to-cart-action,
.has-favorite-cart .add-to-cart-form .quantity-field,
.has-favorite-cart .add-to-cart-form .product-status,
.has-favorite-cart .add-to-cart-form .product-variants,
.has-favorite-cart .add-to-cart-form .order-data {
  margin-right: 45px;
  width: calc(100% - 45px);
}
.has-favorite-cart .add-to-cart-form .toggle-favorite-action {
  width: 30px;
  position: absolute;
  right: 0;
  bottom: 7px;
}
.has-favorite-cart .product-details {
  margin-right: 45px;
  width: calc(50% - 45px);
}
@media only screen and (max-width: 1300px) {
  .has-favorite-cart .product-details {
    width: calc(100% - 45px);
  }
}
.has-favorite-cart .watchable .add-to-cart-form {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
}
.has-favorite-cart .watchable .add-to-cart-form .add-to-cart-wrap {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  margin-left: 20px;
  -webkit-order: 1;
          order: 1;
}
.has-favorite-cart .watchable .add-to-cart-form .toggle-favorite-action {
  position: static;
}
.has-favorite-cart .non-watchable-non-buyable .add-to-cart-form .toggle-favorite-action {
  position: static;
  padding-top: 20px;
}

[dir=rtl].mobile.chrome body {
  transition: none;
}
[dir=rtl] #mobile-menu {
  right: 10px;
}
[dir=rtl].mobile .header-item-1 {
  -webkit-justify-content: flex-start;
          justify-content: flex-start;
  padding-right: 50px;
  padding-left: 0;
}
@media only screen and (max-width: 375px) {
  [dir=rtl].mobile .header-item-1 {
    padding-right: 20px;
  }
}
[dir=rtl].mobile-menu-with-left-push.has-visible-off-canvas-nav #page-container {
  -webkit-transform: translate3d(-240px, 0, 0);
          transform: translate3d(-240px, 0, 0);
}
[dir=rtl].mobile-menu-with-left-push .off-canvas-nav {
  right: 0;
}
[dir=rtl] .off-canvas-nav .menu-level-1-title,
[dir=rtl] .off-canvas-nav .menu-level-2-title,
[dir=rtl] .off-canvas-nav .menu-level-3-title {
  padding-left: 0;
}
[dir=rtl] .off-canvas-nav .menu-level-1-title {
  padding-right: 10px;
}
[dir=rtl] .off-canvas-nav .menu-level-2-title {
  padding-right: 20px;
}
[dir=rtl] .off-canvas-nav .menu-level-3-title {
  padding-right: 30px;
}
[dir=rtl] .off-canvas-nav .menu-title-has-children {
  padding-right: 50px;
}
[dir=rtl].has-visible-off-canvas-cart body {
  -webkit-transform: translate3d(280px, 0, 0);
          transform: translate3d(280px, 0, 0);
}
[dir=rtl].has-visible-shopping-favorite-cart body, [dir=rtl].has-visible-shopping-cart body {
  will-change: transform;
  -webkit-transform: translate3d(350px, 0, 0);
          transform: translate3d(350px, 0, 0);
}
@media only screen and (max-width: 374px) {
  [dir=rtl].has-visible-shopping-favorite-cart body, [dir=rtl].has-visible-shopping-cart body {
    -webkit-transform: translate3d(300px, 0, 0);
            transform: translate3d(300px, 0, 0);
  }
}
[dir=rtl] .shopping-favorite-cart {
  left: 0;
  right: auto;
  -webkit-transform: translate3d(-100%, 0, 0);
          transform: translate3d(-100%, 0, 0);
}
[dir=rtl] .shopping-favorite-cart .favorite {
  margin-left: 15px;
  margin-right: auto;
}
[dir=rtl] .off-canvas-cart {
  left: 0;
  right: auto;
  -webkit-transform: translate3d(-100%, 0, 0);
          transform: translate3d(-100%, 0, 0);
}
[dir=rtl] .modal-cart .modal-cart-close {
  left: 15px;
  right: auto;
}
[dir=rtl] .filter-groups {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
}
[dir=rtl] .product-filter label {
  display: -webkit-inline-flex;
  display: inline-flex;
}
[dir=rtl] .save-percent-info .value::before {
  content: "";
}
[dir=rtl] #product-reviews .product-rating .rating {
  display: -webkit-flex;
  display: flex;
}
[dir=rtl] #product-reviews .product-rating .title {
  float: right;
  padding: 5px 0 0 10px;
}
[dir=rtl] #product-reviews .add-review-comment .rating-selector {
  text-align: right;
}
[dir=rtl] #product-reviews .add-review-comment .rating-selector .control {
  display: -webkit-flex;
  display: flex;
}
[dir=rtl] #product-reviews .review-author {
  margin-right: 115px;
  margin-left: 0;
}
[dir=rtl] .header-region .block {
  margin-right: 15px;
  margin-left: 0;
}
@media only screen and (min-width: 650px) {
  [dir=rtl] .product-media {
    float: right;
  }
}
[dir=rtl] .product-header,
[dir=rtl] .product-offer {
  float: left;
}
@media only screen and (max-width: 650px) {
  [dir=rtl] .product-header,
  [dir=rtl] .product-offer {
    float: none;
  }
}
[dir=rtl] #page-container .save-percent-info .percentage {
  position: relative;
  right: 6px;
}
[dir=rtl] #page-container .save-percent-info .percentage .value {
  font-size: 1.3rem;
}
[dir=rtl] #page-container .save-percent-info .percentage .unit {
  font-size: 0.6rem;
}
[dir=rtl] #page-container .dynamic-product-info .save-percent-info .percentage {
  display: -webkit-flex;
  display: flex;
  place-content: center;
  right: 0;
  top: 0;
}

/* - - - - - - - - - - - - - - - - - - - - - - - - - - -
 * BLOG
 * - - - - - - - - - - - - - - - - - - - - - - - - - - -
 * */
.layout-1 .post-article {
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
}
.layout-1 .post-link,
.layout-1 .post-content {
  width: 100%;
}
.layout-1 .last {
  -webkit-flex: 1;
          flex: 1;
}

.layout-2 .first {
  -webkit-flex: 1;
          flex: 1;
}
.layout-2 .last .post-article {
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
}
.layout-2 .last .post-link,
.layout-2 .last .post-content {
  width: 100%;
}

.post-article {
  display: -webkit-flex;
  display: flex;
  margin-bottom: 20px;
}

.blog-list .post-content {
  margin-left: 20px;
  margin-right: 20px;
  margin-top: 0;
  word-wrap: break-word;
}
@media only screen and (max-width: 500px) {
  .blog-list .post-content {
    width: auto;
  }
}
.blog-list .post-title-preview {
  margin-top: 10px;
}
.blog-list .media-content {
  width: 370px;
}
.blog-list h4 {
  color: #222;
  margin: 0;
  font-weight: 300;
  text-transform: none;
  font-size: 1.33333em;
  line-height: 1.1em;
  padding: 1em 0 0.5em 0;
}

.postmeta-group {
  margin-top: auto;
}

.postmeta {
  font-weight: 300;
  font-size: 14px;
  margin: 15px 0 25px;
  text-align: center;
  letter-spacing: 0.03em;
}
.postmeta a {
  color: #373737;
  font-weight: 300;
  font-size: 0.9em;
  margin-right: 5px;
  opacity: 0.5;
}
.postmeta a:hover {
  text-decoration: underline;
  opacity: 1;
}
.postmeta a:last-child:after {
  content: "";
}
.postmeta a:after {
  content: ",";
}

.small {
  font-size: 0.75em;
  line-height: 1.5em;
}

.post-article a:hover,
.blog-item a:hover {
  text-decoration: none;
}
.post-article > a,
.blog-item > a {
  color: #373737;
}
.post-article .img-wrap,
.blog-item .img-wrap {
  text-align: center;
}
.post-article h2,
.blog-item h2 {
  margin-top: 0;
  line-height: 1.3em;
  font-size: 1.5rem;
}

.blog-widgets .widget {
  list-style: none;
  margin: 0;
}
.blog-widgets .widget li ul li {
  padding: 0;
  position: relative;
  margin: 0 0 1em 0;
  line-height: 1.2em;
}
.blog-widgets .widget li ul li a {
  text-decoration: none;
  display: inline-block;
}
.blog-widgets .widget .widget-title {
  margin-bottom: 0.5em;
}

.widget ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.widget-archive ul {
  margin: 0;
  padding: 0;
}

.toggle-year-archive {
  padding-left: 0.5em;
}

li.month a {
  padding-left: 1em;
}

.media-content {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
}

.blog-post .post-article {
  display: block;
}
.blog-post .post-media img {
  max-width: 88%;
}

.widget-title h4 {
  font-size: 1.25rem;
}

.post-content {
  padding: 10px;
  word-wrap: break-word;
}
.post-content a {
  color: rgb(55, 55, 55);
  font-weight: 300;
  text-decoration: none;
}

.post-top-info-list {
  font-size: 0.9em;
  letter-spacing: 0.05em;
  line-height: 1.2em;
  opacity: 0.5;
  padding-bottom: 10px;
}

#blog-page h1 .caption,
#blog-page h2 .caption,
#blog-page h3 .caption,
#blog-page h4 .caption,
#blog-page .slideshow-block .caption,
#blog-post-page h1 .caption,
#blog-post-page h2 .caption,
#blog-post-page h3 .caption,
#blog-post-page h4 .caption,
#blog-post-page .slideshow-block .caption {
  word-break: keep-all;
}
#blog-page .slideshow-media,
#blog-post-page .slideshow-media {
  margin-left: 0;
  margin-right: 0;
}

#blog-page .related-products-wrap,
#blog-post-page .related-products-wrap {
  margin-bottom: 20px;
}
#blog-page .related-products-wrap > h2,
#blog-post-page .related-products-wrap > h2 {
  line-height: 1.2em;
}

.block.blog-categories-block h4,
.block.blog-tags-block h4,
.block.blog-archive-block h4 {
  margin-top: 0;
}

.blog-latest-posts-block .section:before, .blog-latest-posts-block .section:after {
  content: none;
}
.blog-latest-posts-block .columns {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  margin-left: -20px;
  margin-top: 0;
  padding: 0;
}
.blog-latest-posts-block .columns:before, .blog-latest-posts-block .columns:after {
  content: none;
}
@media only screen and (max-width: 500px) {
  .blog-latest-posts-block .columns {
    margin-left: -10px;
    margin-bottom: 10px;
  }
}
.blog-latest-posts-block .columns .blog-item {
  background-color: white;
  list-style: none;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  margin-bottom: 20px;
  margin-left: 20px;
  width: calc(20% - 20px);
}
@media only screen and (max-width: 1200px) {
  .blog-latest-posts-block .columns .blog-item {
    width: calc(25% - 20px);
  }
}
@media only screen and (max-width: 768px) {
  .blog-latest-posts-block .columns .blog-item {
    width: calc(33.333% - 20px);
  }
}
@media only screen and (max-width: 500px) {
  .blog-latest-posts-block .columns .blog-item {
    width: calc(50% - 10px);
    margin-bottom: 10px;
    margin-left: 10px;
  }
}
.blog-latest-posts-block .columns .blog-item .preamble {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  letter-spacing: 1px;
  margin-top: 15px;
  line-height: 1.3em;
}
.blog-latest-posts-block .columns .blog-item .post-content {
  padding: 10px;
  word-wrap: break-word;
}

#blog-page .block.blog-categories-block, #blog-page .block.blog-tags-block, #blog-page .block.blog-archive-block {
  max-width: 1400px;
  margin-bottom: 30px;
}
#blog-page .block.blog-categories-block .widget-categories, #blog-page .block.blog-tags-block .widget-categories, #blog-page .block.blog-archive-block .widget-categories {
  font-size: 14px;
  margin-left: 6px;
}
#blog-page .block.blog-categories-block .widget-categories .category-item:hover, #blog-page .block.blog-tags-block .widget-categories .category-item:hover, #blog-page .block.blog-archive-block .widget-categories .category-item:hover {
  text-decoration: underline;
}
#blog-page .block.blog-categories-block .widget-tags, #blog-page .block.blog-tags-block .widget-tags, #blog-page .block.blog-archive-block .widget-tags {
  font-size: 14px;
  margin-left: 6px;
}
#blog-page .block.blog-categories-block .widget-tags .tag-item:hover, #blog-page .block.blog-tags-block .widget-tags .tag-item:hover, #blog-page .block.blog-archive-block .widget-tags .tag-item:hover {
  text-decoration: underline;
}
#blog-page .block.blog-categories-block .widget-archive, #blog-page .block.blog-tags-block .widget-archive, #blog-page .block.blog-archive-block .widget-archive {
  font-size: 14px;
  margin-left: 6px;
}
#blog-page .block.blog-categories-block .widget-archive .month:hover, #blog-page .block.blog-tags-block .widget-archive .month:hover, #blog-page .block.blog-archive-block .widget-archive .month:hover {
  text-decoration: underline;
}
#blog-page .layout {
  display: -webkit-flex;
  display: flex;
}
@media only screen and (max-width: 768px) {
  #blog-page .layout-1 {
    -webkit-flex-direction: column-reverse;
            flex-direction: column-reverse;
  }
}
#blog-page .layout-1 .blog-post-block {
  width: calc(100% - 20px);
  margin-left: 20px;
}
#blog-page .layout-1 .first {
  width: 20%;
}
@media only screen and (max-width: 768px) {
  #blog-page .layout-1 .first {
    width: 100%;
  }
}
#blog-page .layout-1 .first .blog-latest-posts-block .blog-item {
  width: calc(100% - 20px);
}
@media only screen and (max-width: 768px) {
  #blog-page .layout-1 .first .blog-latest-posts-block .blog-item {
    width: calc(33.333% - 20px);
  }
}
@media only screen and (max-width: 500px) {
  #blog-page .layout-1 .first .blog-latest-posts-block .blog-item {
    width: calc(50% - 10px);
  }
}
#blog-page .layout-1 .first .block.blog-categories-block,
#blog-page .layout-1 .first .block.blog-tags-block,
#blog-page .layout-1 .first .block.blog-archive-block {
  padding-left: 20px;
}
#blog-page .layout-1 .first .post-article {
  width: calc(100% - 20px);
}
#blog-page .layout-1 .last {
  width: calc(80% - 40px);
  margin-left: 40px;
}
@media only screen and (max-width: 768px) {
  #blog-page .layout-1 .last {
    width: 100%;
    margin-left: 0;
  }
}
#blog-page .layout-1 .last .blog-content {
  margin-left: -20px;
}
@media only screen and (max-width: 980px) {
  #blog-page .layout-1 .last .blog-latest-posts-block .blog-item {
    width: calc(33.333% - 20px);
  }
}
@media only screen and (max-width: 500px) {
  #blog-page .layout-1 .last .blog-latest-posts-block .blog-item {
    width: calc(50% - 10px);
  }
}
@media only screen and (max-width: 768px) {
  #blog-page .layout-2 {
    -webkit-flex-direction: column;
            flex-direction: column;
  }
}
#blog-page .layout-2 .blog-post-block {
  width: calc(100% - 20px);
  margin-left: 20px;
}
#blog-page .layout-2 .blog-content {
  margin-left: -20px;
}
#blog-page .layout-2 .first {
  width: calc(80% - 40px);
  margin-right: 40px;
}
@media only screen and (max-width: 768px) {
  #blog-page .layout-2 .first {
    width: 100%;
    margin-right: 0;
  }
}
@media only screen and (max-width: 980px) {
  #blog-page .layout-2 .first .blog-latest-posts-block .blog-item {
    width: calc(33.333% - 20px);
  }
}
@media only screen and (max-width: 500px) {
  #blog-page .layout-2 .first .blog-latest-posts-block .blog-item {
    width: calc(50% - 10px);
  }
}
#blog-page .layout-2 .last {
  width: 20%;
}
@media only screen and (max-width: 768px) {
  #blog-page .layout-2 .last {
    width: 100%;
  }
}
#blog-page .layout-2 .last .blog-latest-posts-block .blog-item {
  width: calc(100% - 20px);
}
@media only screen and (max-width: 768px) {
  #blog-page .layout-2 .last .blog-latest-posts-block .blog-item {
    width: calc(33.333% - 20px);
  }
}
@media only screen and (max-width: 500px) {
  #blog-page .layout-2 .last .blog-latest-posts-block .blog-item {
    width: calc(50% - 10px);
    margin-left: 10px;
    margin-bottom: 10px;
  }
}
#blog-page .layout-2 .last .block.blog-categories-block, #blog-page .layout-2 .last .block.blog-tags-block, #blog-page .layout-2 .last .block.blog-archive-block {
  padding-left: 20px;
}
#blog-page .layout-2 .last .post-article {
  width: calc(100% - 20px);
}
#blog-page .layout-2 .widget {
  padding-left: 0;
}
#blog-page .pagination-right {
  width: calc(100% - 20px);
  margin-left: 20px;
  margin-bottom: 20px;
}
#blog-page .blog-content {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  margin-top: 0;
  padding: 0;
  margin-left: -20px;
}
#blog-page .blog-content .block-title {
  width: calc(100% - 20px);
  margin: 0 0 20px 20px;
}
#blog-page .blog-content .block-title h2 {
  margin-top: 0;
}
#blog-page .post-article {
  background-color: white;
  -webkit-flex-direction: column;
          flex-direction: column;
  display: -webkit-flex;
  display: flex;
  margin-left: 20px;
  width: calc(33.333% - 20px);
}
@media only screen and (max-width: 1000px) {
  #blog-page .post-article {
    width: calc(50% - 20px);
  }
}
@media only screen and (max-width: 500px) {
  #blog-page .post-article {
    width: 100%;
    margin-bottom: 10px;
  }
}
#blog-page .post-article:hover {
  opacity: 0.9;
  box-shadow: 0 0 10px #D0D0D0;
  cursor: pointer;
}
#blog-page .preamble {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  letter-spacing: 1px;
  margin-top: 15px;
  line-height: 1.3em;
}
#blog-page .preamble .post-top-info-list {
  font-size: 0.9em;
  font-weight: 200;
  margin-bottom: 0;
  color: #D0D0D0;
  letter-spacing: 0.05em;
}
#blog-page .preamble .post-title-preview h2 {
  word-break: keep-all;
  margin-top: 10px;
  line-height: 1.2em;
}
#blog-page .layout-content > .block:first-child .widget-title h3 {
  margin-top: 0;
}

#blog-post-page .block.blog-categories-block, #blog-post-page .block.blog-tags-block, #blog-post-page .block.blog-archive-block {
  margin-bottom: 30px;
}
#blog-post-page .block.blog-categories-block h4, #blog-post-page .block.blog-tags-block h4, #blog-post-page .block.blog-archive-block h4 {
  margin-top: 0;
}
#blog-post-page .block.blog-categories-block .widget-categories, #blog-post-page .block.blog-tags-block .widget-categories, #blog-post-page .block.blog-archive-block .widget-categories {
  font-size: 14px;
  margin-left: 6px;
}
#blog-post-page .block.blog-categories-block .widget-categories .category-item:hover, #blog-post-page .block.blog-tags-block .widget-categories .category-item:hover, #blog-post-page .block.blog-archive-block .widget-categories .category-item:hover {
  text-decoration: underline;
}
#blog-post-page .block.blog-categories-block .widget-tags, #blog-post-page .block.blog-tags-block .widget-tags, #blog-post-page .block.blog-archive-block .widget-tags {
  font-size: 14px;
  margin-left: 6px;
}
#blog-post-page .block.blog-categories-block .widget-tags .tag-item:hover, #blog-post-page .block.blog-tags-block .widget-tags .tag-item:hover, #blog-post-page .block.blog-archive-block .widget-tags .tag-item:hover {
  text-decoration: underline;
}
#blog-post-page .block.blog-categories-block .widget-archive, #blog-post-page .block.blog-tags-block .widget-archive, #blog-post-page .block.blog-archive-block .widget-archive {
  font-size: 14px;
  margin-left: 6px;
}
#blog-post-page .block.blog-categories-block .widget-archive .month:hover, #blog-post-page .block.blog-tags-block .widget-archive .month:hover, #blog-post-page .block.blog-archive-block .widget-archive .month:hover {
  text-decoration: underline;
}
#blog-post-page .layout {
  display: -webkit-flex;
  display: flex;
}
@media only screen and (max-width: 768px) {
  #blog-post-page .layout-1 {
    -webkit-flex-direction: column-reverse;
            flex-direction: column-reverse;
  }
}
#blog-post-page .layout-1 .blog-post-block {
  width: 100%;
  margin-left: 0;
}
#blog-post-page .layout-1 .first {
  width: 20%;
}
@media only screen and (max-width: 768px) {
  #blog-post-page .layout-1 .first {
    width: 100%;
  }
}
#blog-post-page .layout-1 .first .blog-latest-posts-block .blog-item {
  width: calc(100% - 20px);
}
@media only screen and (max-width: 768px) {
  #blog-post-page .layout-1 .first .blog-latest-posts-block .blog-item {
    width: calc(33.333% - 20px);
  }
}
@media only screen and (max-width: 500px) {
  #blog-post-page .layout-1 .first .blog-latest-posts-block .blog-item {
    width: calc(50% - 10px);
    margin-left: 10px;
    margin-bottom: 10px;
  }
}
#blog-post-page .layout-1 .first .block.blog-categories-block,
#blog-post-page .layout-1 .first .block.blog-tags-block,
#blog-post-page .layout-1 .first .block.blog-archive-block {
  padding-left: 20px;
}
#blog-post-page .layout-1 .first .related-products-gallery .gallery-item,
#blog-post-page .layout-1 .first .bundled-products-gallery .gallery-item {
  width: calc(50% - 20px);
}
@media only screen and (max-width: 1200px) {
  #blog-post-page .layout-1 .first .related-products-gallery .gallery-item,
  #blog-post-page .layout-1 .first .bundled-products-gallery .gallery-item {
    width: calc(100% - 20px);
  }
}
@media only screen and (max-width: 768px) {
  #blog-post-page .layout-1 .first .related-products-gallery .gallery-item,
  #blog-post-page .layout-1 .first .bundled-products-gallery .gallery-item {
    width: calc(25% - 20px);
  }
}
@media only screen and (max-width: 500px) {
  #blog-post-page .layout-1 .first .related-products-gallery .gallery-item,
  #blog-post-page .layout-1 .first .bundled-products-gallery .gallery-item {
    width: calc(50% - 10px);
  }
}
#blog-post-page .layout-1 .last {
  width: calc(80% - 40px);
  margin-left: 40px;
}
#blog-post-page .layout-1 .last .blog-latest-posts-block .blog-item {
  width: calc(25% - 20px);
}
@media only screen and (max-width: 980px) {
  #blog-post-page .layout-1 .last .blog-latest-posts-block .blog-item {
    width: calc(33.333% - 20px);
  }
}
@media only screen and (max-width: 500px) {
  #blog-post-page .layout-1 .last .blog-latest-posts-block .blog-item {
    width: calc(50% - 10px);
  }
}
@media only screen and (max-width: 768px) {
  #blog-post-page .layout-1 .last {
    width: 100%;
    margin-left: 0;
  }
}
#blog-post-page .layout-1 .last .related-products-gallery .gallery-item,
#blog-post-page .layout-1 .last .bundled-products-gallery .gallery-item {
  width: calc(25% - 20px);
}
@media only screen and (max-width: 500px) {
  #blog-post-page .layout-1 .last .related-products-gallery .gallery-item,
  #blog-post-page .layout-1 .last .bundled-products-gallery .gallery-item {
    width: calc(50% - 10px);
  }
}
@media only screen and (max-width: 768px) {
  #blog-post-page .layout-2 {
    -webkit-flex-direction: column;
            flex-direction: column;
  }
}
#blog-post-page .layout-2 .blog-post-block {
  width: 100%;
}
#blog-post-page .layout-2 .first {
  width: calc(20% - 40px);
  margin-right: 40px;
}
@media only screen and (max-width: 768px) {
  #blog-post-page .layout-2 .first {
    width: 100%;
    margin-right: 0;
  }
}
#blog-post-page .layout-2 .first .blog-latest-posts-block .blog-item {
  width: calc(25% - 20px);
}
@media only screen and (max-width: 980px) {
  #blog-post-page .layout-2 .first .blog-latest-posts-block .blog-item {
    width: calc(33.333% - 20px);
  }
}
@media only screen and (max-width: 500px) {
  #blog-post-page .layout-2 .first .blog-latest-posts-block .blog-item {
    width: calc(50% - 10px);
  }
}
#blog-post-page .layout-2 .first .related-products-gallery .gallery-item,
#blog-post-page .layout-2 .first .bundled-products-gallery .gallery-item {
  width: calc(25% - 20px);
}
@media only screen and (max-width: 500px) {
  #blog-post-page .layout-2 .first .related-products-gallery .gallery-item,
  #blog-post-page .layout-2 .first .bundled-products-gallery .gallery-item {
    width: calc(50% - 10px);
  }
}
#blog-post-page .layout-2 .last {
  width: 20%;
}
@media only screen and (max-width: 768px) {
  #blog-post-page .layout-2 .last {
    width: 100%;
  }
}
#blog-post-page .layout-2 .last .blog-latest-posts-block .blog-item {
  width: calc(100% - 20px);
}
@media only screen and (max-width: 768px) {
  #blog-post-page .layout-2 .last .blog-latest-posts-block .blog-item {
    width: calc(33.333% - 20px);
  }
}
@media only screen and (max-width: 500px) {
  #blog-post-page .layout-2 .last .blog-latest-posts-block .blog-item {
    width: calc(50% - 10px);
  }
}
#blog-post-page .layout-2 .last .related-products-gallery .gallery-item,
#blog-post-page .layout-2 .last .bundled-products-gallery .gallery-item {
  width: calc(50% - 20px);
}
@media only screen and (max-width: 1200px) {
  #blog-post-page .layout-2 .last .related-products-gallery .gallery-item,
  #blog-post-page .layout-2 .last .bundled-products-gallery .gallery-item {
    width: calc(100% - 20px);
  }
}
@media only screen and (max-width: 768px) {
  #blog-post-page .layout-2 .last .related-products-gallery .gallery-item,
  #blog-post-page .layout-2 .last .bundled-products-gallery .gallery-item {
    width: calc(25% - 20px);
  }
}
@media only screen and (max-width: 500px) {
  #blog-post-page .layout-2 .last .related-products-gallery .gallery-item,
  #blog-post-page .layout-2 .last .bundled-products-gallery .gallery-item {
    width: calc(50% - 10px);
  }
}
#blog-post-page .layout-2 .last .block.blog-categories-block,
#blog-post-page .layout-2 .last .block.blog-tags-block,
#blog-post-page .layout-2 .last .block.blog-archive-block {
  padding-left: 20px;
}
#blog-post-page .blog-post-block {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
}
#blog-post-page .blog-post-block .blog-content {
  margin-left: 0;
  padding-left: 0;
  box-shadow: 0 0 4px #D0D0D0;
  background-color: white;
}
#blog-post-page .post-article {
  -webkit-justify-content: center;
          justify-content: center;
}
#blog-post-page .post-article .post-content {
  width: 100%;
  word-wrap: break-word;
  letter-spacing: 0.04em;
}
#blog-post-page .post-article .post-content .post-top-info {
  font-size: 1em;
  font-weight: 200;
  padding-bottom: 30px;
  padding-top: 20px;
  letter-spacing: 0.05em;
  opacity: 0.5;
}
#blog-post-page .post-article .post-content .entry-title {
  margin-bottom: 30px;
}
#blog-post-page .post-article .post-content .preamble {
  margin-top: 30px;
  margin-bottom: 30px;
}

#blog-post-page.has-sidebar .page-headline {
  margin-left: auto;
}

.blog-categories-block .blog-widgets a,
.blog-tags-block .blog-widgets a,
.blog-archive-block .blog-widgets a {
  color: rgb(55, 55, 55);
  text-decoration: none;
}
.blog-categories-block .blog-widgets ul,
.blog-tags-block .blog-widgets ul,
.blog-archive-block .blog-widgets ul {
  padding: 0;
  list-style-type: none;
}

.instagram-block .instagram-gallery {
  margin-top: 20px;
}

.udc {
  margin-top: 20px;
}

#udc-postalcode {
  font-weight: 600;
}

.unifaun-checkout-clip-box {
  overflow: hidden;
}

.unifaun-checkout-option-columns,
.unifaun-checkout-option-agent-panel {
  padding-left: 26px;
}

.unifaun-checkout-option-agent-info {
  padding-top: 10px;
}

.unifaun-checkout-option-columns {
  width: 100%;
}

.unifaun-checkout-option-column1 {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
}
@media only screen and (max-width: 500px) {
  .unifaun-checkout-option-column1 {
    -webkit-flex-direction: column;
            flex-direction: column;
  }
}

.unifaun-checkout-option-column1-text {
  -webkit-flex: 1;
          flex: 1;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
}
.unifaun-checkout-option-column1-text br {
  display: none;
}

.unifaun-checkout-option-delivery {
  opacity: 0.6;
  font-size: 0.9rem;
  line-height: 1.1em;
}

.unifaun-checkout-option-sub-column2 {
  margin-bottom: 10px;
}

.unifaun-checkout-text-input-input {
  border-style: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  outline: 0;
  border-radius: 0;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  background-color: transparent;
  width: 100%;
  position: relative;
  z-index: 2;
  font-size: 1rem;
  padding: 0.7rem 0.2rem 0 0;
  border-bottom: 1px solid #000;
  margin-bottom: 10px;
}

.unifaun-checkout-invalid .unifaun-checkout-text-input-input {
  margin-bottom: 20px;
}

.unifaun-checkout-option-agents-wrapper,
.unifaun-checkout-list-input {
  position: relative;
  width: 100%;
}
.unifaun-checkout-option-agents-wrapper:after,
.unifaun-checkout-list-input:after {
  position: absolute;
  content: "\e900";
  line-height: 40px;
  right: 0;
  bottom: 4px;
  width: 40px;
  height: 40px;
  text-align: center;
  color: #000;
  font-size: 22px;
  transition: color linear 0.2s;
  pointer-events: none;
}

.unifaun-checkout-root select {
  border-style: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  outline: 0;
  border-radius: 0;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  background-color: transparent;
  width: 100%;
  z-index: 2;
  font-size: 1rem;
  padding: 0.7rem 35px 0 0;
  border-bottom: 1px solid #000;
  margin-bottom: 10px;
}

.unifaun-checkout-addon-error {
  font-size: 0.9rem;
  opacity: 0.7;
  font-style: italic;
}

.unifaun-checkout-option-header0 {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  position: relative;
}
.unifaun-checkout-option-header0 .unifaun-checkout-option-radio {
  position: absolute;
  top: 2px;
  left: 0;
  display: -webkit-flex;
  display: flex;
}
.unifaun-checkout-option-header0 .unifaun-checkout-option-title {
  font-size: 1.1rem;
  line-height: 1.1em;
  padding-right: 1em;
  letter-spacing: 0.05em;
  margin-bottom: 4px;
}
.unifaun-checkout-option-header0 .unifaun-checkout-option-price {
  position: absolute;
  top: 0;
  right: 0;
  line-height: 1.1em;
  max-width: 60px;
}

.unifaun-checkout-option-suffix-icon {
  padding-right: 70px;
  padding-left: 26px;
}
@media only screen and (max-width: 500px) {
  .unifaun-checkout-option-suffix-icon {
    padding-right: 0;
    padding-left: 0;
    -webkit-order: -1;
            order: -1;
  }
}
.unifaun-checkout-option-suffix-icon img {
  max-width: 80px;
  max-height: 40px;
}

.unifaun-checkout-text-input-label {
  font-size: 0.7rem;
  top: 0;
  left: 0;
  position: absolute;
  z-index: 0;
  line-height: 1;
  transition: top 0.1s ease-in-out;
  opacity: 0.5;
}

.unifaun-checkout-option-fields-panel {
  padding-left: 20px;
}

.control input.highlight {
  background: rgba(249, 244, 183, 0.3);
}

.unifaun-checkout-option0 {
  border-bottom: 1px dotted silver;
  padding-bottom: 6px;
  padding-top: 10px;
}
.unifaun-checkout-option0 .unifaun-checkout-text-input,
.unifaun-checkout-option0 .unifaun-checkout-list-input {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  position: relative;
}
.unifaun-checkout-option0 .unifaun-checkout-option-field {
  margin-top: 10px;
}
.unifaun-checkout-option0 .unifaun-checkout-addon-label {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-align-items: center;
          align-items: center;
}
.unifaun-checkout-option0 .unifaun-checkout-addon-label .unifaun-checkout-addon-description {
  padding-left: 10px;
  padding-right: 10px;
}
.unifaun-checkout-option0 .unifaun-checkout-addon-label .unifaun-checkout-addon-price {
  margin-left: auto;
  margin-right: 0;
  -webkit-order: 10;
          order: 10;
}
.unifaun-checkout-option0 .unifaun-checkout-invalid .unifaun-checkout-text-input-label-message {
  -webkit-order: 100;
          order: 100;
  color: white;
  background-color: #d14b2c;
  padding: 4px 4px 3px;
  font-size: 0.7rem;
  line-height: 1.3em;
  margin: 0;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  width: auto;
}

.has-unifaun-delivery-checkout.klarna-checkout-v3-selected-payment-method .submit-wrap .section h2 {
  margin-top: 1em !important;
}
.has-unifaun-delivery-checkout .select-wrap > .payment-shipping-methods {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
}
.has-unifaun-delivery-checkout .select-wrap > .payment-shipping-methods .shipping-methods {
  -webkit-order: 0;
          order: 0;
  margin-top: 0;
}
.has-unifaun-delivery-checkout .select-wrap > .payment-shipping-methods .shipping-methods > div {
  margin-bottom: 20px;
}
.has-unifaun-delivery-checkout .select-wrap > .payment-shipping-methods .payment-methods {
  -webkit-order: 1;
          order: 1;
}

.theme-checkout-layout-0 .has-unifaun-delivery-checkout .shipping-methods .content {
  padding: 10px;
  background-color: #ffffff;
}
.theme-checkout-layout-0 .has-unifaun-delivery-checkout .addresses {
  margin-bottom: 20px;
}

/* - - - - - - - - - - - - - - - - - - - - - - - - - - -
 * PRODUCT
 * - - - - - - - - - - - - - - - - - - - - - - - - - - -
 * */
.product-header,
.product-offer {
  float: right;
  width: 47%;
}
@media only screen and (max-width: 650px) {
  .product-header,
  .product-offer {
    float: none;
    width: auto;
  }
}

@media only screen and (max-width: 650px) {
  .breadcrumbs {
    -webkit-order: -2;
            order: -2;
  }
  .dynamic-product-info {
    -webkit-order: -1;
            order: -1;
  }
}
@media only screen and (max-width: 800px) {
  .has-sidebar .product-header,
  .has-sidebar .product-offer {
    float: none;
    width: auto;
  }
}

.product-media {
  position: relative;
  margin-top: 20px;
  width: calc(53% - 30px);
  float: left;
}
.product-media img {
  display: block;
}
@media only screen and (max-width: 650px) {
  .product-media {
    float: none;
    width: auto;
  }
}

@media only screen and (max-width: 800px) {
  .has-sidebar .product-media {
    float: none;
    width: 100%;
  }
}

.product-media figure {
  position: relative;
  overflow: hidden;
}
.product-media figure:before {
  content: "";
  display: block;
  padding-top: 100%;
  height: 0;
  width: 100%;
}
.product-media figure .figure-content,
.product-media figure .img-wrap {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.product-media figure .img-wrap {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  -webkit-transform: translate(50%, 50%) translateZ(0);
          transform: translate(50%, 50%) translateZ(0);
}
.product-media figure img {
  position: absolute;
  display: inline-block;
  top: 0;
  left: 0;
  -webkit-transform: translate(-50%, -50%) translateZ(0);
          transform: translate(-50%, -50%) translateZ(0);
  max-width: 100%;
  max-height: 100%;
  width: auto;
  height: auto;
  -webkit-perspective: 1000;
  -webkit-backface-visibility: hidden;
}

.product-block-with-image-reset .product-media figure:before {
  display: none;
}
.product-block-with-image-reset .product-media figure .figure-content,
.product-block-with-image-reset .product-media figure .img-wrap {
  position: static;
  top: auto;
  left: auto;
  width: auto;
  height: auto;
}
.product-block-with-image-reset .product-media figure .img-wrap {
  display: block;
  text-align: center;
}
.product-block-with-image-reset .product-media figure .img-wrap,
.product-block-with-image-reset .product-media figure img {
  -webkit-transform: none;
          transform: none;
}
.product-block-with-image-reset .product-media figure img {
  position: static;
  top: auto;
  left: auto;
}

.product-overview {
  clear: both;
  padding-top: 20px;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  margin-left: 0;
}

#product-reviews {
  -webkit-order: 100;
          order: 100;
}

.product-header {
  margin-top: 20px;
}

.product-name {
  font-size: 2.4rem;
  line-height: 1.1em;
  margin-top: -0.3rem;
}

.product-short-description {
  margin-top: 30px;
}

.product-long-description, .product-short-description {
  word-wrap: break-word;
}
.product-long-description:empty, .product-short-description:empty {
  display: none;
}

@media only screen and (min-width: 1300px) {
  .product-media .save-percent-info {
    font-size: 35px;
  }
  .product-media .save-percent-info .percentage {
    margin-top: 0.75em;
  }
}

@media only screen and (min-width: 1300px) {
  .product-media .product-label span {
    font-size: 18px;
    line-height: 29px;
    padding-left: 30px;
    padding-right: 30px;
  }
  .product-media .product-label-0 {
    width: 175px;
    height: 175px;
  }
  .product-media .product-label-0 span {
    top: 60px;
    right: -46px;
    width: 240px;
  }
  .product-media .product-label-1 {
    width: 250px;
    height: 250px;
  }
  .product-media .product-label-1 span {
    top: 83px;
    right: -59px;
    width: 310px;
  }
}

.product-offer .product-price {
  margin-top: 30px;
  font-size: 1.8rem;
}

.add-to-cart-form {
  margin-top: 30px;
  max-width: 50%;
}
@media only screen and (max-width: 1300px) {
  .add-to-cart-form {
    max-width: none;
  }
}

.product-status-checkout-link {
  width: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
}
.product-status-checkout-link b {
  font-weight: normal;
  position: relative;
  padding-right: 26px;
}
.product-status-checkout-link b:after {
  content: "\f105";
  position: absolute;
  top: -8px;
  right: 0;
  font-size: 30px;
}
.product-status-checkout-link .cart-item-count {
  padding-right: 10px;
  -webkit-order: -1;
          order: -1;
}
.product-status-checkout-link .cart-item-count:before {
  content: "(";
}
.product-status-checkout-link .cart-item-count:after {
  content: ")";
}
.product-status-checkout-link .status-message {
  font-style: italic;
}

.desktop .product-status-checkout-link:hover {
  text-decoration: none;
}

.quantity-field .control {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
}
.quantity-field .control input {
  -webkit-flex: 1;
          flex: 1;
}
.quantity-field .unit {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: flex-end;
          align-items: flex-end;
  line-height: 1.8em;
}

.product-variants,
.quantity-field {
  margin-bottom: 20px;
}

.product-add-to-cart-action {
  padding-left: 35px;
  padding-right: 35px;
  width: 100%;
}
.product-add-to-cart-action.loading span {
  position: relative;
}
.product-add-to-cart-action.loading span:after {
  content: " ";
  border-radius: 50%;
  border: 2px solid #ffffff;
  border-top: 2px solid transparent;
  width: 16px;
  height: 16px;
  top: 3px;
  right: -30px;
  -webkit-animation: spin 1s linear infinite;
          animation: spin 1s linear infinite;
  display: block;
  position: absolute;
}

.product-status {
  display: none;
}

.product-details, .klarna-promotion-widget {
  font-size: 0.9rem;
  margin-top: 30px;
  margin-bottom: 0;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  max-width: 50%;
}
@media only screen and (max-width: 1300px) {
  .product-details, .klarna-promotion-widget {
    max-width: none;
  }
}
.product-details dt, .product-details dd, .klarna-promotion-widget dt, .klarna-promotion-widget dd {
  margin: 0;
  line-height: 1.3em;
}
.product-details dt, .klarna-promotion-widget dt {
  width: 40%;
  padding-right: 10px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.product-details dt:after, .klarna-promotion-widget dt:after {
  content: ":";
}
.product-details dd, .klarna-promotion-widget dd {
  width: 60%;
  text-align: right;
}

.product-details, #wasa-promotion-widget {
  font-size: 0.9rem;
  margin-top: 30px;
  margin-bottom: 0;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  max-width: 50%;
}
@media only screen and (max-width: 1300px) {
  .product-details, #wasa-promotion-widget {
    max-width: none;
  }
}
.product-details dt, .product-details dd, #wasa-promotion-widget dt, #wasa-promotion-widget dd {
  margin: 0;
  line-height: 1.3em;
}
.product-details dt, #wasa-promotion-widget dt {
  width: 40%;
  padding-right: 10px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.product-details dt:after, #wasa-promotion-widget dt:after {
  content: ":";
}
.product-details dd, #wasa-promotion-widget dd {
  width: 60%;
  text-align: right;
}

.bundled-product-fields .quantity-field {
  margin-bottom: 0;
}
.bundled-product-fields .remove-item-action {
  top: 0;
  right: 0;
}

.desktop .bundled-product-fields .remove-item-action:hover {
  background-color: transparent;
  color: #000;
}

.bundled-product {
  padding: 10px 10px 15px;
  background-color: #fff;
  margin-bottom: 10px;
  position: relative;
  display: -webkit-flex;
  display: flex;
}
.bundled-product select,
.bundled-product input[type=text],
.bundled-product input[type=number] {
  font-size: 0.9rem;
}
.bundled-product .select-type .control:after {
  line-height: 35px;
  right: 1px;
  top: 4px;
  width: 30px;
  height: 30px;
}
.bundled-product .bundled-product-quantity {
  margin-top: 10px;
  line-height: 1.1em;
  font-size: 0.9rem;
}
.bundled-product .bundled-product-quantity .unit {
  padding-left: 0.1em;
}

.bundled-product-image {
  width: 20%;
  text-align: center;
}

.bundled-product-options {
  margin-left: 10px;
  width: calc(80% - 10px);
}

.bundled-product-title {
  margin-top: 0;
  font-size: 1.1rem;
  line-height: 1.2em;
  padding-right: 25px;
}

.bundled-product-field-fixed-quantity {
  font-size: 0.9rem;
  padding-top: 0.8em;
}

.bundled-product-field + .bundled-product-field .bundled-product-field-fixed-quantity {
  padding-top: 1.3em;
}

.product-stock-locations-wrap {
  margin-top: 30px;
  margin-bottom: 30px;
  width: 50%;
}
.product-stock-locations-wrap .product-stock-location {
  display: -webkit-flex;
  display: flex;
  white-space: nowrap;
}
.product-stock-locations-wrap .product-stock-location .product-stock-location-name:after {
  content: ":";
}
.product-stock-locations-wrap .product-stock-locations {
  font-size: 0.9rem;
  margin-top: 0.5em;
  line-height: 1.1rem;
  overflow: hidden;
  text-overflow: ellipsis;
}
.product-stock-locations-wrap .product-stock-locations .product-stock-location-name {
  -webkit-flex: 1;
          flex: 1;
}
.product-stock-locations-wrap .product-stock-locations div:nth-child(n+6) {
  display: none;
}
.product-stock-locations-wrap .expand-product-stock-locations-button {
  font-size: 15px;
}
.product-stock-locations-wrap .expand-product-stock-locations-button .less {
  display: none;
}

.has-product-stock-locations-expanded .product-stock-locations div:nth-child(n+6) {
  display: -webkit-flex;
  display: flex;
}
.has-product-stock-locations-expanded .expand-product-stock-locations-button .more {
  display: none;
}
.has-product-stock-locations-expanded .expand-product-stock-locations-button .less {
  display: block;
}

.product-stock-status-4 span {
  padding-left: 15px;
  position: relative;
}
.product-stock-status-4 span:before {
  content: "•";
  display: block;
  position: absolute;
  top: 0px;
  left: 0px;
  font-size: 35px;
  color: #e20016;
  padding-right: 5px;
}

.product-stock-locations:not(.expanded) dl *:nth-child(n+10) {
  display: none;
}

.product-stock-locations.expanded button.expand-button {
  display: none;
}

.product-overview-header {
  display: none;
}

.product-offer h4,
.product-offer .product-rating {
  margin-top: 30px;
}

.product-documents {
  margin-top: 0.6em;
  margin-bottom: 1.2em;
}

.file-ext {
  display: none;
}

.file-size {
  opacity: 0;
  transition: opacity 0.5s ease;
}
.file-size:before {
  content: "(";
}
.file-size:after {
  content: ")";
}

.file-type {
  padding-left: 28px;
  position: relative;
  margin-bottom: 0.5rem;
  line-height: 1.2em;
}
.file-type a:before {
  content: attr(data-file-type);
  position: absolute;
  top: 0;
  left: 0;
  background-color: black;
  color: white;
  font-size: 10px;
  font-size: 0.6rem;
  padding: 0px 2px;
  text-transform: uppercase;
  font-family: arial, helvetica;
}

.desktop .file-type a:hover {
  text-decoration: none;
}
.desktop .file-type a:hover .file-size {
  opacity: 1;
  color: rgb(55, 55, 55);
}

.product-gallery-layout {
  margin: 10px 0;
}

.product-gallery-items {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  margin-left: -10px;
}

.product-gallery-item {
  margin-left: 10px;
  margin-bottom: 10px;
  width: calc(20% - 10px);
}
@media only screen and (max-width: 1200px) {
  .product-gallery-item {
    width: calc(33.3333333333% - 10px);
  }
}

.product-gallery-layout-3 .product-gallery-layout-last {
  display: none;
}

.product-gallery-layout-1 .product-gallery figure {
  margin-top: 10px;
  margin-bottom: 0;
}
.product-gallery-layout-1 .product-gallery-layout-first {
  display: none;
}
.product-gallery-layout-1 .product-gallery {
  -webkit-flex-direction: column;
          flex-direction: column;
}
.product-gallery-layout-1 .product-gallery .product-gallery-item {
  width: 100%;
}

.add-to-in-stock-notify {
  width: 100%;
  margin-top: 10px;
}

.add-to-in-stock-notify-button {
  margin-top: 10px;
  width: 100%;
}

.in-stock-notify-modal h3,
.in-stock-notify-modal .email-type {
  margin-top: 0;
}

.has-in-stock-notify-message .field-wrap {
  display: none;
}

.sw-modal-window .main .in-stock-notify-info,
.sw-modal-window .main .in-stock-notify-message {
  font-size: 0.8em;
  line-height: 1.4em;
  margin-top: 0.5em;
}

.customer-account-logged-in .in-stock-notify-modal .field-wrap,
.in-stock-notify-signed-up .field-wrap {
  display: none;
}

.product-media > figure {
  position: relative;
}

.stock-status-info {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  letter-spacing: 0.1em;
  background-color: rgba(0, 0, 0, 0.3);
  text-align: center;
  text-transform: uppercase;
  padding: 20px;
  font-size: 20px;
  font-size: 5vw;
  color: rgba(255, 255, 255, 0.85);
  line-height: 1em;
  pointer-events: none;
}

.product-bundle-items {
  margin-top: 20px;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  margin-left: -20px;
}

.product-bundle-item {
  width: calc(16.6666666667% - 20px);
  margin-left: 20px;
  display: -webkit-flex;
  display: flex;
  margin-bottom: 20px;
  position: relative;
}
@media only screen and (max-width: 1200px) {
  .product-bundle-item {
    width: calc(20% - 20px);
  }
}
@media only screen and (max-width: 850px) {
  .product-bundle-item {
    width: calc(25% - 20px);
  }
}
@media only screen and (max-width: 700px) {
  .product-bundle-item {
    width: calc(50% - 20px);
  }
}
@media only screen and (max-width: 500px) {
  .product-bundle-item {
    width: calc(50% - 10px);
  }
}
.product-bundle-item figure {
  position: relative;
  overflow: hidden;
}
.product-bundle-item figure:before {
  content: "";
  display: block;
  padding-top: 100%;
  height: 0;
  width: 100%;
}
.product-bundle-item figure .figure-content,
.product-bundle-item figure .img-wrap {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.product-bundle-item figure .img-wrap {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  -webkit-transform: translate(50%, 50%) translateZ(0);
          transform: translate(50%, 50%) translateZ(0);
}
.product-bundle-item figure img {
  position: absolute;
  display: inline-block;
  top: 0;
  left: 0;
  -webkit-transform: translate(-50%, -50%) translateZ(0);
          transform: translate(-50%, -50%) translateZ(0);
  max-width: 100%;
  max-height: 100%;
  width: auto;
  height: auto;
  -webkit-perspective: 1000;
  -webkit-backface-visibility: hidden;
}
.product-bundle-item h3 {
  margin-top: 0;
  line-height: 1.1em;
  font-size: 1.1rem;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.product-bundle-item .product-price {
  margin-top: 1rem;
  font-size: 1rem;
  text-align: center;
}

@media only screen and (max-width: 500px) {
  .product-bundle-items {
    margin-left: -10px;
  }
  .product-bundle-item {
    margin-left: 10px;
  }
}
.product-bundle-item-content {
  background-color: #fff;
  -webkit-flex: 1;
          flex: 1;
}

.product-bundle-link {
  display: block;
}

.product-bundle-link,
.desktop .product-bundle-link:hover {
  text-decoration: none;
  color: rgb(55, 55, 55);
}

.product-bundle-total {
  display: -webkit-flex;
  display: flex;
}
.product-bundle-total .button {
  font-size: 0.9rem;
  margin-top: 10px;
  width: 100%;
}
.product-bundle-total .product-price {
  font-size: 1.8rem;
}

.product-bundle-cta {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  position: relative;
  width: 100%;
  background-color: transparent;
  text-align: center;
}

.product-bundle-divider {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
}
.product-bundle-divider:after {
  background-color: #828282;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  color: #fff;
  text-align: center;
  line-height: 43px;
  font-size: 50px;
  left: -30px;
  position: absolute;
  z-index: 20;
  content: "+";
}

@media only screen and (max-width: 500px) {
  .product-bundle-divider:after {
    left: -25px;
  }
  .product-bundle-item:nth-child(3n+3) .product-bundle-divider:after, .product-bundle-item:nth-last-child(1):nth-child(odd) .product-bundle-divider:after {
    left: -10px;
  }
}
.product-bundle-item:first-child .product-bundle-divider:after {
  display: none;
}
.product-bundle-item:last-child .product-bundle-divider:after {
  content: "=";
}
.product-bundle-item .product-variants {
  padding-left: 20px;
  padding-right: 20px;
  margin-top: 10px;
  margin-bottom: 20px;
}
.product-bundle-item .product-variants legend {
  font-size: 0.9rem;
  opacity: 0.5;
}
.product-bundle-item .details {
  padding: 10px 10px 20px;
}
.product-bundle-item .product-sku {
  margin: 0.6em 0;
  font-family: "Courier New", Courier, monospace;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  display: none;
}
.product-bundle-item .short-description,
.product-bundle-item .product-sku,
.product-bundle-item .original-price {
  opacity: 0.5;
  font-size: 0.8rem;
  line-height: 1.2em;
}
.product-bundle-item .original-price {
  line-height: 1em;
}

.product-bundle .quantity {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  margin-top: 10px;
  margin-bottom: 10px;
}
.product-bundle .decrease-quantity-action,
.product-bundle .increase-quantity-action {
  background-color: transparent;
  border: 1px solid #6f6f6f;
  color: #333333;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  padding: 0;
  font-size: 13px;
  position: relative;
}
.product-bundle .decrease-quantity-action span,
.product-bundle .increase-quantity-action span {
  position: absolute;
  display: block;
  width: 100%;
  top: 4px;
}
.product-bundle .decrease-quantity-action span {
  margin-top: -1px;
}
.product-bundle .quantity-count {
  padding-left: 8px;
  padding-right: 8px;
}

.product-bundle-cta-content {
  background-color: white;
  width: 100%;
  padding: 70px 10px 10px;
  -webkit-flex: 1 0 auto;
          flex: 1 0 auto;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
}

.product-bundle-cta-text {
  width: 100%;
  line-height: 1.1em;
}

.bundled-product-quantity-info {
  position: absolute;
  overflow: hidden;
  bottom: -15px;
  left: 50%;
  margin-left: -15px;
  font-size: 1rem;
  background-color: #000;
  font-weight: 300;
  color: #fff;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
}
.bundled-product-quantity-info:before {
  content: "×";
  padding-right: 0.1rem;
  padding-top: 0.1rem;
  font-size: 0.8rem;
}

.bundle-price-save-amount {
  font-size: 0.8rem;
  opacity: 0.5;
  line-height: 1rem;
}

.volume-pricing {
  margin-top: 30px;
  max-width: 50%;
}
@media only screen and (max-width: 1300px) {
  .volume-pricing {
    max-width: none;
  }
}

.volume-pricing-content {
  display: none;
  padding-bottom: 0.7em;
}

.volume-pricing-header {
  position: relative;
  cursor: pointer;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
}

.toggle-volume-pricing-content {
  -webkit-align-self: center;
          align-self: center;
  width: 40px;
  text-align: center;
  transition: -webkit-transform 0.3s ease;
  transition: transform 0.3s ease;
  transition: transform 0.3s ease, -webkit-transform 0.3s ease;
  line-height: 1em;
  font-size: 0.9em;
}
.toggle-volume-pricing-content:after {
  content: "\e900";
  color: inherit;
  font-size: 22px;
}

.has-visible-volume-pricing-content .volume-pricing-content {
  display: block;
}
.has-visible-volume-pricing-content .toggle-volume-pricing-content {
  -webkit-transform: rotateX(180deg);
          transform: rotateX(180deg);
  -webkit-transform-origin: 50% 50%;
          transform-origin: 50% 50%;
}

.volume-pricing-row {
  display: -webkit-flex;
  display: flex;
  line-height: 1em;
  padding-top: 0.7em;
  padding-right: 0.7em;
  padding-left: 0.7em;
  position: relative;
}
.volume-pricing-row:after {
  position: absolute;
  content: "";
  right: 0;
  bottom: 0;
  left: 0;
  height: 1px;
  border-bottom: 1px dotted #000;
  opacity: 0.3;
}
.volume-pricing-row .volume {
  min-width: 4em;
}
.volume-pricing-row .price {
  margin-left: 1em;
}
.volume-pricing-row .percent {
  margin-left: auto;
}
.volume-pricing-row .unit,
.volume-pricing-row .divider,
.volume-pricing-row .currency {
  font-size: 0.6em;
  text-transform: uppercase;
}

.zero-percent {
  display: none;
}

/* - - - - - - - - - - - - - - - - - - - - - - - - - - -
 * CATEGORY
 * - - - - - - - - - - - - - - - - - - - - - - - - - - -
 * */
.category-block {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
}
.category-block .slideshow-media {
  -webkit-order: -1;
          order: -1;
}

.has-sidebar .category-description {
  margin-left: 0;
}

.category-block .category-block {
  display: block;
}

/* - - - - - - - - - - - - - - - - - - - - - - - - - - -
 * CHECKOUT
 * - - - - - - - - - - - - - - - - - - - - - - - - - - -
 * */
.apply-discount-code-action,
#discount-code-field,
.customer-info-form-partial #email-field {
  min-height: 47px;
}

.checkout-block {
  margin-top: 20px;
}

.custom-message {
  margin-bottom: 30px;
}
.custom-message:after {
  content: "";
  display: table;
  clear: both;
}

.login-action {
  width: 100%;
}

.account-type .caption,
.customer-type .caption,
.customer-info-form-partial legend {
  display: none;
}

.customer-type {
  margin-top: 20px;
}

.checkout-cart .cart-item,
.import-cart .cart-item,
.summary {
  border-bottom: 1px dotted silver;
  padding-bottom: 10px;
  margin-bottom: 10px;
}

.checkout-cart h3,
.import-cart h3,
.payment-shipping-methods h3 {
  font-size: 1.1rem;
  line-height: 1.2em;
  margin-top: 0;
}

.checkout-block h2 {
  margin-bottom: 0.2em;
  font-size: 1.5rem;
}

.checkout-cart h4,
.import-cart h4 {
  margin-top: 0;
  font-size: 0.7rem;
  opacity: 0.4;
}
.checkout-cart .image,
.import-cart .image {
  min-width: 100px;
}
.checkout-cart img,
.import-cart img {
  display: block;
}
.checkout-cart .title,
.import-cart .title {
  padding-left: 1em;
  -webkit-flex: 1 1 auto;
          flex: 1 1 auto;
}
@media only screen and (max-width: 400px) {
  .checkout-cart .title,
  .import-cart .title {
    padding-left: 0;
    padding-top: 10px;
  }
}
.checkout-cart .product-title,
.import-cart .product-title {
  display: block;
}
.checkout-cart .cart-item,
.import-cart .cart-item {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  position: relative;
  min-height: 75px;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
}
.checkout-cart .cart-item .price,
.import-cart .cart-item .price {
  margin-left: auto;
  font-size: 1.2rem;
}
.checkout-cart .cart-item .price-wrap,
.import-cart .cart-item .price-wrap {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
}
.checkout-cart .cart-item.error,
.import-cart .cart-item.error {
  padding-bottom: 30px;
}
.checkout-cart .price-wrap,
.import-cart .price-wrap {
  margin-left: auto;
}
.checkout-cart .total-price,
.import-cart .total-price {
  -webkit-order: 100;
          order: 100;
}
.checkout-cart .unit-price,
.import-cart .unit-price {
  -webkit-order: -1;
          order: -1;
  margin-right: 1em;
  margin-left: 1em;
}
.checkout-cart .unit-price .unit,
.import-cart .unit-price .unit {
  display: none;
}
.checkout-cart .unit-price,
.checkout-cart .quantity,
.checkout-cart .total-price,
.import-cart .unit-price,
.import-cart .quantity,
.import-cart .total-price {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
}
.checkout-cart .unit,
.import-cart .unit {
  padding-left: 0.1em;
}
.checkout-cart .increase-quantity-action,
.checkout-cart .decrease-quantity-action,
.import-cart .increase-quantity-action,
.import-cart .decrease-quantity-action {
  background-color: transparent;
  border: 1px solid #6f6f6f;
  color: #333333;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  padding: 0;
  font-size: 13px;
  position: relative;
}
.checkout-cart .increase-quantity-action span,
.checkout-cart .decrease-quantity-action span,
.import-cart .increase-quantity-action span,
.import-cart .decrease-quantity-action span {
  position: absolute;
  display: block;
  width: 100%;
  top: 4px;
}
.checkout-cart .decrease-quantity-action,
.import-cart .decrease-quantity-action {
  -webkit-order: -20;
          order: -20;
}
.checkout-cart .decrease-quantity-action span,
.import-cart .decrease-quantity-action span {
  margin-top: -1px;
}
.checkout-cart .remove-item-action,
.checkout-cart .remove-item,
.import-cart .remove-item-action,
.import-cart .remove-item {
  top: -10px;
  right: -10px;
}
.checkout-cart .quantity-field,
.import-cart .quantity-field {
  display: none;
}
.checkout-cart .quantity-count,
.import-cart .quantity-count {
  -webkit-order: -10;
          order: -10;
  margin: 0 0.5em;
}
@media only screen and (max-width: 700px) {
  .checkout-cart .cart-head,
  .checkout-cart .unit-price,
  .import-cart .cart-head,
  .import-cart .unit-price {
    display: none;
  }
  .checkout-cart .title,
  .import-cart .title {
    padding-right: 40px;
  }
  .checkout-cart .description,
  .import-cart .description {
    width: 100%;
    margin-bottom: 0.5em;
  }
  .checkout-cart .unit-price,
  .import-cart .unit-price {
    -webkit-flex: 1;
            flex: 1;
  }
}
.checkout-cart .label.error,
.import-cart .label.error {
  display: block;
  width: 100%;
  position: absolute;
  left: 0;
  bottom: 0;
}
.checkout-cart .label.error:before,
.import-cart .label.error:before {
  content: "↑";
  padding-right: 4px;
}
@media only screen and (max-width: 500px) {
  .checkout-cart .increase-quantity-action,
  .checkout-cart .decrease-quantity-action,
  .import-cart .increase-quantity-action,
  .import-cart .decrease-quantity-action {
    width: 40px;
    height: 40px;
    font-size: 26px;
  }
  .checkout-cart .increase-quantity-action span,
  .checkout-cart .decrease-quantity-action span,
  .import-cart .increase-quantity-action span,
  .import-cart .decrease-quantity-action span {
    top: 8px;
  }
}

.checkout-cart-wrap .info {
  line-height: 1.3;
  font-size: 0.9rem;
  border: 1px dotted silver;
  padding: 5px 10px;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-align-items: flex-start;
          align-items: flex-start;
}
.checkout-cart-wrap .info:before {
  content: "↑";
  padding-right: 6px;
}

.checkout-cart .unit-price,
.checkout-cart .quantity,
.checkout-cart .total-price {
  -webkit-align-items: center;
          align-items: center;
}
.checkout-cart .description {
  -webkit-flex: 1 0 auto;
          flex: 1 0 auto;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
}
@media only screen and (max-width: 400px) {
  .checkout-cart .description {
    display: block;
  }
}
@media only screen and (max-width: 700px) {
  .checkout-cart .cart-item {
    -webkit-flex-direction: column;
            flex-direction: column;
  }
}

.checkout-cart .bundled-products,
.order-thanks-details-block .bundled-products {
  margin-top: 30px;
}
.checkout-cart .bundled-product,
.order-thanks-details-block .bundled-product {
  display: -webkit-flex;
  display: flex;
  margin-top: 5px;
  padding: 0;
}
.checkout-cart .bundled-product-image,
.order-thanks-details-block .bundled-product-image {
  width: 50px;
}
.checkout-cart .bundled-product-quantity,
.order-thanks-details-block .bundled-product-quantity {
  margin-top: 0;
}
.checkout-cart .bundled-product-description,
.order-thanks-details-block .bundled-product-description {
  margin-left: 0.4em;
}
.checkout-cart .bundled-product-description h5,
.order-thanks-details-block .bundled-product-description h5 {
  font-size: 0.8rem;
  line-height: 1.1em;
  margin: 0;
  font-weight: 400;
}
.checkout-cart .bundled-product-description .variant:before,
.order-thanks-details-block .bundled-product-description .variant:before {
  content: ", " attr(data-title);
}
.checkout-cart .bundled-product-description p,
.order-thanks-details-block .bundled-product-description p {
  margin-top: 0.5em;
  font-size: 0.8rem;
  opacity: 0.4;
  line-height: 1.1em;
}
.checkout-cart .bundled-product-description .sku:before,
.order-thanks-details-block .bundled-product-description .sku:before {
  content: attr(data-title);
}
.checkout-cart .bundled-product-quantity,
.order-thanks-details-block .bundled-product-quantity {
  margin-left: auto;
  font-size: 0.8rem;
  line-height: 1.1em;
  padding-left: 0.5em;
}

.cart-head-items {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-justify-content: flex-end;
          justify-content: flex-end;
  opacity: 0.6;
}
.cart-head-items .description {
  -webkit-order: 1;
          order: 1;
  margin-right: auto;
}
.cart-head-items .unit-price {
  -webkit-order: 2;
          order: 2;
  min-width: 90px;
}
.cart-head-items .quantity {
  -webkit-order: 3;
          order: 3;
  min-width: 70px;
}
.cart-head-items .price {
  -webkit-order: 4;
          order: 4;
}

.checkout-cart .total-price,
.import-cart .total-price,
.cart-head-items .price {
  min-width: 8em;
  text-align: right;
}

@media only screen and (max-width: 400px) {
  .checkout-cart .cart-item .price-wrap,
  .import-cart .cart-item .price-wrap {
    -webkit-flex-direction: column;
            flex-direction: column;
    margin-left: 0;
  }
  .checkout-cart .quantity,
  .import-cart .quantity {
    -webkit-order: -1;
            order: -1;
  }
}
.summary {
  margin-top: 1em;
}
.summary p {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  margin: 0;
  line-height: 1.2em;
  opacity: 0.6;
  font-size: 0.9rem;
}
.summary p .price,
.summary p .weight {
  margin-left: auto;
}
.summary .grand-total {
  opacity: 1;
  font-size: 1.1rem;
  font-weight: 600;
  text-transform: uppercase;
}

.klarna-checkout-selected-payment-method .select-wrap .summary, .dibs-easy-selected-payment-method .select-wrap .summary, .svea-checkout-selected-payment-method .select-wrap .summary {
  display: none;
}
.klarna-checkout-selected-payment-method .submit-wrap, .dibs-easy-selected-payment-method .submit-wrap, .svea-checkout-selected-payment-method .submit-wrap {
  margin-top: 30px;
}

.toggle.discount-code {
  margin: 0;
}

.discount-code-content.two {
  display: none;
}
.discount-code-content.two.show {
  display: -webkit-flex !important;
  display: flex !important;
}

.group-items .db-cart-wrap:first-child {
  margin-left: 20px;
}

.group-items .discount-code-wrap {
  -webkit-order: -1;
          order: -1;
  margin-left: 0;
}

.discount-code-content.flex,
.theme-checkout-layout-2 .discount-code-content.flex {
  display: -webkit-flex;
  display: flex;
}

@media only screen and (max-width: 1400px) {
  .theme-checkout-layout-2 .group-items.two.discount-code-content {
    display: none;
  }
  .theme-checkout-layout-2 .group-items.two.discount-code-content.flex {
    display: -webkit-flex;
    display: flex;
  }
}
.customer-account-types,
.customer-type .control {
  display: -webkit-flex;
  display: flex;
  margin-left: -20px;
}

.customer-account-type,
.customer-type .control p {
  margin-left: 20px;
}

.get-external-customer-info {
  line-height: 1.1em;
  font-size: 0.7rem;
}
.get-external-customer-info .control {
  margin-top: 0.3em;
}
.get-external-customer-info button {
  width: 100%;
}

.payment-shipping-method .caption .price {
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  font-size: 1.2rem;
}

.payment-shipping-method {
  padding-top: 10px;
  padding-bottom: 10px;
  margin-bottom: 1px;
}
.payment-shipping-method h3 {
  line-height: 1.2em;
  position: relative;
}
.payment-shipping-method h3 input {
  position: absolute;
  top: 3px;
  left: 0;
}
.payment-shipping-method h3,
.payment-shipping-method .description {
  padding-left: 26px;
  padding-right: 58px;
}
.payment-shipping-method h3 label,
.payment-shipping-method .description label {
  display: -webkit-flex;
  display: flex;
}
@media only screen and (max-width: 500px) {
  .payment-shipping-method h3 label,
  .payment-shipping-method .description label {
    display: block;
  }
  .payment-shipping-method h3 label .figure,
  .payment-shipping-method h3 label .caption,
  .payment-shipping-method .description label .figure,
  .payment-shipping-method .description label .caption {
    display: block;
  }
}
.payment-shipping-method h3,
.payment-shipping-method .description,
.payment-shipping-method .payment-method-content-klarna {
  padding-left: 26px;
  padding-right: 70px;
}
.payment-shipping-method .figure {
  -webkit-order: 2;
          order: 2;
}
.payment-shipping-method img {
  max-width: 80px;
  max-height: 40px;
}
.payment-shipping-method .caption {
  -webkit-flex: 1;
          flex: 1;
  padding-right: 1em;
  line-height: 1.1em;
}
.payment-shipping-method .price.zero {
  display: none;
}
.payment-shipping-method i {
  display: block;
  font-size: 1rem;
  opacity: 0.7;
}
.payment-shipping-method .description,
.payment-shipping-method .payment-method-content-klarna {
  display: none;
  line-height: 1.1em;
  margin-top: 0.5em;
}
.payment-shipping-method.checked .caption {
  font-weight: 400;
}
.payment-shipping-method.checked .caption:before {
  display: none;
}
.payment-shipping-method.checked .description {
  display: block;
}
.payment-shipping-method.checked .payment-method-content-klarna {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
}
.payment-shipping-method.checked .payment-method-content-klarna:empty {
  display: none;
}

.payment-method-content-klarna {
  font-size: 0.9em;
}
.payment-method-content-klarna .payment-method-options {
  margin-right: 1em;
  margin-bottom: 0.5em;
  -webkit-flex: 1 0 auto;
          flex: 1 0 auto;
}
.payment-method-content-klarna .payment-method-options span {
  display: block;
  margin-bottom: 0.4em;
}
.payment-method-content-klarna .selected-option-info {
  background-color: #f1f1f1;
  padding: 1em;
  margin-bottom: 0.5em;
}
.payment-method-content-klarna .selected-option-info h4 {
  margin: 0 0 0.5em;
  letter-spacing: normal;
}
.payment-method-content-klarna .selected-option-info p {
  margin: 0 0 0.2em;
  font-size: 0.9em;
}

/* - - - - - - - - - - - - - - - - - - - - - - - - - - -
 * PRODUCT UPSELL GALLERY
 * - - - - - - - - - - - - - - - - - - - - - - - - - - -
 * */
.product-upsell-wrap {
  padding-bottom: 24px;
}

.product-upsell-slideshow {
  display: block;
  margin-left: 0;
}
@media only screen and (max-width: 500px) {
  .product-upsell-slideshow .gallery-item {
    margin-left: 20px;
    margin-bottom: 20px;
  }
}

.theme-checkout-layout-0 .gallery-layout-1.product-upsell-slideshow .gallery-item {
  width: calc(25% - 20px);
}
@media only screen and (max-width: 1200px) {
  .theme-checkout-layout-0 .gallery-layout-1.product-upsell-slideshow .gallery-item {
    width: calc(33.3333333333% - 20px);
  }
}
@media only screen and (max-width: 850px) {
  .theme-checkout-layout-0 .gallery-layout-1.product-upsell-slideshow .gallery-item {
    width: calc(50% - 20px);
  }
}
.theme-checkout-layout-0 .gallery-layout-3.product-upsell-slideshow .gallery-item {
  width: calc(16.6666666667% - 20px);
}
@media only screen and (max-width: 1200px) {
  .theme-checkout-layout-0 .gallery-layout-3.product-upsell-slideshow .gallery-item {
    width: calc(25% - 20px);
  }
}
@media only screen and (max-width: 850px) {
  .theme-checkout-layout-0 .gallery-layout-3.product-upsell-slideshow .gallery-item {
    width: calc(33.3333333333% - 20px);
  }
}
@media only screen and (max-width: 500px) {
  .theme-checkout-layout-0 .gallery-layout-3.product-upsell-slideshow .gallery-item {
    width: calc(50% - 10px);
  }
}
.theme-checkout-layout-0 .gallery-layout-4 .gallery-item:first-child:nth-last-child(7), .theme-checkout-layout-0 .gallery-layout-4 .gallery-item:first-child:nth-last-child(7) ~ li {
  width: calc(14.2857142857% - 20px);
}
@media only screen and (max-width: 1200px) {
  .theme-checkout-layout-0 .gallery-layout-4 .gallery-item:first-child:nth-last-child(7), .theme-checkout-layout-0 .gallery-layout-4 .gallery-item:first-child:nth-last-child(7) ~ li {
    width: calc(16.6666666667% - 20px);
  }
}
@media only screen and (max-width: 850px) {
  .theme-checkout-layout-0 .gallery-layout-4 .gallery-item:first-child:nth-last-child(7), .theme-checkout-layout-0 .gallery-layout-4 .gallery-item:first-child:nth-last-child(7) ~ li {
    width: calc(25% - 20px);
  }
}
@media only screen and (max-width: 500px) {
  .theme-checkout-layout-0 .gallery-layout-4 .gallery-item:first-child:nth-last-child(7), .theme-checkout-layout-0 .gallery-layout-4 .gallery-item:first-child:nth-last-child(7) ~ li {
    width: calc(50% - 20px);
  }
}
.theme-checkout-layout-0 .gallery-layout-4.product-upsell-slideshow .gallery-item {
  width: calc(14.2857142857% - 20px);
}
@media only screen and (max-width: 1200px) {
  .theme-checkout-layout-0 .gallery-layout-4.product-upsell-slideshow .gallery-item {
    width: calc(16.6666666667% - 20px);
  }
}
@media only screen and (max-width: 850px) {
  .theme-checkout-layout-0 .gallery-layout-4.product-upsell-slideshow .gallery-item {
    width: calc(33.3333333333% - 20px);
  }
}
@media only screen and (max-width: 500px) {
  .theme-checkout-layout-0 .gallery-layout-4.product-upsell-slideshow .gallery-item {
    width: calc(50% - 10px);
  }
}

.theme-checkout-layout-1 .gallery-layout-1 .gallery-item:first-child:nth-last-child(1) {
  width: calc(100% - 20px);
}
.theme-checkout-layout-1 .gallery-layout-1 .gallery-item:first-child:nth-last-child(2), .theme-checkout-layout-1 .gallery-layout-1 .gallery-item:first-child:nth-last-child(2) ~ li {
  width: calc(50% - 20px);
}
.theme-checkout-layout-1 .gallery-layout-1.product-upsell-slideshow .gallery-item {
  width: calc(50% - 20px);
}
@media only screen and (max-width: 1200px) {
  .theme-checkout-layout-1 .gallery-layout-1.product-upsell-slideshow .gallery-item {
    width: calc(100% - 20px);
  }
}
.theme-checkout-layout-1 .gallery-layout-3 .gallery-item:first-child:nth-last-child(1) {
  width: calc(100% - 20px);
}
.theme-checkout-layout-1 .gallery-layout-3 .gallery-item:first-child:nth-last-child(2), .theme-checkout-layout-1 .gallery-layout-3 .gallery-item:first-child:nth-last-child(2) ~ li {
  width: calc(50% - 20px);
}
.theme-checkout-layout-1 .gallery-layout-3 .gallery-item:first-child:nth-last-child(3), .theme-checkout-layout-1 .gallery-layout-3 .gallery-item:first-child:nth-last-child(3) ~ li {
  width: calc(33.3333333333% - 20px);
}
@media only screen and (max-width: 1580px) {
  .theme-checkout-layout-1 .gallery-layout-3 .gallery-item:first-child:nth-last-child(3), .theme-checkout-layout-1 .gallery-layout-3 .gallery-item:first-child:nth-last-child(3) ~ li {
    width: calc(50% - 20px);
  }
}
.theme-checkout-layout-1 .gallery-layout-3.product-upsell-slideshow .gallery-item {
  width: calc(33.3333333333% - 20px);
}
@media only screen and (max-width: 1300px) {
  .theme-checkout-layout-1 .gallery-layout-3.product-upsell-slideshow .gallery-item {
    width: calc(50% - 20px);
  }
}
.theme-checkout-layout-1 .gallery-layout-4 .gallery-item:first-child:nth-last-child(1) {
  width: calc(100% - 20px);
}
.theme-checkout-layout-1 .gallery-layout-4 .gallery-item:first-child:nth-last-child(2), .theme-checkout-layout-1 .gallery-layout-4 .gallery-item:first-child:nth-last-child(2) ~ li {
  width: calc(50% - 20px);
}
.theme-checkout-layout-1 .gallery-layout-4 .gallery-item:first-child:nth-last-child(3), .theme-checkout-layout-1 .gallery-layout-4 .gallery-item:first-child:nth-last-child(3) ~ li, .theme-checkout-layout-1 .gallery-layout-4 .gallery-item:first-child:nth-last-child(4), .theme-checkout-layout-1 .gallery-layout-4 .gallery-item:first-child:nth-last-child(4) ~ li {
  width: calc(33.3333333333% - 20px);
}
@media only screen and (max-width: 1580px) {
  .theme-checkout-layout-1 .gallery-layout-4 .gallery-item:first-child:nth-last-child(3), .theme-checkout-layout-1 .gallery-layout-4 .gallery-item:first-child:nth-last-child(3) ~ li, .theme-checkout-layout-1 .gallery-layout-4 .gallery-item:first-child:nth-last-child(4), .theme-checkout-layout-1 .gallery-layout-4 .gallery-item:first-child:nth-last-child(4) ~ li {
    width: calc(50% - 20px);
  }
}
.theme-checkout-layout-1 .gallery-layout-4.product-upsell-slideshow .gallery-item {
  width: calc(25% - 20px);
}
@media only screen and (max-width: 1300px) {
  .theme-checkout-layout-1 .gallery-layout-4.product-upsell-slideshow .gallery-item {
    width: calc(33.3333333333% - 20px);
  }
}
@media only screen and (max-width: 1000px) {
  .theme-checkout-layout-1 .gallery-layout-4.product-upsell-slideshow .gallery-item {
    width: calc(50% - 20px);
  }
}

.theme-checkout-layout-2 .gallery-layout-1 .gallery-item:first-child:nth-last-child(1) {
  width: calc(100% - 20px);
}
.theme-checkout-layout-2 .gallery-layout-1.product-upsell-slideshow .gallery-item {
  width: calc(100% - 20px);
}
.theme-checkout-layout-2 .gallery-layout-3 .gallery-item:first-child:nth-last-child(1) {
  width: calc(100% - 20px);
}
.theme-checkout-layout-2 .gallery-layout-3 .gallery-item:first-child:nth-last-child(2), .theme-checkout-layout-2 .gallery-layout-3 .gallery-item:first-child:nth-last-child(2) ~ li {
  width: calc(50% - 20px);
}
.theme-checkout-layout-2 .gallery-layout-3.product-upsell-slideshow .gallery-item {
  width: calc(50% - 20px);
}
@media only screen and (max-width: 1250px) {
  .theme-checkout-layout-2 .gallery-layout-3.product-upsell-slideshow .gallery-item {
    width: calc(100% - 20px);
  }
}
@media only screen and (max-width: 1000px) {
  .theme-checkout-layout-2 .gallery-layout-3.product-upsell-slideshow .gallery-item {
    width: calc(50% - 20px);
  }
}
.theme-checkout-layout-2 .gallery-layout-4 .gallery-item:first-child:nth-last-child(1) {
  width: calc(100% - 20px);
}
.theme-checkout-layout-2 .gallery-layout-4 .gallery-item:first-child:nth-last-child(2), .theme-checkout-layout-2 .gallery-layout-4 .gallery-item:first-child:nth-last-child(2) ~ li {
  width: calc(50% - 20px);
}
.theme-checkout-layout-2 .gallery-layout-4 .gallery-item:first-child:nth-last-child(3), .theme-checkout-layout-2 .gallery-layout-4 .gallery-item:first-child:nth-last-child(3) ~ li {
  width: calc(33.3333333333% - 20px);
}
@media only screen and (max-width: 1580px) {
  .theme-checkout-layout-2 .gallery-layout-4 .gallery-item:first-child:nth-last-child(3), .theme-checkout-layout-2 .gallery-layout-4 .gallery-item:first-child:nth-last-child(3) ~ li {
    width: calc(50% - 20px);
  }
}
.theme-checkout-layout-2 .gallery-layout-4.product-upsell-slideshow .gallery-item {
  width: calc(33.3333333333% - 20px);
}
@media only screen and (max-width: 1650px) {
  .theme-checkout-layout-2 .gallery-layout-4.product-upsell-slideshow .gallery-item {
    width: calc(50% - 20px);
  }
}
@media only screen and (max-width: 1200px) {
  .theme-checkout-layout-2 .gallery-layout-4.product-upsell-slideshow .gallery-item {
    width: calc(100% - 20px);
  }
}
@media only screen and (max-width: 700px) {
  .theme-checkout-layout-2 .gallery-layout-4.product-upsell-slideshow .gallery-item {
    width: calc(50% - 20px);
  }
}

/* - - - - - - - - - - - - - - - - - - - - - - - - - - -
 * CHECKOUT LAYOUT
 * - - - - - - - - - - - - - - - - - - - - - - - - - - -
 * */
.theme-checkout-layout-1 h2, .theme-checkout-layout-1 h3,
.theme-checkout-layout-2 h2,
.theme-checkout-layout-2 h3 {
  margin-top: 0;
}
.theme-checkout-layout-1 .klarna-checkout-v3-selected-payment-method h2,
.theme-checkout-layout-2 .klarna-checkout-v3-selected-payment-method h2 {
  margin-top: 1em;
}
.theme-checkout-layout-1 .checkout-block form,
.theme-checkout-layout-2 .checkout-block form {
  display: -webkit-flex;
  display: flex;
  margin-left: -30px;
}
.theme-checkout-layout-1 .cart,
.theme-checkout-layout-1 .info-wrap,
.theme-checkout-layout-2 .cart,
.theme-checkout-layout-2 .info-wrap {
  margin-left: 30px;
}

@media only screen and (max-width: 1000px) {
  .theme-checkout-layout-2 .info-wrap {
    border: 1px dotted silver;
  }
}
@media only screen and (max-width: 600px) {
  .group-items .discount-code-wrap {
    -webkit-order: 1;
            order: 1;
  }
  .group-items .db-cart-wrap:first-child {
    margin-left: 0;
  }
  .db-cart-message,
  .toggle.discount-code a {
    display: block;
    text-align: left;
    line-height: 2.5em;
  }
}
.theme-checkout-layout-1 .cart,
.theme-checkout-layout-1 .info-wrap {
  padding: 20px;
}

.theme-checkout-layout-2 .cart,
.theme-checkout-layout-2 .addresses,
.theme-checkout-layout-2 .select-wrap {
  padding: 20px;
}

.theme-checkout-layout-1 .cart,
.theme-checkout-layout-1 .info-wrap,
.theme-checkout-layout-2 .cart,
.theme-checkout-layout-2 .addresses,
.theme-checkout-layout-2 .select-wrap {
  background-color: #fff;
}

.theme-checkout-layout-1 .payment-shipping-method,
.theme-checkout-layout-2 .payment-shipping-method {
  border-bottom: 1px dotted silver;
}

.theme-checkout-layout-0 .checkout-cart .cart-item {
  border-style: none;
}
.theme-checkout-layout-0 .checkout-cart .remove-item-action {
  top: 0;
  right: 0;
}
.theme-checkout-layout-0 .checkout-cart .cart-item,
.theme-checkout-layout-0 .customer-info-form-partial,
.theme-checkout-layout-0 .payment-shipping-method {
  background-color: #fff;
  padding-left: 10px;
  padding-right: 10px;
}
.theme-checkout-layout-0 .checkout-cart .cart-item,
.theme-checkout-layout-0 .customer-info-form-partial {
  padding-top: 10px;
  padding-bottom: 10px;
}

.theme-checkout-layout-1 .group-items .discount-code-wrap {
  -webkit-order: 1;
          order: 1;
}
.theme-checkout-layout-1 .discount-code-db-cart-wrap .group-items.two {
  -webkit-flex-direction: column;
          flex-direction: column;
}
.theme-checkout-layout-1 .discount-code-db-cart-wrap .group-items .group-item {
  margin-left: 0;
}
.theme-checkout-layout-1 .product-upsell-wrap,
.theme-checkout-layout-1 .select-wrap,
.theme-checkout-layout-1 .shipping-methods {
  margin-top: 30px;
}
.theme-checkout-layout-1 .checkout-cart .quantity {
  min-width: 70px;
}
.theme-checkout-layout-1 .checkout-cart .unit-price {
  min-width: 90px;
}
@media only screen and (max-width: 1280px) {
  .theme-checkout-layout-1 .cart-item {
    -webkit-flex-direction: column;
            flex-direction: column;
  }
  .theme-checkout-layout-1 .cart-head,
  .theme-checkout-layout-1 .unit-price {
    display: none;
  }
  .theme-checkout-layout-1 .description {
    width: 100%;
    margin-bottom: 0.5em;
  }
  .theme-checkout-layout-1 .title {
    padding-right: 40px;
  }
}
.theme-checkout-layout-1 .cart,
.theme-checkout-layout-1 .info-wrap {
  width: calc(50% - 30px);
}
@media only screen and (max-width: 700px) {
  .theme-checkout-layout-1 .cart,
  .theme-checkout-layout-1 .info-wrap {
    width: auto;
    margin-left: 0;
  }
}
@media only screen and (max-width: 700px) {
  .theme-checkout-layout-1 .checkout-block form {
    -webkit-flex-direction: column;
            flex-direction: column;
    margin-left: 0;
  }
}
.theme-checkout-layout-1 .checkout-cart .cart-item .description,
.theme-checkout-layout-1 .import-cart .cart-item .description {
  -webkit-flex-shrink: 1;
          flex-shrink: 1;
}

.theme-checkout-layout-2 .product-upsell-wrap,
.theme-checkout-layout-2 .shipping-methods {
  margin-top: 30px;
}
.theme-checkout-layout-2 .group-items .discount-code-wrap {
  -webkit-order: 1;
          order: 1;
}
.theme-checkout-layout-2 .page-headline,
.theme-checkout-layout-2 .checkout-block {
  max-width: none;
}
.theme-checkout-layout-2 .cart {
  width: 32.5%;
}
.theme-checkout-layout-2 .info-wrap {
  width: 67.5%;
  display: -webkit-flex;
  display: flex;
  padding: 0;
}
.theme-checkout-layout-2 .addresses {
  width: calc(50% - 15px);
}
.theme-checkout-layout-2 .select-wrap {
  width: calc(50% - 15px);
  margin-left: 30px;
}
.theme-checkout-layout-2 .checkout-cart .cart-head,
.theme-checkout-layout-2 .checkout-cart .unit-price {
  display: none;
}
.theme-checkout-layout-2 .checkout-cart .title {
  padding-right: 40px;
}
.theme-checkout-layout-2 .checkout-cart .cart-item {
  -webkit-flex-direction: column;
          flex-direction: column;
}
.theme-checkout-layout-2 .checkout-cart .description {
  margin-bottom: 0.5em;
}
.theme-checkout-layout-2 .checkout-cart .unit-price {
  -webkit-flex: 1;
          flex: 1;
}
.theme-checkout-layout-2 .discount-code-db-cart-wrap .group-items.two,
.theme-checkout-layout-2 .discount-code-content {
  -webkit-flex-direction: column;
          flex-direction: column;
}
.theme-checkout-layout-2 .discount-code-db-cart-wrap .group-items .group-item {
  margin-left: 0;
}
.theme-checkout-layout-2 .shipping-methods {
  margin-top: 30px;
}
.theme-checkout-layout-2 .select-payment-methods-hidden .shipping-methods {
  margin-top: 0;
}
@media only screen and (max-width: 1400px) {
  .theme-checkout-layout-2 .group-items.two {
    display: block;
  }
  .theme-checkout-layout-2 .group-items .group-item {
    margin-left: 0;
  }
}
@media only screen and (max-width: 1000px) {
  .theme-checkout-layout-2 .info-wrap {
    -webkit-flex-direction: column;
            flex-direction: column;
  }
  .theme-checkout-layout-2 .cart,
  .theme-checkout-layout-2 .info-wrap {
    width: calc(50% - 30px);
  }
  .theme-checkout-layout-2 .addresses,
  .theme-checkout-layout-2 .select-wrap {
    width: auto;
    margin-left: 0;
    border-style: none;
  }
}
@media only screen and (max-width: 700px) {
  .theme-checkout-layout-2 .checkout-block form {
    -webkit-flex-direction: column;
            flex-direction: column;
    margin-left: 0;
  }
  .theme-checkout-layout-2 .cart,
  .theme-checkout-layout-2 .info-wrap,
  .theme-checkout-layout-2 .select-wrap {
    width: auto;
    margin-left: 0;
  }
}

.select-list-adresses .toggle-favorite-address,
.select-list-adresses .icon-selected-address {
  position: absolute;
  display: block;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
}
.select-list-adresses .icon-selected-address {
  display: none;
}
.select-list-adresses .select-list-option-selected .icon-selected-address {
  display: -webkit-flex;
  display: flex;
}
.select-list-adresses .select-list-option-selected .icon-selected-address:before {
  content: "\e909";
}
.select-list-adresses .toggle-favorite-address {
  top: 4px;
  right: 10px;
  font-size: 20px;
  width: 30px;
  height: 30px;
}
.select-list-adresses .icon-selected-address {
  top: 11px;
  left: 10px;
  font-size: 14px;
  width: 15px;
  height: 15px;
}
.select-list-adresses .favorite .toggle-favorite-address:before {
  content: "\f005";
}
.select-list-adresses .select-list-option-extended {
  display: none;
}
.select-list-adresses .toggle-favorite-address:before {
  content: "\e908";
}
.select-list-adresses .select-list-option {
  position: relative;
  padding-left: 30px;
  padding-right: 50px;
}
.select-list-adresses .select-list-option-text {
  display: block;
}
.select-list-adresses.select-list-show-extended-list-options .select-list-option-extended {
  display: block;
}
.select-list-adresses.select-list-show-extended-list-options .show-extended-list-options {
  display: none;
}

.desktop .select-list-adresses .favorite .toggle-favorite-address:hover {
  cursor: pointer;
}
.desktop .select-list-adresses .favorite .toggle-favorite-address:hover:before {
  content: "\e908";
}
.desktop .select-list-adresses .non-favorite .toggle-favorite-address:hover {
  cursor: pointer;
}
.desktop .select-list-adresses .non-favorite .toggle-favorite-address:hover:before {
  content: "\f005";
}

.touch .select-list-adresses .select-list-option {
  padding-left: 38px;
}
.touch .select-list-adresses .icon-selected-address {
  font-size: 16px;
  top: 4px;
  left: 8px;
  width: 30px;
  height: 30px;
}
.touch .icon-selected-address {
  display: -webkit-flex;
  display: flex;
}
.touch .icon-selected-address:before {
  content: "\e90a";
}
.touch .select-list-option-selected .icon-selected-address:before {
  content: "\e90b";
}

#shipping-methods-checkout h4 {
  margin-left: 5px;
}
#shipping-methods-checkout .button {
  background: rgba(0, 0, 0, 0.5);
}
#shipping-methods-checkout .button:not(.disabled):hover, #shipping-methods-checkout .button.active {
  background: rgb(0, 0, 0);
}
#shipping-methods-checkout .shipping-method-type-selector {
  display: -webkit-flex;
  display: flex;
  margin-top: 10px;
}
#shipping-methods-checkout .shipping-method-type-selector .shipping-method-type-button {
  padding: 14px;
  font-size: 0.95rem;
  -webkit-flex: 1;
          flex: 1;
  margin-left: 10px;
  letter-spacing: 0.05em;
}
#shipping-methods-checkout .shipping-method-type-selector .shipping-method-type-button:first-of-type {
  margin-left: 0;
}
#shipping-methods-checkout .shipping-method-type-selector .shipping-method-type-button i {
  position: absolute;
  background-color: transparent;
  padding: 0;
  font-size: 18px;
  margin: -5px 0px 0px 5px;
}
#shipping-methods-checkout .control {
  margin-top: 15px;
}

.shipping-methods .deliver-home .payment-shipping-method,
.shipping-methods .click-and-collect .payment-shipping-method {
  padding: 18px 10px 15px 10px;
  padding-bottom: 15px;
}

.klarna-checkout-v3-selected-payment-method .submit-wrap .section h2 {
  margin-top: 0 !important;
}
.klarna-checkout-v3-selected-payment-method .submit-wrap .content {
  border: 1px dotted silver;
}
.klarna-checkout-v3-selected-payment-method .submit-wrap .shipping-method-type-selector {
  display: -webkit-flex;
  display: flex;
  margin-top: 10px;
  margin-bottom: 20px;
}
.klarna-checkout-v3-selected-payment-method .submit-wrap .shipping-method-type-selector .button {
  background: rgba(0, 0, 0, 0.5);
}
.klarna-checkout-v3-selected-payment-method .submit-wrap .shipping-method-type-selector .button:not(.disabled):hover, .klarna-checkout-v3-selected-payment-method .submit-wrap .shipping-method-type-selector .button.active {
  background: rgb(0, 0, 0);
  font-weight: 600;
}
.klarna-checkout-v3-selected-payment-method .submit-wrap .shipping-method-type-selector .shipping-method-type-button {
  padding: 15px;
  -webkit-flex: 1;
          flex: 1;
  z-index: 1;
  position: relative;
  font-family: "Klarna Text", "Klarna Sans", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  text-rendering: geometricprecision;
  font-size: 15px;
  letter-spacing: -0.2px;
  transition: none;
  border-radius: 0;
  text-transform: none;
}
.klarna-checkout-v3-selected-payment-method .submit-wrap .shipping-method-type-selector .shipping-method-type-button:first-of-type {
  margin-right: 7px;
}
.klarna-checkout-v3-selected-payment-method .submit-wrap .shipping-method-type-selector .shipping-method-type-button i {
  position: absolute;
  top: 11px;
  background-color: transparent;
  margin-left: 5px;
  padding: 0;
  font-size: 18px;
}

/* - - - - - - - - - - - - - - - - - - - - - - - - - - -
 * CUSTOMER PAGES
 * - - - - - - - - - - - - - - - - - - - - - - - - - - -
 * */
.account-login-options {
  margin-top: 10px;
  padding-top: 1em;
}

.customer-page .page-headline {
  padding-left: 0;
  padding-right: 0;
}

.customer-info-block {
  margin-top: 30px;
}

.customer-page-navigation {
  margin-top: 30px;
}
.customer-page-navigation ul {
  list-style: none;
  margin: 0;
  padding: 0;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  margin-left: -10px;
}
.customer-page-navigation li {
  text-transform: uppercase;
  font-size: 0.9rem;
}
@media only screen and (max-width: 650px) {
  .customer-page-navigation li {
    width: 50%;
    margin-bottom: 10px;
  }
}
.customer-page-navigation a, .customer-page-navigation span {
  background-color: rgba(255, 255, 255, 0.4);
  padding: 1rem 1rem;
  display: block;
  margin-left: 10px;
  text-align: center;
}
.customer-page-navigation .current a, .customer-page-navigation .current span {
  background-color: #fff;
}
.customer-page-navigation .logout {
  margin-left: auto;
}
.customer-page-navigation .logout a {
  background-color: transparent;
}
@media only screen and (max-width: 650px) {
  .customer-page-navigation .logout a {
    background-color: rgba(255, 255, 255, 0.5);
  }
}

.desktop .customer-page-navigation a:hover {
  text-decoration: none;
  background-color: #fff;
}

#orders-page:lang(ar) .customer-content th {
  text-align: right;
}

.customer-content {
  background-color: #fff;
  padding: 30px;
}
@media only screen and (max-width: 500px) {
  .customer-content {
    padding-left: 10px;
    padding-right: 10px;
  }
}
.customer-content table {
  width: 100%;
}
.customer-content th {
  text-align: left;
}

.order-document-products {
  margin-top: 40px;
}

#customer-info-page #customer-info-form {
  background-color: transparent;
  padding: 0;
}

#customer-info-page .customer-info-form-partial {
  background-color: #fff;
  padding: 30px;
}

.order-document-info {
  margin-top: 30px;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
}
@media only screen and (max-width: 650px) {
  .order-document-info {
    -webkit-flex-direction: column;
            flex-direction: column;
  }
}
.order-document-info .column-left,
.order-document-info .column-right {
  -webkit-flex: 1;
          flex: 1;
}

.order-details,
.order-documents,
.invoice-address,
.delivery-address {
  list-style: none;
  margin-left: 0;
  margin-top: 0;
  margin-bottom: 0;
  padding: 0;
}
.order-details li,
.order-documents li,
.invoice-address li,
.delivery-address li {
  line-height: 1.1em;
  padding-bottom: 0.2em;
}
.order-details h3,
.order-documents h3,
.invoice-address h3,
.delivery-address h3 {
  margin-top: 0;
}

.order-document-reference,
.order-documents {
  margin-top: 1em;
}

.order-document-title {
  font-size: 1.7rem;
}

#customer-info-page .order-document-order-info,
#customer-info-page .invoice-address,
#customer-info-page .delivery-address {
  margin-bottom: 30px;
}

.order-document-products a,
.customer-orders-table a,
.customer-child-customers-table a {
  display: block;
}
.order-document-products .quantity,
.order-document-products .vat-rate,
.order-document-products .unit-price,
.order-document-products .total-price,
.customer-orders-table .quantity,
.customer-orders-table .vat-rate,
.customer-orders-table .unit-price,
.customer-orders-table .total-price,
.customer-child-customers-table .quantity,
.customer-child-customers-table .vat-rate,
.customer-child-customers-table .unit-price,
.customer-child-customers-table .total-price {
  text-align: right;
}
.order-document-products tr,
.customer-orders-table tr,
.customer-child-customers-table tr {
  border-bottom: 1px solid #ECECEC;
}
.order-document-products tr:before,
.customer-orders-table tr:before,
.customer-child-customers-table tr:before {
  display: none;
}
.order-document-products th,
.order-document-products td,
.customer-orders-table th,
.customer-orders-table td,
.customer-child-customers-table th,
.customer-child-customers-table td {
  padding-left: 1em;
  line-height: 1.2em;
  vertical-align: top;
}
.order-document-products .product-description,
.customer-orders-table .product-description,
.customer-child-customers-table .product-description {
  padding-left: 0;
}
@media only screen and (max-width: 650px) {
  .order-document-products thead,
  .customer-orders-table thead,
  .customer-child-customers-table thead {
    display: none;
  }
  .order-document-products tr,
  .customer-orders-table tr,
  .customer-child-customers-table tr {
    border-bottom: 3px double #ECECEC;
  }
  .order-document-products td,
  .customer-orders-table td,
  .customer-child-customers-table td {
    position: relative;
    display: block;
    text-align: right;
    font-size: 0.8rem;
    line-height: 1.5em;
    padding-left: 100px;
  }
  .order-document-products td:before,
  .customer-orders-table td:before,
  .customer-child-customers-table td:before {
    content: attr(data-title) ": ";
    text-transform: none;
    position: absolute;
    left: 0;
  }
  .order-document-products td:first-child,
  .customer-orders-table td:first-child,
  .customer-child-customers-table td:first-child {
    font-size: 1rem;
    text-align: left;
    padding-top: 1em;
    padding-bottom: 0.2em;
    font-weight: 600;
    padding-left: 0;
  }
  .order-document-products td:first-child .product-description-title,
  .customer-orders-table td:first-child .product-description-title,
  .customer-child-customers-table td:first-child .product-description-title {
    font-weight: 600;
  }
  .order-document-products td:first-child:before,
  .customer-orders-table td:first-child:before,
  .customer-child-customers-table td:first-child:before {
    display: none;
  }
  .order-document-products td:last-child,
  .customer-orders-table td:last-child,
  .customer-child-customers-table td:last-child {
    padding-bottom: 1em;
  }
}

.order-document-summary {
  margin-top: 1em;
}
.order-document-summary p {
  margin: 0;
  line-height: 1.3em;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-justify-content: flex-end;
          justify-content: flex-end;
}
.order-document-summary .price {
  min-width: 8em;
  text-align: right;
}
.order-document-summary .order-document-grand-total {
  font-size: 1rem;
  margin-top: 0.5em;
  font-weight: 600;
}

.customer-child-customers-table td:first-child {
  font-weight: inherit;
}
.customer-child-customers-table h4 {
  font-weight: bold;
  margin-top: 0;
}
.customer-child-customers-table a {
  display: inline;
}
.customer-child-customers-table .customer-orders-table th, .customer-child-customers-table .customer-orders-table td {
  padding-left: 0;
}
@media only screen and (max-width: 1000px) {
  .customer-child-customers-table .invoice-adress-column,
  .customer-child-customers-table .delivery-adress-column {
    display: none;
  }
}

.add-child-customer-button {
  width: 100%;
}

.delete-child-customer-action.button {
  background: transparent;
  color: rgb(203, 102, 77);
}
.delete-child-customer-action.button:hover {
  background: rgb(203, 102, 77);
  color: #ffffff;
}

.child-customer-details {
  background-color: #f7f7f7;
}
.child-customer-details td {
  padding-bottom: 10px;
}
.child-customer-details .column-content {
  padding-top: 10px;
  padding-right: 10px;
}
.child-customer-details .last-column {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: flex-end;
          align-items: flex-end;
  -webkit-justify-content: space-between;
          justify-content: space-between;
}
.child-customer-details .last-column .button {
  margin-top: 1em;
  font-size: 0.9rem;
}
@media only screen and (max-width: 650px) {
  .child-customer-details td:first-child {
    padding-left: 10px;
  }
}

.child-customer-details-list {
  display: grid;
  grid-template-columns: -webkit-max-content auto;
  grid-template-columns: max-content auto;
  grid-column-gap: 1em;
  grid-row-gap: 2px;
  -webkit-align-items: start;
          align-items: start;
  line-height: 1.2em;
  margin-bottom: 1em;
}
@media only screen and (max-width: 650px) {
  .child-customer-details-list {
    font-size: 0.8rem;
  }
}
.child-customer-details-list br {
  display: none;
}
.child-customer-details-list .label {
  opacity: 0.6;
}

.password-recovery,
.password-reset-content {
  max-width: 1400px;
  margin-left: auto;
  margin-right: auto;
}
.password-recovery button,
.password-reset-content button {
  margin-top: 1rem;
}

#terms-page.is-in-iframe #tb-container,
#terms-page.is-in-iframe #admin-toolbar-phantom,
#terms-page.is-in-iframe #site-header,
#terms-page.is-in-iframe #site-header-phantom {
  display: none;
}

#swish-payment-page .admin-toolbar,
#swish-payment-page .admin-toolbar-phantom,
#swish-payment-page .site-header,
#swish-payment-page .site-header-phantom, #swish-payment-page.has-fixed-site-header #site-header-phantom {
  display: none;
}
#swish-payment-page #content {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
}

#swish-payment .page-headline {
  word-break: normal;
}
#swish-payment button {
  position: static;
  top: auto;
  right: auto;
  width: auto;
  margin: 20px auto 0;
  font-size: 1.3em;
}

.confirm-swish-mobile-number {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
}
.confirm-swish-mobile-number .control {
  position: static;
  top: auto;
  left: auto;
  margin-top: 0;
  width: auto;
}
.confirm-swish-mobile-number input[type=tel] {
  font-size: 1.9em;
  padding: 0.3em;
}

.product-items {
  margin-top: 0.5em;
}

.product-item {
  display: grid;
  grid-template-columns: 100px auto 90px 90px 90px;
  grid-gap: 10px;
  padding: 10px;
  background-color: #fff;
  margin-bottom: 10px;
}
@media only screen and (max-width: 700px) {
  .product-item {
    grid-template-columns: 100px auto auto auto;
  }
}
.product-item .price {
  font-size: 1.2rem;
}

.product-image {
  text-align: center;
}

.product-items-header {
  background-color: transparent;
  padding: 0 10px;
  line-height: 1.2em;
  opacity: 0.6;
  margin-bottom: 5px;
}
.product-items-header .product-image {
  text-align: left;
  margin-left: -10px;
}
@media only screen and (max-width: 700px) {
  .product-items-header {
    display: none;
  }
}

.product-title a {
  color: rgb(55, 55, 55);
}
.product-title h3 {
  margin-top: 0;
  font-size: 1.1rem;
  line-height: 1.2em;
}
.product-title p {
  margin-top: 0;
  opacity: 0.4;
  font-size: 0.7rem;
  line-height: 1.2em;
  letter-spacing: 0.05em;
}
.product-title .weight:before,
.product-title .sku:before {
  content: ", " attr(data-title);
  padding-right: 4px;
}
.product-title .weight:first-of-type:before,
.product-title .sku:first-of-type:before {
  content: attr(data-title);
}
@media only screen and (max-width: 700px) {
  .product-title {
    grid-column: 2/5;
  }
}

.desktop .product-title a:hover {
  text-decoration: none;
}

.product-unit-price .unit {
  display: none;
}
@media only screen and (max-width: 700px) {
  .product-unit-price {
    grid-column: 1/2;
  }
}

.product-unit-price,
.product-quantity,
.product-total-price {
  text-align: right;
}

@media only screen and (max-width: 700px) {
  .product-total-price {
    grid-column: 3/5;
  }
}

.order-summary-items {
  border-bottom: 1px dotted silver;
  padding-top: 10px;
  padding-bottom: 10px;
  margin-bottom: 10px;
}

.order-summary-item {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  line-height: 1.2em;
  opacity: 0.6;
  font-size: 0.9rem;
}

.order-summary-grand-total {
  opacity: 1;
  font-size: 1.1rem;
  line-height: 1.6em;
  font-weight: 600;
  text-transform: uppercase;
}

.order-discount-code .caption:after {
  content: ":";
  padding-right: 4px;
}
.order-discount-code .code {
  font-weight: 600;
}

.theme-color-scheme-1.theme-layout-boxed #page-container,
.theme-color-scheme-2.theme-layout-boxed #page-container,
.theme-color-scheme-3.theme-layout-boxed #page-container,
.theme-color-scheme-4.theme-layout-boxed #page-container,
.theme-color-scheme-5.theme-layout-boxed #page-container,
.theme-color-scheme-6.theme-layout-boxed #page-container,
.theme-color-scheme-7.theme-layout-boxed #page-container {
  background-color: rgba(255, 255, 255, 0.4);
}
.theme-color-scheme-1 .header-item-2,
.theme-color-scheme-2 .header-item-2,
.theme-color-scheme-3 .header-item-2,
.theme-color-scheme-4 .header-item-2,
.theme-color-scheme-5 .header-item-2,
.theme-color-scheme-6 .header-item-2,
.theme-color-scheme-7 .header-item-2 {
  margin-top: 6px;
  margin-bottom: 6px;
}
.theme-color-scheme-1 .site-header .menu-block-with-mega-dropdown-menu .menu-level-2-item,
.theme-color-scheme-2 .site-header .menu-block-with-mega-dropdown-menu .menu-level-2-item,
.theme-color-scheme-3 .site-header .menu-block-with-mega-dropdown-menu .menu-level-2-item,
.theme-color-scheme-4 .site-header .menu-block-with-mega-dropdown-menu .menu-level-2-item,
.theme-color-scheme-5 .site-header .menu-block-with-mega-dropdown-menu .menu-level-2-item,
.theme-color-scheme-6 .site-header .menu-block-with-mega-dropdown-menu .menu-level-2-item,
.theme-color-scheme-7 .site-header .menu-block-with-mega-dropdown-menu .menu-level-2-item {
  width: auto;
}
@-webkit-keyframes bounce {
  0%, 20%, 53%, 80%, 100% {
    transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
    -webkit-transform-style: preserve-3d;
            transform-style: preserve-3d;
    box-shadow: 0 0 0 rgba(0, 0, 0, 0.9);
  }
  40%, 43% {
    transition-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    -webkit-transform: translate3d(0, -30px, 0);
            transform: translate3d(0, -30px, 0);
    -webkit-transform-style: preserve-3d;
            transform-style: preserve-3d;
    box-shadow: 0 30px 30px rgba(0, 0, 0, 0.01);
  }
  70% {
    transition-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    -webkit-transform: translate3d(0, -15px, 0);
            transform: translate3d(0, -15px, 0);
    -webkit-transform-style: preserve-3d;
            transform-style: preserve-3d;
    box-shadow: 0 15px 15px rgba(0, 0, 0, 0.5);
  }
  90% {
    -webkit-transform: translate3d(0, -4px, 0);
            transform: translate3d(0, -4px, 0);
    -webkit-transform-style: preserve-3d;
            transform-style: preserve-3d;
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.7);
  }
}
@keyframes bounce {
  0%, 20%, 53%, 80%, 100% {
    transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
    -webkit-transform-style: preserve-3d;
            transform-style: preserve-3d;
    box-shadow: 0 0 0 rgba(0, 0, 0, 0.9);
  }
  40%, 43% {
    transition-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    -webkit-transform: translate3d(0, -30px, 0);
            transform: translate3d(0, -30px, 0);
    -webkit-transform-style: preserve-3d;
            transform-style: preserve-3d;
    box-shadow: 0 30px 30px rgba(0, 0, 0, 0.01);
  }
  70% {
    transition-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    -webkit-transform: translate3d(0, -15px, 0);
            transform: translate3d(0, -15px, 0);
    -webkit-transform-style: preserve-3d;
            transform-style: preserve-3d;
    box-shadow: 0 15px 15px rgba(0, 0, 0, 0.5);
  }
  90% {
    -webkit-transform: translate3d(0, -4px, 0);
            transform: translate3d(0, -4px, 0);
    -webkit-transform-style: preserve-3d;
            transform-style: preserve-3d;
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.7);
  }
}
@media only screen and (max-width: 500px) {
  .theme-color-scheme-1 .gallery h3,
  .theme-color-scheme-1 .shopping-cart-item h3,
  .theme-color-scheme-2 .gallery h3,
  .theme-color-scheme-2 .shopping-cart-item h3,
  .theme-color-scheme-3 .gallery h3,
  .theme-color-scheme-3 .shopping-cart-item h3,
  .theme-color-scheme-4 .gallery h3,
  .theme-color-scheme-4 .shopping-cart-item h3,
  .theme-color-scheme-5 .gallery h3,
  .theme-color-scheme-5 .shopping-cart-item h3,
  .theme-color-scheme-6 .gallery h3,
  .theme-color-scheme-6 .shopping-cart-item h3,
  .theme-color-scheme-7 .gallery h3,
  .theme-color-scheme-7 .shopping-cart-item h3 {
    font-weight: 400;
  }
}
.theme-color-scheme-1 .fullwidth-image-block,
.theme-color-scheme-2 .fullwidth-image-block,
.theme-color-scheme-3 .fullwidth-image-block,
.theme-color-scheme-4 .fullwidth-image-block,
.theme-color-scheme-5 .fullwidth-image-block,
.theme-color-scheme-6 .fullwidth-image-block,
.theme-color-scheme-7 .fullwidth-image-block {
  padding-left: 30px;
  padding-right: 30px;
}
@media only screen and (max-width: 500px) {
  .theme-color-scheme-1 .fullwidth-image-block,
  .theme-color-scheme-2 .fullwidth-image-block,
  .theme-color-scheme-3 .fullwidth-image-block,
  .theme-color-scheme-4 .fullwidth-image-block,
  .theme-color-scheme-5 .fullwidth-image-block,
  .theme-color-scheme-6 .fullwidth-image-block,
  .theme-color-scheme-7 .fullwidth-image-block {
    padding-left: 0;
    padding-right: 0;
  }
}
.theme-color-scheme-1 .filter-group-closed,
.theme-color-scheme-2 .filter-group-closed,
.theme-color-scheme-3 .filter-group-closed,
.theme-color-scheme-4 .filter-group-closed,
.theme-color-scheme-5 .filter-group-closed,
.theme-color-scheme-6 .filter-group-closed,
.theme-color-scheme-7 .filter-group-closed {
  padding-bottom: 0;
}
.theme-color-scheme-1 .filter-group-closed .filter-group-title,
.theme-color-scheme-2 .filter-group-closed .filter-group-title,
.theme-color-scheme-3 .filter-group-closed .filter-group-title,
.theme-color-scheme-4 .filter-group-closed .filter-group-title,
.theme-color-scheme-5 .filter-group-closed .filter-group-title,
.theme-color-scheme-6 .filter-group-closed .filter-group-title,
.theme-color-scheme-7 .filter-group-closed .filter-group-title {
  margin: 0;
  background-color: #fff;
}
.theme-color-scheme-1 .remove-item-action,
.theme-color-scheme-1 .pswp__button,
.theme-color-scheme-2 .remove-item-action,
.theme-color-scheme-2 .pswp__button,
.theme-color-scheme-3 .remove-item-action,
.theme-color-scheme-3 .pswp__button,
.theme-color-scheme-4 .remove-item-action,
.theme-color-scheme-4 .pswp__button,
.theme-color-scheme-5 .remove-item-action,
.theme-color-scheme-5 .pswp__button,
.theme-color-scheme-6 .remove-item-action,
.theme-color-scheme-6 .pswp__button,
.theme-color-scheme-7 .remove-item-action,
.theme-color-scheme-7 .pswp__button {
  background-color: transparent;
}
.theme-color-scheme-1 .review-rating,
.theme-color-scheme-1 .add-review-comment,
.theme-color-scheme-2 .review-rating,
.theme-color-scheme-2 .add-review-comment,
.theme-color-scheme-3 .review-rating,
.theme-color-scheme-3 .add-review-comment,
.theme-color-scheme-4 .review-rating,
.theme-color-scheme-4 .add-review-comment,
.theme-color-scheme-5 .review-rating,
.theme-color-scheme-5 .add-review-comment,
.theme-color-scheme-6 .review-rating,
.theme-color-scheme-6 .add-review-comment,
.theme-color-scheme-7 .review-rating,
.theme-color-scheme-7 .add-review-comment {
  background-color: white;
}
.theme-color-scheme-1 .slideshow-media .figure,
.theme-color-scheme-2 .slideshow-media .figure,
.theme-color-scheme-3 .slideshow-media .figure,
.theme-color-scheme-4 .slideshow-media .figure,
.theme-color-scheme-5 .slideshow-media .figure,
.theme-color-scheme-6 .slideshow-media .figure,
.theme-color-scheme-7 .slideshow-media .figure {
  height: 0;
  overflow: hidden;
  position: relative;
  padding-bottom: 55%;
}
@media only screen and (min-width: 700px) {
  .theme-color-scheme-1 .slideshow-media .figure,
  .theme-color-scheme-2 .slideshow-media .figure,
  .theme-color-scheme-3 .slideshow-media .figure,
  .theme-color-scheme-4 .slideshow-media .figure,
  .theme-color-scheme-5 .slideshow-media .figure,
  .theme-color-scheme-6 .slideshow-media .figure,
  .theme-color-scheme-7 .slideshow-media .figure {
    padding-bottom: 45%;
  }
  .theme-color-scheme-1 .slideshow-media .figure img,
  .theme-color-scheme-2 .slideshow-media .figure img,
  .theme-color-scheme-3 .slideshow-media .figure img,
  .theme-color-scheme-4 .slideshow-media .figure img,
  .theme-color-scheme-5 .slideshow-media .figure img,
  .theme-color-scheme-6 .slideshow-media .figure img,
  .theme-color-scheme-7 .slideshow-media .figure img {
    width: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
  }
}
@media only screen and (min-width: 1000px) {
  .theme-color-scheme-1 .slideshow-media .figure,
  .theme-color-scheme-2 .slideshow-media .figure,
  .theme-color-scheme-3 .slideshow-media .figure,
  .theme-color-scheme-4 .slideshow-media .figure,
  .theme-color-scheme-5 .slideshow-media .figure,
  .theme-color-scheme-6 .slideshow-media .figure,
  .theme-color-scheme-7 .slideshow-media .figure {
    padding-bottom: 32%;
  }
}
@media only screen and (max-width: 700px) {
  .theme-color-scheme-1 .slideshow-media .figure,
  .theme-color-scheme-2 .slideshow-media .figure,
  .theme-color-scheme-3 .slideshow-media .figure,
  .theme-color-scheme-4 .slideshow-media .figure,
  .theme-color-scheme-5 .slideshow-media .figure,
  .theme-color-scheme-6 .slideshow-media .figure,
  .theme-color-scheme-7 .slideshow-media .figure {
    height: auto;
    padding-bottom: 0;
    overflow: visible;
  }
}

.theme-color-scheme-2 .site-header,
.theme-color-scheme-2 .site-footer,
.theme-color-scheme-2 #content,
.theme-color-scheme-3 .site-header,
.theme-color-scheme-3 .site-footer,
.theme-color-scheme-3 #content,
.theme-color-scheme-4 .site-header,
.theme-color-scheme-4 .site-footer,
.theme-color-scheme-4 #content,
.theme-color-scheme-6 .site-header,
.theme-color-scheme-6 .site-footer,
.theme-color-scheme-6 #content,
.theme-color-scheme-7 .site-header,
.theme-color-scheme-7 .site-footer,
.theme-color-scheme-7 #content {
  max-width: 1400px;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  background-color: rgba(255, 255, 255, 0.4);
}
.theme-color-scheme-2 .site-header.fixed-site-header,
.theme-color-scheme-3 .site-header.fixed-site-header,
.theme-color-scheme-4 .site-header.fixed-site-header,
.theme-color-scheme-6 .site-header.fixed-site-header,
.theme-color-scheme-7 .site-header.fixed-site-header {
  padding-left: 0;
  padding-right: 0;
}
.theme-color-scheme-2 .block-fullwidth,
.theme-color-scheme-2 .slideshow-block,
.theme-color-scheme-3 .block-fullwidth,
.theme-color-scheme-3 .slideshow-block,
.theme-color-scheme-4 .block-fullwidth,
.theme-color-scheme-4 .slideshow-block,
.theme-color-scheme-6 .block-fullwidth,
.theme-color-scheme-6 .slideshow-block,
.theme-color-scheme-7 .block-fullwidth,
.theme-color-scheme-7 .slideshow-block {
  margin-left: -30px;
  margin-right: -30px;
}
@media only screen and (max-width: 500px) {
  .theme-color-scheme-2 .block-fullwidth,
  .theme-color-scheme-2 .slideshow-block,
  .theme-color-scheme-3 .block-fullwidth,
  .theme-color-scheme-3 .slideshow-block,
  .theme-color-scheme-4 .block-fullwidth,
  .theme-color-scheme-4 .slideshow-block,
  .theme-color-scheme-6 .block-fullwidth,
  .theme-color-scheme-6 .slideshow-block,
  .theme-color-scheme-7 .block-fullwidth,
  .theme-color-scheme-7 .slideshow-block {
    margin-left: 10px;
    margin-right: 10px;
  }
}

.theme-color-scheme-1 {
  background-color: #F1F1F1;
}
.theme-color-scheme-1 .site-header .menu-block-with-mega-dropdown-menu .menu-level-1-item:hover .menu-level-1-title {
  background-color: rgba(228, 228, 228, 0.98);
}
.theme-color-scheme-1 .site-header .menu-block-with-mega-dropdown-menu .menu-level-2 {
  background-color: rgba(228, 228, 228, 0.98);
}
.theme-color-scheme-1 .site-header .menu-block-with-flyout-menu .menu-level-1-item:hover .menu-level-1-title {
  background-color: rgba(228, 228, 228, 0.98);
}
.theme-color-scheme-1 .site-header .menu-block-with-flyout-menu .menu-level-2-title,
.theme-color-scheme-1 .site-header .menu-block-with-flyout-menu .menu-level-3-title,
.theme-color-scheme-1 .site-header .menu-block-with-flyout-menu .menu-level-4-title {
  background-color: rgba(228, 228, 228, 0.98);
}
.theme-color-scheme-1 .site-header .menu-block-with-flyout-menu .menu-level-2-title:hover,
.theme-color-scheme-1 .site-header .menu-block-with-flyout-menu .menu-level-3-title:hover,
.theme-color-scheme-1 .site-header .menu-block-with-flyout-menu .menu-level-4-title:hover {
  background-color: #000;
  color: #fff;
}
.theme-color-scheme-1 .filter-group-closed {
  padding-bottom: 0;
}
.theme-color-scheme-1 .filter-group-closed .filter-group-title {
  margin: 0;
  background-color: #fff;
}
.theme-color-scheme-1 .shopping-favorite-cart .cart-content-close {
  background-color: transparent !important;
}
.theme-color-scheme-1 .shopping-favorite-cart .erase-button {
  background-color: #000;
  color: white;
}
.theme-color-scheme-1 .shopping-favorite-cart .info-link:hover,
.theme-color-scheme-1 .shopping-favorite-cart .add-to-cart:hover {
  background-color: #000;
}
.theme-color-scheme-1 .cart-content-close {
  background-color: transparent;
}
.theme-color-scheme-1 .site-footer {
  background-color: rgba(228, 228, 228, 0.98);
}

.theme-color-scheme-2 {
  background-color: #EAEEF0;
}
.theme-color-scheme-2 .site-header .menu-block-with-mega-dropdown-menu .menu-level-1-item:hover .menu-level-1-title {
  background-color: rgba(219, 226, 229, 0.98);
}
.theme-color-scheme-2 .site-header .menu-block-with-mega-dropdown-menu .menu-level-2 {
  background-color: rgba(219, 226, 229, 0.98);
}
.theme-color-scheme-2 .site-header .menu-block-with-flyout-menu .menu-level-1-item:hover .menu-level-1-title {
  background-color: rgba(219, 226, 229, 0.98);
}
.theme-color-scheme-2 .site-header .menu-block-with-flyout-menu .menu-level-2-title,
.theme-color-scheme-2 .site-header .menu-block-with-flyout-menu .menu-level-3-title,
.theme-color-scheme-2 .site-header .menu-block-with-flyout-menu .menu-level-4-title {
  background-color: rgba(219, 226, 229, 0.98);
}
.theme-color-scheme-2 .site-header .menu-block-with-flyout-menu .menu-level-2-title:hover,
.theme-color-scheme-2 .site-header .menu-block-with-flyout-menu .menu-level-3-title:hover,
.theme-color-scheme-2 .site-header .menu-block-with-flyout-menu .menu-level-4-title:hover {
  background-color: #0085ba;
  color: #fff;
}
.theme-color-scheme-2 .filter-group-closed {
  padding-bottom: 0;
}
.theme-color-scheme-2 .filter-group-closed .filter-group-title {
  margin: 0;
  background-color: #fff;
}
.theme-color-scheme-2 .shopping-favorite-cart .cart-content-close {
  background-color: transparent !important;
}
.theme-color-scheme-2 .shopping-favorite-cart .erase-button {
  background-color: #0085ba;
  color: white;
}
.theme-color-scheme-2 .shopping-favorite-cart .info-link:hover,
.theme-color-scheme-2 .shopping-favorite-cart .add-to-cart:hover {
  background-color: #0085ba;
}
.theme-color-scheme-2 .cart-content-close {
  background-color: transparent;
}
.theme-color-scheme-2 .site-footer {
  background-color: rgba(219, 226, 229, 0.98);
}

.theme-color-scheme-3 {
  background-color: #F4F2ED;
}
.theme-color-scheme-3 .site-header .menu-block-with-mega-dropdown-menu .menu-level-1-item:hover .menu-level-1-title {
  background-color: rgba(234, 231, 221, 0.98);
}
.theme-color-scheme-3 .site-header .menu-block-with-mega-dropdown-menu .menu-level-2 {
  background-color: rgba(234, 231, 221, 0.98);
}
.theme-color-scheme-3 .site-header .menu-block-with-flyout-menu .menu-level-1-item:hover .menu-level-1-title {
  background-color: rgba(234, 231, 221, 0.98);
}
.theme-color-scheme-3 .site-header .menu-block-with-flyout-menu .menu-level-2-title,
.theme-color-scheme-3 .site-header .menu-block-with-flyout-menu .menu-level-3-title,
.theme-color-scheme-3 .site-header .menu-block-with-flyout-menu .menu-level-4-title {
  background-color: rgba(234, 231, 221, 0.98);
}
.theme-color-scheme-3 .site-header .menu-block-with-flyout-menu .menu-level-2-title:hover,
.theme-color-scheme-3 .site-header .menu-block-with-flyout-menu .menu-level-3-title:hover,
.theme-color-scheme-3 .site-header .menu-block-with-flyout-menu .menu-level-4-title:hover {
  background-color: #967c27;
  color: #fff;
}
.theme-color-scheme-3 .filter-group-closed {
  padding-bottom: 0;
}
.theme-color-scheme-3 .filter-group-closed .filter-group-title {
  margin: 0;
  background-color: #fff;
}
.theme-color-scheme-3 .shopping-favorite-cart .cart-content-close {
  background-color: transparent !important;
}
.theme-color-scheme-3 .shopping-favorite-cart .erase-button {
  background-color: #967c27;
  color: white;
}
.theme-color-scheme-3 .shopping-favorite-cart .info-link:hover,
.theme-color-scheme-3 .shopping-favorite-cart .add-to-cart:hover {
  background-color: #967c27;
}
.theme-color-scheme-3 .cart-content-close {
  background-color: transparent;
}
.theme-color-scheme-3 .site-footer {
  background-color: rgba(234, 231, 221, 0.98);
}

.theme-color-scheme-4 {
  background-color: #f0eaea;
}
.theme-color-scheme-4 .site-header .menu-block-with-mega-dropdown-menu .menu-level-1-item:hover .menu-level-1-title {
  background-color: rgba(229, 219, 219, 0.98);
}
.theme-color-scheme-4 .site-header .menu-block-with-mega-dropdown-menu .menu-level-2 {
  background-color: rgba(229, 219, 219, 0.98);
}
.theme-color-scheme-4 .site-header .menu-block-with-flyout-menu .menu-level-1-item:hover .menu-level-1-title {
  background-color: rgba(229, 219, 219, 0.98);
}
.theme-color-scheme-4 .site-header .menu-block-with-flyout-menu .menu-level-2-title,
.theme-color-scheme-4 .site-header .menu-block-with-flyout-menu .menu-level-3-title,
.theme-color-scheme-4 .site-header .menu-block-with-flyout-menu .menu-level-4-title {
  background-color: rgba(229, 219, 219, 0.98);
}
.theme-color-scheme-4 .site-header .menu-block-with-flyout-menu .menu-level-2-title:hover,
.theme-color-scheme-4 .site-header .menu-block-with-flyout-menu .menu-level-3-title:hover,
.theme-color-scheme-4 .site-header .menu-block-with-flyout-menu .menu-level-4-title:hover {
  background-color: #863B3A;
  color: #fff;
}
.theme-color-scheme-4 .filter-group-closed {
  padding-bottom: 0;
}
.theme-color-scheme-4 .filter-group-closed .filter-group-title {
  margin: 0;
  background-color: #fff;
}
.theme-color-scheme-4 .shopping-favorite-cart .cart-content-close {
  background-color: transparent !important;
}
.theme-color-scheme-4 .shopping-favorite-cart .erase-button {
  background-color: #863B3A;
  color: white;
}
.theme-color-scheme-4 .shopping-favorite-cart .info-link:hover,
.theme-color-scheme-4 .shopping-favorite-cart .add-to-cart:hover {
  background-color: #863B3A;
}
.theme-color-scheme-4 .cart-content-close {
  background-color: transparent;
}
.theme-color-scheme-4 .site-footer {
  background-color: rgba(229, 219, 219, 0.98);
}

.theme-color-scheme-5 {
  background-color: #F1F1F1;
}
.theme-color-scheme-5 .site-header .menu-block-with-mega-dropdown-menu .menu-level-1-item:hover .menu-level-1-title {
  background-color: rgba(228, 228, 228, 0.98);
}
.theme-color-scheme-5 .site-header .menu-block-with-mega-dropdown-menu .menu-level-2 {
  background-color: rgba(228, 228, 228, 0.98);
}
.theme-color-scheme-5 .site-header .menu-block-with-flyout-menu .menu-level-1-item:hover .menu-level-1-title {
  background-color: rgba(228, 228, 228, 0.98);
}
.theme-color-scheme-5 .site-header .menu-block-with-flyout-menu .menu-level-2-title,
.theme-color-scheme-5 .site-header .menu-block-with-flyout-menu .menu-level-3-title,
.theme-color-scheme-5 .site-header .menu-block-with-flyout-menu .menu-level-4-title {
  background-color: rgba(228, 228, 228, 0.98);
}
.theme-color-scheme-5 .site-header .menu-block-with-flyout-menu .menu-level-2-title:hover,
.theme-color-scheme-5 .site-header .menu-block-with-flyout-menu .menu-level-3-title:hover,
.theme-color-scheme-5 .site-header .menu-block-with-flyout-menu .menu-level-4-title:hover {
  background-color: #000;
  color: #fff;
}
.theme-color-scheme-5 .filter-group-closed {
  padding-bottom: 0;
}
.theme-color-scheme-5 .filter-group-closed .filter-group-title {
  margin: 0;
  background-color: #fff;
}
.theme-color-scheme-5 .shopping-favorite-cart .cart-content-close {
  background-color: transparent !important;
}
.theme-color-scheme-5 .shopping-favorite-cart .erase-button {
  background-color: #000;
  color: white;
}
.theme-color-scheme-5 .shopping-favorite-cart .info-link:hover,
.theme-color-scheme-5 .shopping-favorite-cart .add-to-cart:hover {
  background-color: #000;
}
.theme-color-scheme-5 .cart-content-close {
  background-color: transparent;
}
.theme-color-scheme-5 .site-footer {
  background-color: rgba(228, 228, 228, 0.98);
}

.theme-color-scheme-6 {
  background-color: #ebeaf0;
}
.theme-color-scheme-6 .site-header .menu-block-with-mega-dropdown-menu .menu-level-1-item:hover .menu-level-1-title {
  background-color: rgba(221, 219, 229, 0.98);
}
.theme-color-scheme-6 .site-header .menu-block-with-mega-dropdown-menu .menu-level-2 {
  background-color: rgba(221, 219, 229, 0.98);
}
.theme-color-scheme-6 .site-header .menu-block-with-flyout-menu .menu-level-1-item:hover .menu-level-1-title {
  background-color: rgba(221, 219, 229, 0.98);
}
.theme-color-scheme-6 .site-header .menu-block-with-flyout-menu .menu-level-2-title,
.theme-color-scheme-6 .site-header .menu-block-with-flyout-menu .menu-level-3-title,
.theme-color-scheme-6 .site-header .menu-block-with-flyout-menu .menu-level-4-title {
  background-color: rgba(221, 219, 229, 0.98);
}
.theme-color-scheme-6 .site-header .menu-block-with-flyout-menu .menu-level-2-title:hover,
.theme-color-scheme-6 .site-header .menu-block-with-flyout-menu .menu-level-3-title:hover,
.theme-color-scheme-6 .site-header .menu-block-with-flyout-menu .menu-level-4-title:hover {
  background-color: #504482;
  color: #fff;
}
.theme-color-scheme-6 .filter-group-closed {
  padding-bottom: 0;
}
.theme-color-scheme-6 .filter-group-closed .filter-group-title {
  margin: 0;
  background-color: #fff;
}
.theme-color-scheme-6 .shopping-favorite-cart .cart-content-close {
  background-color: transparent !important;
}
.theme-color-scheme-6 .shopping-favorite-cart .erase-button {
  background-color: #504482;
  color: white;
}
.theme-color-scheme-6 .shopping-favorite-cart .info-link:hover,
.theme-color-scheme-6 .shopping-favorite-cart .add-to-cart:hover {
  background-color: #504482;
}
.theme-color-scheme-6 .cart-content-close {
  background-color: transparent;
}
.theme-color-scheme-6 .site-footer {
  background-color: rgba(221, 219, 229, 0.98);
}

.theme-color-scheme-7 {
  background-color: #eef0ea;
}
.theme-color-scheme-7 .site-header .menu-block-with-mega-dropdown-menu .menu-level-1-item:hover .menu-level-1-title {
  background-color: rgba(226, 229, 219, 0.98);
}
.theme-color-scheme-7 .site-header .menu-block-with-mega-dropdown-menu .menu-level-2 {
  background-color: rgba(226, 229, 219, 0.98);
}
.theme-color-scheme-7 .site-header .menu-block-with-flyout-menu .menu-level-1-item:hover .menu-level-1-title {
  background-color: rgba(226, 229, 219, 0.98);
}
.theme-color-scheme-7 .site-header .menu-block-with-flyout-menu .menu-level-2-title,
.theme-color-scheme-7 .site-header .menu-block-with-flyout-menu .menu-level-3-title,
.theme-color-scheme-7 .site-header .menu-block-with-flyout-menu .menu-level-4-title {
  background-color: rgba(226, 229, 219, 0.98);
}
.theme-color-scheme-7 .site-header .menu-block-with-flyout-menu .menu-level-2-title:hover,
.theme-color-scheme-7 .site-header .menu-block-with-flyout-menu .menu-level-3-title:hover,
.theme-color-scheme-7 .site-header .menu-block-with-flyout-menu .menu-level-4-title:hover {
  background-color: #646d50;
  color: #fff;
}
.theme-color-scheme-7 .filter-group-closed {
  padding-bottom: 0;
}
.theme-color-scheme-7 .filter-group-closed .filter-group-title {
  margin: 0;
  background-color: #fff;
}
.theme-color-scheme-7 .shopping-favorite-cart .cart-content-close {
  background-color: transparent !important;
}
.theme-color-scheme-7 .shopping-favorite-cart .erase-button {
  background-color: #646d50;
  color: white;
}
.theme-color-scheme-7 .shopping-favorite-cart .info-link:hover,
.theme-color-scheme-7 .shopping-favorite-cart .add-to-cart:hover {
  background-color: #646d50;
}
.theme-color-scheme-7 .cart-content-close {
  background-color: transparent;
}
.theme-color-scheme-7 .site-footer {
  background-color: rgba(226, 229, 219, 0.98);
}

#color-scheme li[data-option="0"] a {
  background-color: #FFFFFF;
}

#color-scheme li[data-option="1"] a {
  background-color: #F1F1F1;
}

#color-scheme li[data-option="2"] a {
  background-color: #EAEEF0;
}

#color-scheme li[data-option="3"] a {
  background-color: #F4F2ED;
}

#color-scheme li[data-option="4"] a {
  background-color: #f0eaea;
}

#color-scheme li[data-option="5"] a {
  background-color: #F1F1F1;
}

#color-scheme li[data-option="6"] a {
  background-color: #ebeaf0;
}

#color-scheme li[data-option="7"] a {
  background-color: #eef0ea;
}

#background-patterns li {
  background-color: #FFFFFF;
}

#background-pattern li[data-option="1"] a:before,
.theme-background-pattern-1 {
  background-image: url(../img/background-pattern/01.png);
}

#background-pattern li[data-option="2"] a:before,
.theme-background-pattern-2 {
  background-image: url(../img/background-pattern/02.png);
}

#background-pattern li[data-option="3"] a:before,
.theme-background-pattern-3 {
  background-image: url(../img/background-pattern/03.png);
}

#background-pattern li[data-option="4"] a:before,
.theme-background-pattern-4 {
  background-image: url(../img/background-pattern/04.png);
}

#background-pattern li[data-option="5"] a:before,
.theme-background-pattern-5 {
  background-image: url(../img/background-pattern/05.png);
}

#background-pattern li[data-option="6"] a:before,
.theme-background-pattern-6 {
  background-image: url(../img/background-pattern/06.png);
}

#background-pattern li[data-option="7"] a:before,
.theme-background-pattern-7 {
  background-image: url(../img/background-pattern/07.png);
}

#checkout-layout li a {
  text-align: center;
  border-style: none;
  color: black;
}
#checkout-layout li a:hover {
  border-style: none;
  color: #682877;
}
#checkout-layout li a:before {
  font-size: 60px;
}
#checkout-layout li a b {
  display: none;
}
#checkout-layout li[data-option="0"] a:before {
  content: "\e604";
}
#checkout-layout li[data-option="1"] a:before {
  content: "\e603";
}
#checkout-layout li[data-option="2"] a:before {
  content: "\e605";
}

.ie-11 .gallery-info-link {
  display: block;
}
.ie-11 select::-ms-expand {
  display: none;
}
.ie-11.has-visible-off-canvas-cart body {
  -webkit-transform: none;
          transform: none;
}
.ie-11.has-visible-off-canvas-cart #page-container {
  position: relative;
}
.ie-11.has-visible-off-canvas-cart #page-container:after {
  z-index: 999;
}
.ie-11.has-visible-off-canvas-cart .off-canvas-cart {
  -webkit-transform: none;
          transform: none;
  z-index: 1000;
}
.ie-11.has-visible-off-canvas-cart.admin-user-logged-in .off-canvas-cart {
  top: 50px;
}
.ie-11.theme-layout-boxed .footer-region .block {
  padding-left: 30px;
  padding-right: 30px;
}
@media only screen and (max-width: 500px) {
  .ie-11.theme-layout-boxed .footer-region .block {
    padding-left: 10px;
    padding-right: 10px;
  }
}

.ios .gallery-item .button,
.ios .save-percent-info {
  -webkit-transform: translate3d(0, 0, 0);
}

@media print {
  .theme-color-scheme-1,
  .theme-color-scheme-2,
  .theme-color-scheme-3,
  .theme-color-scheme-4,
  .theme-color-scheme-5,
  .theme-color-scheme-6,
  .theme-color-scheme-7 {
    background-color: transparent;
    background-image: none;
  }
  #site-header-phantom,
  #tb-preview {
    display: none;
  }
  #content,
  h1 {
    margin-top: 0;
  }
  #content {
    margin-bottom: 0;
  }
  .customer-content {
    padding-left: 0;
    padding-right: 0;
  }
}
.theme-color-scheme-5 #page-container {
  overflow-x: hidden;
}
.theme-color-scheme-5 h1,
.theme-color-scheme-5 h2,
.theme-color-scheme-5 h3,
.theme-color-scheme-5 h4 {
  font-weight: 500;
}
.theme-color-scheme-5 h1 {
  font-size: 2rem;
  margin-top: 0;
}
@media only screen and (max-width: 980px) {
  .theme-color-scheme-5 h1 {
    font-size: 1.75rem;
  }
}
.theme-color-scheme-5 .button,
.theme-color-scheme-5 button,
.theme-color-scheme-5 .checkout-link,
.theme-color-scheme-5 .product-status-checkout-link {
  font-size: 1rem;
  letter-spacing: 0.05em;
  border-radius: 50px;
  padding-left: 25px;
  padding-right: 25px;
}
.theme-color-scheme-5 .checkout-link:after {
  right: 22px;
}
.theme-color-scheme-5 .gallery-item .button {
  padding-left: 35px;
  padding-right: 35px;
}
.theme-color-scheme-5 .product-status-checkout-link b:after {
  font-size: 24px;
}
.theme-color-scheme-5 .control textarea,
.theme-color-scheme-5 .control select,
.theme-color-scheme-5 .control input[type=text],
.theme-color-scheme-5 .control input[type=password],
.theme-color-scheme-5 .control input[type=email],
.theme-color-scheme-5 .control input[type=number],
.theme-color-scheme-5 .control input[type=tel],
.theme-color-scheme-5 .control input[type=url],
.theme-color-scheme-5 .control input[type=file] {
  font-size: 1rem;
}
.theme-color-scheme-5.has-visible-off-canvas-nav #mobile-menu span:before, .theme-color-scheme-5.has-visible-off-canvas-nav #mobile-menu span:after {
  top: 0;
}
.theme-color-scheme-5 #mobile-menu {
  top: 40px;
  z-index: 10;
}
@media only screen and (max-width: 980px) {
  .theme-color-scheme-5 #mobile-menu {
    top: 35px;
  }
}
.theme-color-scheme-5 #mobile-menu span {
  height: 2px;
  width: 30px;
}
.theme-color-scheme-5 #mobile-menu span:before, .theme-color-scheme-5 #mobile-menu span:after {
  height: 2px;
  width: 30px;
}
.theme-color-scheme-5 #mobile-menu span:before {
  top: -9px;
}
.theme-color-scheme-5 #mobile-menu span:after {
  bottom: -9px;
}
.theme-color-scheme-5 .fixed-site-header {
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.1);
  background: #ffffff;
}
.theme-color-scheme-5 .site-header-wrapper .favorite-cart-icon:before,
.theme-color-scheme-5 .site-header-wrapper .favorite:before {
  font-size: 26px;
}
@media only screen and (max-width: 980px) {
  .theme-color-scheme-5 .site-header-wrapper #home {
    margin-left: 55px;
  }
}
@media only screen and (max-width: 500px) {
  .theme-color-scheme-5 .site-header-wrapper #home {
    margin-left: 45px;
  }
}
.theme-color-scheme-5 .site-header-wrapper .header-region .control select,
.theme-color-scheme-5 .site-header-wrapper .header-region .menu-level-1-title {
  font-size: 0.8125rem;
}
.theme-color-scheme-5 .site-header-wrapper .site-header-content .header-item-2,
.theme-color-scheme-5 .site-header-wrapper .site-header-content .header-item-3 {
  max-width: 2000px;
  margin-left: auto;
  margin-right: auto;
}
.theme-color-scheme-5 .site-header-wrapper .site-header-content .favorite-item-count,
.theme-color-scheme-5 .site-header-wrapper .site-header-content .product-item-count {
  position: relative;
}
.theme-color-scheme-5 .site-header-wrapper .site-header-content .favorite-item-count .number,
.theme-color-scheme-5 .site-header-wrapper .site-header-content .product-item-count .number {
  position: absolute;
  right: -5px;
  top: -5px;
}
.theme-color-scheme-5 .site-header-wrapper .site-header-content .vat-selector-block a {
  line-height: 1em;
  padding-top: 6px;
  padding-bottom: 6px;
}
.theme-color-scheme-5 .site-header-wrapper .header-item-1 {
  background: #fafafa;
  border-top: 1px solid #fafafa;
  border-bottom: 1px solid #f1f1f1;
  padding: 0 30px;
  margin-left: -30px;
  margin-right: -30px;
  text-transform: none;
  font-size: 0.8125rem;
}
.theme-color-scheme-5 .site-header-wrapper .header-item-1 .block:first-child {
  margin-left: auto;
}
.theme-color-scheme-5 .site-header-wrapper .header-item-1 .toggle-fullwidth-search {
  width: 20px;
  height: 20px;
}
.theme-color-scheme-5 .site-header-wrapper .header-item-1 .toggle-fullwidth-search:after {
  font-size: 20px;
}
.theme-color-scheme-5 .site-header-wrapper .header-item-1 .menu-block .menu-item {
  line-height: 1em;
}
.theme-color-scheme-5 .site-header-wrapper .header-item-1 .menu-block .menu-item .menu-title-has-children:after {
  top: 5px;
  right: 14px;
  font-size: 14px;
}
.theme-color-scheme-5 .site-header-wrapper .header-item-1 .menu-block .menu-item .menu-level-1-title {
  text-transform: none;
}
.theme-color-scheme-5 .site-header-wrapper .header-item-1.menu-block-with-flyout-menu .menu-level-1 > .menu-item:first-of-type a {
  padding-left: 0;
}
.theme-color-scheme-5 .site-header-wrapper .header-item-1.has-menu-block.has-locale-block.has-vat-selector-block .block:first-child {
  margin-left: 0;
  margin-right: auto;
}
@media only screen and (max-width: 980px) {
  .theme-color-scheme-5 .site-header-wrapper .header-item-1.has-menu-block.has-locale-block.has-vat-selector-block {
    -webkit-justify-content: flex-end;
            justify-content: flex-end;
  }
}
.theme-color-scheme-5 .site-header-wrapper .header-item-1.has-menu-block.has-locale-block.has-vat-selector-block.has-search-block .block:first-child {
  margin-left: 0;
  margin-right: auto;
}
.theme-color-scheme-5 .site-header-wrapper .header-item-1.has-menu-block.has-locale-block.has-vat-selector-block.has-search-block .block:nth-child(2) {
  margin-left: 15px;
  margin-right: auto;
}
.theme-color-scheme-5 .site-header-wrapper .header-item-1.has-search-block.has-vat-selector-block.has-locale-block .block:first-child {
  margin-left: 0;
  margin-right: auto;
}
.theme-color-scheme-5 .site-header-wrapper .header-item-2 {
  position: relative;
  margin-top: 12px;
  margin-bottom: 10px;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
}
@media only screen and (max-width: 1200px) {
  .theme-color-scheme-5 .site-header-wrapper .header-item-2 {
    margin-bottom: 0;
  }
}
@media only screen and (max-width: 980px) {
  .theme-color-scheme-5 .site-header-wrapper .header-item-2 {
    margin-top: 8px;
    margin-bottom: 8px;
  }
}
.theme-color-scheme-5 .site-header-wrapper .header-item-2 .search-block {
  position: absolute;
  margin: 0 auto;
  left: 0;
  right: 0;
  width: 440px;
}
.theme-color-scheme-5 .site-header-wrapper .header-item-2 .search-block input[type=search] {
  padding: 8px 20px;
}
.theme-color-scheme-5 .site-header-wrapper .header-item-2 .search-block button[type=submit] {
  width: 50px;
}
.theme-color-scheme-5 .site-header-wrapper .header-item-2 .search-block button[type=submit]:after {
  font-size: 20px;
}
.theme-color-scheme-5 .site-header-wrapper .header-item-3 {
  -webkit-justify-content: center;
          justify-content: center;
}
.theme-color-scheme-5 .site-header-wrapper .header-item-3 .search-block {
  margin-bottom: 5px;
}
.theme-color-scheme-5 .site-header-wrapper .header-item-3.menu-block-with-mega-dropdown-menu .menu-level-1 .menu-level-1-item .menu-level-1-title {
  padding: 14px 20px 10px;
  font-size: 1rem;
  line-height: 1em;
  font-weight: 600;
}
.theme-color-scheme-5 .site-header-wrapper .header-item-3.menu-block-with-mega-dropdown-menu .menu-level-2 {
  -webkit-justify-content: center;
          justify-content: center;
  padding-top: 20px;
}
.theme-color-scheme-5 .site-header-wrapper .header-item-3.menu-block-with-mega-dropdown-menu .menu-level-2 .menu-level-2-item {
  padding-right: 20px;
  padding-left: 20px;
}
.theme-color-scheme-5 .site-header-wrapper .header-item-3.menu-block-with-mega-dropdown-menu .menu-level-2-title,
.theme-color-scheme-5 .site-header-wrapper .header-item-3.menu-block-with-mega-dropdown-menu .menu-level-3-title,
.theme-color-scheme-5 .site-header-wrapper .header-item-3.menu-block-with-mega-dropdown-menu .menu-level-4-title {
  font-size: 0.875rem;
}
.theme-color-scheme-5 .site-header-wrapper .header-item-3.has-search-block {
  -webkit-justify-content: space-between;
          justify-content: space-between;
}
.theme-color-scheme-5 .site-header-wrapper .header-item-3.has-menu-block.has-search-block .block:first-child, .theme-color-scheme-5 .site-header-wrapper .header-item-3.has-category-tree-block.has-search-block .block:first-child {
  margin-left: 0;
  margin-right: auto;
}
.theme-color-scheme-5 .site-header-wrapper .header-item-3.has-menu-block.has-search-block nav, .theme-color-scheme-5 .site-header-wrapper .header-item-3.has-category-tree-block.has-search-block nav {
  margin-left: auto;
  margin-right: auto;
}
.theme-color-scheme-5 .site-header-wrapper .header-item-3.has-cart-block .favorite-cart-block {
  margin-right: 0;
  margin-left: auto;
  margin-bottom: 5px;
}
.theme-color-scheme-5.desktop .select-locale-button:hover {
  color: #000000;
  background: #ffffff;
  border-color: #888888;
}
.theme-color-scheme-5.desktop .select-locale-button:hover .select-locale-button-content span.language, .theme-color-scheme-5.desktop .select-locale-button:hover .select-locale-button-content span.country, .theme-color-scheme-5.desktop .select-locale-button:hover .select-locale-button-content span.currency {
  border-color: #888888;
}
.theme-color-scheme-5 .select-locale-button {
  font-size: 0.75rem;
  line-height: 1em;
  border-radius: 50px;
  background: #ffffff;
  border: 1px solid #dddddd;
  cursor: pointer;
}
.theme-color-scheme-5 .select-locale-button .select-locale-button-content span {
  padding-bottom: 3px;
}
.theme-color-scheme-5 .select-locale-button .select-locale-button-content span:last-of-type {
  padding-right: 10px;
}
.theme-color-scheme-5 .select-locale-button .select-locale-button-content span:first-of-type {
  padding-left: 10px;
}
.theme-color-scheme-5 .select-locale-button .select-locale-button-content span.language, .theme-color-scheme-5 .select-locale-button .select-locale-button-content span.country, .theme-color-scheme-5 .select-locale-button .select-locale-button-content span.currency {
  border-left: 1px solid #dddddd;
}
.theme-color-scheme-5 .select-locale-button span:before {
  font-size: 12px;
}
.theme-color-scheme-5 .menu a:hover {
  text-decoration: none;
}
.theme-color-scheme-5 .site-header .menu-block-with-flyout-menu .menu-level-1-title {
  padding-top: 6px;
  padding-bottom: 6px;
}
.theme-color-scheme-5 .site-header .menu-block-with-flyout-menu .menu-level-2-title,
.theme-color-scheme-5 .site-header .menu-block-with-flyout-menu .menu-level-3-title,
.theme-color-scheme-5 .site-header .menu-block-with-flyout-menu .menu-level-4-title {
  padding-top: 6px;
  padding-bottom: 6px;
}
.theme-color-scheme-5 .quick-search-results .wrapper-header {
  border-bottom: 1px solid #efefef;
}
.theme-color-scheme-5 .quick-search-results .quick-search-result {
  font-size: 1rem;
}
.theme-color-scheme-5 .quick-search-results .quick-search-result:not(:last-child) {
  border-bottom: 1px solid #efefef;
}
.theme-color-scheme-5 .quick-search-results .quick-search-result:last-of-type {
  margin-bottom: 0;
}
.theme-color-scheme-5 .quick-search-results .search-link a {
  font-size: 0.875rem;
  font-weight: 600;
  color: #000;
}
.theme-color-scheme-5.has-quick-search-results-open .search-block form input[type=search],
.theme-color-scheme-5.has-quick-search-results-open .search-block-with-expand form input[type=search] {
  border-radius: 20px 20px 0 0;
}
.theme-color-scheme-5.has-quick-search-results-open .search-block form button[type=submit],
.theme-color-scheme-5.has-quick-search-results-open .search-block-with-expand form button[type=submit] {
  border-top-right-radius: 50px;
  border-bottom-right-radius: 50px;
}
.theme-color-scheme-5 .search-block form input[type=search],
.theme-color-scheme-5 .search-block-with-expand form input[type=search] {
  font-size: 1rem;
  border-radius: 50px;
  padding-left: 15px;
  padding-right: 15px;
}
.theme-color-scheme-5 .search-block form button[type=submit],
.theme-color-scheme-5 .search-block-with-expand form button[type=submit] {
  width: 40px;
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  border-top-right-radius: 50px;
  border-bottom-right-radius: 50px;
  background: #F0F0F0;
  color: #000;
}
.theme-color-scheme-5 .search-block form button[type=submit]:after,
.theme-color-scheme-5 .search-block-with-expand form button[type=submit]:after {
  font-size: 16px;
}
.theme-color-scheme-5 .toggle-fullwidth-search {
  display: none;
  margin-left: 15px;
}
@media only screen and (max-width: 1200px) {
  .theme-color-scheme-5 .toggle-fullwidth-search {
    display: block;
  }
}
.theme-color-scheme-5 .toggle-fullwidth-search:after {
  font-size: 26px;
}
.theme-color-scheme-5 .search-block-with-fullwidth {
  background: rgba(228, 228, 228, 0.98);
  position: absolute;
  left: 0;
  right: 0;
  bottom: -61px;
  height: 61px;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  z-index: 10;
}
.theme-color-scheme-5 .search-block-with-fullwidth .content {
  display: -webkit-flex;
  display: flex;
  width: 100%;
}
.theme-color-scheme-5 .search-block-with-fullwidth .content .quick-search-results {
  margin: 0 auto;
  max-width: 440px;
}
@media only screen and (max-width: 600px) {
  .theme-color-scheme-5 .search-block-with-fullwidth .content .quick-search-results {
    max-width: 100%;
    margin: 0 8px;
    width: auto;
  }
}
.theme-color-scheme-5 .search-block-with-fullwidth .content .quick-search-results .wrapper-header {
  border-top: 1px solid #efefef;
}
.theme-color-scheme-5 .search-block-with-fullwidth .content .field-wrap {
  max-width: 440px;
  width: 100%;
}
@media only screen and (max-width: 600px) {
  .theme-color-scheme-5 .search-block-with-fullwidth .content .field-wrap {
    max-width: 100%;
    padding: 0 8px;
  }
}
.theme-color-scheme-5 .search-block-with-fullwidth .content .close-fullwidth-search {
  width: 50px;
  height: 50px;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  padding: 0;
  margin-right: 0;
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  margin-top: auto;
  margin-bottom: auto;
}
@media only screen and (max-width: 600px) {
  .theme-color-scheme-5 .search-block-with-fullwidth .content .close-fullwidth-search {
    right: 8px;
  }
}
.theme-color-scheme-5 .search-block-with-fullwidth .content .close-fullwidth-search:after {
  font-size: 20px;
  color: #000;
}
.theme-color-scheme-5 .search-block-with-fullwidth .content form {
  width: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
}
.theme-color-scheme-5 .search-block-with-fullwidth .content input[type=search] {
  padding: 8px 20px;
  border-radius: 50px;
  color: #000;
  background: #ffffff;
}
.theme-color-scheme-5 .shopping-favorite-cart .favorite-cart-icon:before,
.theme-color-scheme-5 .shopping-favorite-cart .favorite:before {
  margin-top: 20px;
}
.theme-color-scheme-5 .shopping-favorite-cart .shopping-favorite-cart-header {
  padding-left: 60px;
}
.theme-color-scheme-5 .shopping-favorite-cart .cart-content-close {
  left: 10px;
}
.theme-color-scheme-5 .shopping-favorite-cart .cart-content-close:after {
  font-size: 26px;
}
.theme-color-scheme-5 .shopping-favorite-cart .favorite-item-count,
.theme-color-scheme-5 .shopping-favorite-cart .product-item-count {
  position: relative;
}
.theme-color-scheme-5 .shopping-favorite-cart .favorite-item-count .number,
.theme-color-scheme-5 .shopping-favorite-cart .product-item-count .number {
  position: absolute;
  right: -5px;
  top: -1px;
}
.theme-color-scheme-5 .shopping-favorite-cart .favorite-cart-icon:before,
.theme-color-scheme-5 .shopping-favorite-cart .favorite:before {
  font-size: 26px;
}
.theme-color-scheme-5 .cart-block .cart-icon {
  -webkit-align-items: center;
          align-items: center;
  display: -webkit-flex;
  display: flex;
}
.theme-color-scheme-5 .cart-block .cart-icon:before {
  font-size: 26px;
}
.theme-color-scheme-5 .cart-block .cart-header {
  padding-left: 0;
}
.theme-color-scheme-5 .cart-block .cart-header .price {
  font-size: 18px;
  margin-top: 5px;
}
.theme-color-scheme-5 .cart-block .cart-has-items .price {
  margin-left: 8px;
}
.theme-color-scheme-5 .cart-block .cart-item-count {
  position: relative;
}
.theme-color-scheme-5 .cart-block .cart-item-count .number {
  position: absolute;
  top: -5px;
  right: -5px;
}
.theme-color-scheme-5 .sw-modal-window .select-locale-modal-close-button {
  padding-left: 35px;
}
.theme-color-scheme-5 .slideshow-block .flickity-prev-next-button {
  border-radius: 50%;
  padding: 0;
}
.theme-color-scheme-5 .slideshow-block .flickity-prev-next-button svg {
  left: 25%;
  top: 25%;
  width: 50%;
  height: 50%;
}
.theme-color-scheme-5 .categories-gallery h3 {
  font-weight: 600;
  text-transform: uppercase;
}
.theme-color-scheme-5 .categories-gallery.images h3 {
  margin-top: 10px;
  font-size: 1.25rem;
}
@media only screen and (max-width: 500px) {
  .theme-color-scheme-5 .categories-gallery.images h3 {
    font-size: 1rem;
  }
}
.theme-color-scheme-5.mobile .header-item-1 {
  -webkit-justify-content: flex-end;
          justify-content: flex-end;
}
.theme-color-scheme-5.mobile .header-item-2 {
  margin-top: 12px;
  margin-bottom: 12px;
}
@media only screen and (max-width: 980px) {
  .theme-color-scheme-5.mobile .header-item-2 {
    margin-top: 8px;
    margin-bottom: 8px;
  }
}
.theme-color-scheme-5.mobile #home {
  margin-left: 55px;
}
@media only screen and (max-width: 500px) {
  .theme-color-scheme-5.mobile #home {
    margin-left: 45px;
  }
}
.theme-color-scheme-5 .search-results,
.theme-color-scheme-5 .product-overview,
.theme-color-scheme-5 .preview-cart-import {
  max-width: 2000px;
  margin-left: auto;
  margin-right: auto;
}
.theme-color-scheme-5 #content {
  margin-top: 0;
}
@media only screen and (max-width: 980px) {
  .theme-color-scheme-5 #content {
    padding-top: 20px;
  }
}
.theme-color-scheme-5#start-page #content {
  padding-top: 0;
}
.theme-color-scheme-5#start-page #main-content .block:not(.slideshow-block) {
  max-width: 2000px;
  margin-left: auto;
  margin-right: auto;
}
.theme-color-scheme-5#product-page #main-content {
  max-width: 2000px;
  margin-left: auto;
  margin-right: auto;
}
.theme-color-scheme-5#product-page #main-content .product-long-description,
.theme-color-scheme-5#product-page #main-content .add-review-comment,
.theme-color-scheme-5#product-page #main-content .review-rating {
  width: calc(53% - 30px);
}
@media only screen and (max-width: 980px) {
  .theme-color-scheme-5#product-page #main-content .product-long-description,
  .theme-color-scheme-5#product-page #main-content .add-review-comment,
  .theme-color-scheme-5#product-page #main-content .review-rating {
    width: 100%;
  }
}
.theme-color-scheme-5#product-page #main-content .review-rating,
.theme-color-scheme-5#product-page #main-content .add-review-comment {
  border: 0;
}
.theme-color-scheme-5#product-list-page .product-list-block {
  margin-top: 20px;
}
.theme-color-scheme-5#product-list-page .category-description {
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
}
.theme-color-scheme-5 .customer-page #main-content {
  max-width: 2000px;
  margin-left: auto;
  margin-right: auto;
}
.theme-color-scheme-5 .customer-page-navigation a {
  color: #000;
}
.theme-color-scheme-5 .customer-page-navigation .logout a {
  background-color: rgba(255, 255, 255, 0.4);
}
.theme-color-scheme-5.desktop .customer-page-navigation .logout a:hover {
  background-color: #ffffff;
}
.theme-color-scheme-5 #blog-page .layout,
.theme-color-scheme-5 #blog-post-page .layout {
  max-width: 2000px;
  margin-left: auto;
  margin-right: auto;
}
.theme-color-scheme-5 .site-footer .site-footer-content {
  max-width: 2000px;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
}
.theme-color-scheme-5 .site-footer .site-footer-content h2 {
  font-size: 1.5rem;
}
.theme-color-scheme-5 .site-footer .site-footer-content h3 {
  font-size: 1.25rem;
}
.theme-color-scheme-5 .site-footer .site-footer-content h4 {
  font-size: 1.125rem;
}
.theme-color-scheme-5 .site-footer .site-footer-content .block {
  max-width: 800px;
  margin-bottom: 40px;
}
.theme-color-scheme-5 .site-footer .site-footer-content .menu-level-1 ul {
  margin-left: 0;
}
.theme-color-scheme-5 .site-footer .site-footer-content .menu-level-1-title {
  font-size: 1rem;
  padding-top: 7px;
  padding-bottom: 7px;
  text-transform: none;
  line-height: 1em;
  padding-left: 20px;
}
.theme-color-scheme-5 .site-footer .site-footer-content .menu-level-2-title:before {
  display: none;
}
.theme-color-scheme-5 .site-footer .site-footer-content .menu-item {
  margin: 0;
}
.theme-color-scheme-5 .site-footer .site-footer-content .custom-menu-block .menu-title-has-children {
  padding-right: 30px;
}
.theme-color-scheme-5 .site-footer .site-footer-content .custom-menu-block .toggle-child-menu {
  display: none;
  top: 0;
}
.theme-color-scheme-5 .site-footer .site-footer-content .custom-menu-block .toggle-child-menu:before {
  font-size: 16px;
  position: absolute;
  top: 7px;
  right: 10px;
  line-height: 1em;
}
.theme-color-scheme-5 .site-footer .site-footer-content .custom-menu-block .menu-level-2 {
  display: none;
}
.theme-color-scheme-5 .site-footer .site-footer-content .custom-menu-block .menu-title-has-children {
  position: relative;
  padding-right: 28px;
}
.theme-color-scheme-5 .site-footer .site-footer-content .newsletter-block {
  width: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  margin-top: 0;
  text-align: center;
}
.theme-color-scheme-5 .site-footer .site-footer-content .newsletter-block .content,
.theme-color-scheme-5 .site-footer .site-footer-content .newsletter-block h3,
.theme-color-scheme-5 .site-footer .site-footer-content .newsletter-block p {
  max-width: 100%;
  width: 440px;
}
.theme-color-scheme-5 .site-footer .site-footer-content .newsletter-block h3 {
  margin-bottom: 6px;
  text-transform: uppercase;
  font-weight: 600;
}
.theme-color-scheme-5 .site-footer .site-footer-content .newsletter-block p {
  margin-top: 2px;
}
.theme-color-scheme-5 .site-footer .site-footer-content .newsletter-block input[type=email] {
  padding: 10px 20px;
  min-height: 0;
  border-radius: 50px 0 0 50px;
}
@media only screen and (max-width: 500px) {
  .theme-color-scheme-5 .site-footer .site-footer-content .newsletter-block input[type=email] {
    border-radius: 10px 10px 0 0;
  }
}
.theme-color-scheme-5 .site-footer .site-footer-content .newsletter-block .newsletter-subscribe-button {
  border-radius: 0 50px 50px 0;
}
@media only screen and (max-width: 500px) {
  .theme-color-scheme-5 .site-footer .site-footer-content .newsletter-block .newsletter-subscribe-button {
    border-radius: 0 0 10px 10px;
  }
}
.theme-color-scheme-5 .site-footer .site-footer-content .sociala-medier {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  margin-bottom: 20px;
}
.theme-color-scheme-5 .site-footer .site-footer-content .sociala-medier a {
  margin: 0 6px;
}
.theme-color-scheme-5 .site-footer .site-footer-content .sociala-medier a img {
  width: 26px;
  height: auto;
  -webkit-filter: grayscale(100%);
          filter: grayscale(100%);
}
.theme-color-scheme-5 .site-footer .site-footer-content .sociala-medier a:hover img {
  -webkit-filter: grayscale(0);
          filter: grayscale(0);
}